import React from 'react';
import { StyledButton, StyledIcon } from './Styles';

const Button = props => {
  const { func, title, icon, type } = props;
  return (
    <StyledButton {...props} type={type || 'button'} onClick={func}>
      {icon ? <StyledIcon className={icon} /> : null}
      {title ? title : null}
    </StyledButton>
  );
};

export default Button;
