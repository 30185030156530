// @flow
import Video from 'twilio-video';
import * as Sentry from '@sentry/browser';
import { VideoServer } from '../server';
import styles from '../views/video/Video.module.css';

//NEW CONTROLLER CODES

class VideoController {
  constructor() {
    this.localAudioTrack = null;
    this.localVideoTrack = null;
    this.remoteMedia = null;
    this.localMedia = null;
  }

  sendErrorToSentry = (error, patient, therapist) => {
    Sentry.withScope(scope => {
      scope.setExtra('patient', patient);
      scope.setExtra('therapist', therapist);
      Sentry.captureException(error);
    });
  };

  setMedia = (media: Object, type: string) => {
    if (type === 'local') {
      this.localMedia = media;
    } else if (type === 'remote') {
      this.remoteMedia = media;
    }
  };

  setLocalTrack = (track: Object, type: string) => {
    if (type === 'audio') {
      this.localAudioTrack = track;
    } else if (type === 'video') {
      this.localVideoTrack = track;
    }
  };

  detachTrack = track => {
    if (track.kind === 'video' || track.kind === 'audio') {
      track.detach().forEach(function(element) {
        element.remove();
      });
    }
  };

  // A RemoteTrack was unpublished from the Room.
  trackUnpublished = publication => {
    console.log(`${publication.kind} track was unpublished.`);
  };

  getTracks = participant => {
    return Array.from(participant.tracks.values())
      .filter(function(publication) {
        return publication.track;
      })
      .map(function(publication) {
        return publication.track;
      });
  };

  participantConnected = participant => {
    const selfContainer = document.createElement('div');
    selfContainer.id = `participantContainer-${participant.identity}`;
    try {
      const trackPublished = (publication, container) => {
        publication.on('subscribed', function(track) {
          // this.attachTrack(track, container);
          if (track.kind === 'video' || track.kind === 'audio') {
            container.appendChild(track.attach());
          }

          const video = selfContainer.querySelector('video');
          if (video) {
            video.setAttribute('class', styles.video);
          }
        });
        publication.on('unsubscribed', this.detachTrack);
      };

      this.remoteMedia.appendChild(selfContainer);

      const video = selfContainer.querySelector('video');
      if (video) {
        video.style.height = '80%';
      }

      participant.tracks.forEach(function(publication) {
        trackPublished(publication, selfContainer);
      });
      participant.on('trackPublished', function(publication) {
        trackPublished(publication, selfContainer);
      });
      participant.on('trackUnpublished', this.trackUnpublished);
    } catch (err) {
      this.sendErrorToSentry(err, participant);
    }
  };

  createLocalsTracks = async (token, roomName) => {
    const localVideoTrack = await Video.createLocalVideoTrack();
    this.setLocalTrack(localVideoTrack, 'video');
    const localAudioTrack = await Video.createLocalAudioTrack();
    this.setLocalTrack(localAudioTrack, 'audio');
    const AudioVideoTracks = [localVideoTrack, localAudioTrack];

    return await Video.connect(token, {
      name: roomName,
      tracks: AudioVideoTracks
    });
  };

  setMediaById = () => {
    const remote = document.getElementById('remoteMedia');
    const local = document.getElementById('localMedia');
    return { local, remote };
  };

  initiateVideoCall = async (options: Object, media: Object): Promise<Object> => {
    const { therapist, patient, roomName, setToast, actualRoom } = options;

    media = this.setMediaById();

    this.setMedia(media.local, 'local');
    this.setMedia(media.remote, 'remote');

    return VideoServer.generateTokens(therapist, patient)
      .then(async ({ therapist_token: token }) => {
        const room = await this.createLocalsTracks(token, roomName);
        VideoServer.confirmVideocall(therapist, patient).catch(error => {
          this.sendErrorToSentry(error, patient, therapist);
        });

        if (!media.local || !media.remote) {
          media = this.setMediaById();
        }

        const localParticipantTracks = room.localParticipant.tracks;
        localParticipantTracks.forEach(publication => {
          media.local.appendChild(publication.track.attach());
        });

        room.participants.forEach(this.participantConnected);
        room.on('participantConnected', this.participantConnected);
        const video = media.local.querySelector('video');
        video.setAttribute('class', styles.localVideo);

        if (video) {
          video.style.height = '100%';
        }

        const detachParticipantTracks = participant => {
          var tracks = this.getTracks(participant);
          tracks.forEach(this.detachTrack);
        };

        room.on('disconnected', function(room) {
          room.localParticipant.tracks.forEach(publication => {
            publication.track.stop();
            const attachedElements = publication.track.detach();
            attachedElements.forEach(element => element.remove());
          });
          detachParticipantTracks(room.localParticipant);
          room.participants.forEach(detachParticipantTracks);
        });

        return room;
      })
      .catch(error => {
        this.disconnectVideoCall(actualRoom, { therapist, patient });
        setToast(this.handleToast(error.message));
        this.sendErrorToSentry(error, patient, therapist);
      });
  };

  handleToast = error =>
    ({
      'Permission denied':
        'Por favor cheque se sua webcam e microfone estão permitidos para serem utilizados no navegador e reinicie a chamada',
      'Requested device not found':
        'Sua webcam ou microfone não foram encontrados, por favor habilite no seu sistema e reinicie a chamada'
    }[error]);

  disconnectVideoCall = (room: Object, options: Object) => {
    const { therapist, patient } = options;
    if (room) {
      try {
        room.localParticipant.tracks.forEach(publication => {
          if (publication.track.kind === 'video') {
            const attachedElements = publication.track.detach();
            attachedElements.forEach(element => element.remove());
          }
        });
        VideoServer.deleteTokens(therapist, patient)
          .then(response => {})
          .catch(error => {
            this.sendErrorToSentry(error, patient, therapist);
          });
        room.disconnect();
      } catch (error) {
        this.sendErrorToSentry(error, patient, therapist);
      }
    }
  };

  qualityChecker = async (
    packetLost: ?number,
    differencePackets: ?number,
    differenceSentPackets: ?number,
    videoEnabled: ?Boolean
  ) => {
    let message = 'Conexão ruim. Tente desativar a imagem da chamada no ícone inferior direito.';
    if (
      (differencePackets > 0 && differencePackets <= 25) ||
      (videoEnabled && differenceSentPackets > 0 && differenceSentPackets <= 25)
    ) {
      return {
        packetLost,
        toastEnabled: false,
        icon: 'wifi0',
        toastMessage: message
      };
    } else if (
      (differencePackets > 0 && differencePackets < 25) ||
      (videoEnabled && differenceSentPackets < 100)
    ) {
      return {
        packetLost,
        toastEnabled: false,
        icon: 'wifi1',
        toastMessage: 'Sua conexão ou do paciente está instável.'
      };
    } else if (
      (differencePackets > 25 && differencePackets < 50) ||
      (videoEnabled && differenceSentPackets < 170)
    ) {
      return {
        packetLost,
        toastEnabled: false,
        icon: 'wifi2',
        toastMessage: ''
      };
    } else if (differencePackets > 50 || (videoEnabled && differenceSentPackets > 170)) {
      return {
        packetLost,
        toastEnabled: false,
        icon: 'wifi3',
        toastMessage: ''
      };
    } else {
      return {
        packetLost,
        toastEnabled: false,
        icon: 'wifi0',
        toastMessage: ''
      };
    }
  };

  setVideo = (trigger): Promise<boolean> => {
    trigger ? this.localVideoTrack.disable() : this.localVideoTrack.enable();
  };

  submitFeedback = body => {
    return VideoServer.sendFeedback(body).catch(err => {
      throw err;
    });
  };
}
export default new VideoController();
