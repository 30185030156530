// @flow
import Patient from '../views/patient';
import Login from '../views/login';
import Chat from '../views/chat';
import Video from '../views/video';
import Payments from '../views/payments/Payments';
import Profile from '../views/profile/Profile';
import Historic from '../views/historic/Historic';
import Clients from '../views/clients/Clients';
import Wallet from '../views/wallet/Wallet';
import Schedule from '../views/schedules/Schedules';
import GoogleCalendar from '../views/googleCalendar/GoogleCalendar';

const routes = [
  { path: '/login', component: Login, isPrivate: false },
  { path: '/chat', component: Chat, isPrivate: true },
  { path: '/patient/:id', component: Patient, isPrivate: true },
  { path: '/video', component: Video, isPrivate: true },
  { path: '/payments', component: Payments, isPrivate: true },
  { path: '/configuracoes', component: Profile, isPrivate: true },
  { path: '/history/:id', component: Historic, isPrivate: true },
  { path: '/clientes', component: Clients, isPrivate: true },
  { path: '/carteira', component: Wallet, isPrivate: true },
  { path: '/consultas', component: Schedule, isPrivate: true },
  { path: '/agenda', component: GoogleCalendar, isPrivate: true }
];

export default routes;
