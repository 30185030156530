import { combineReducers } from "redux";

import ServerAuthReducer from "./ServerAuthReducer";
import FirebaseAuthReducer from "./FirebaseAuthReducer";
import SecurityReducer from './SecurityReducer';

export default combineReducers({
  server: ServerAuthReducer,
  firebase: FirebaseAuthReducer,
  security: SecurityReducer
});
