// @flow
import * as React from 'react';
import moment from 'moment';
import { Editor, EditorState, ContentState, RichUtils } from 'draft-js';
import { Spinner } from '../../../reusable';
import styles from './NotesCard.module.css';

type Props = {
  note: PatientAnnotation,
  isFetching: boolean,
  editNote: (text: string) => void
};

type State = {
  isFocused: boolean,
  lastValidContent: string,
  editorState: EditorState
};

class NotesCardStateless extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      editorState: EditorState.createEmpty(),
      lastValidContent: props?.note?.text || '',
      isFocused: false
    };
  }

  componentDidMount() {
    this.setEditorWithLastValidContent();
  }

  setEditorWithLastValidContent = () => {
    const { lastValidContent } = this.state;

    this.setState({
      editorState: EditorState.createWithContent(ContentState.createFromText(lastValidContent))
    });
  };

  getEditorRef = (editor: ?React.ElementRef<*>) => {
    this.editor = editor;
  };

  onEditorChange = (editorState: EditorState) => {
    this.setState({ editorState });
  };

  onEditorBlur = () => {
    this.setState({ isFocused: false }, this.setEditorWithLastValidContent);
  };

  blurEditor = () => {
    if (this.editor) this.editor.blur();
  };

  focusEditor = () => {
    const { editorState } = this.state;
    this.setState({
      editorState: EditorState.moveFocusToEnd(editorState),
      isFocused: true
    });
  };

  handleEdit = (event: SyntheticMouseEvent<HTMLButtonElement>) => {
    this.focusEditor();
    event.preventDefault();
  };

  handleReturnCommand = () => {
    const { editorState, lastValidContent } = this.state;
    const { editNote } = this.props;
    const currentText: string = editorState
      .getCurrentContent()
      .getPlainText()
      .trim();

    this.setState(() => {
      if (currentText && currentText !== lastValidContent) {
        editNote(currentText);
        return { lastValidContent: currentText };
      }
      return null;
    }, this.blurEditor);
  };

  handleShiftReturnCommand = () => {
    const { editorState } = this.state;
    this.setState({ editorState: RichUtils.insertSoftNewline(editorState) });
  };

  handleReturn = (event: SyntheticKeyboardEvent<*>) => {
    if (event.shiftKey) {
      this.handleShiftReturnCommand();
      return 'not-handled';
    }
    this.handleReturnCommand();
    return 'handled';
  };

  editor: ?React.ElementRef<*>;

  render() {
    const { editorState, isFocused } = this.state;
    const { note, isFetching, isHistoric } = this.props;

    return (
      <div className={styles.container}>
        <h4>{moment(note.date, 'YYYY-MM-DD HH:mm:ss').calendar()}</h4>
        <div className={styles.card}>
          {!isFetching ? (
            <React.Fragment>
              <div className={styles.left}>
                <Editor
                  ref={this.getEditorRef}
                  readOnly={!isFocused}
                  onBlur={this.onEditorBlur}
                  onChange={this.onEditorChange}
                  editorState={editorState}
                  onEscape={this.blurEditor}
                  handleReturn={this.handleReturn}
                />
              </div>
              {!isHistoric ? (
                <div className={styles.right}>
                  {!isFocused && (
                    <button type="button" onClick={this.handleEdit} className={styles.edit}>
                      <i className="fas fa-pen" />
                    </button>
                  )}
                </div>
              ) : null}
            </React.Fragment>
          ) : (
            <Spinner size="2em" />
          )}
        </div>
      </div>
    );
  }
}

export default NotesCardStateless;
