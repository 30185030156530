// @flow
import * as React from 'react';
import { connect } from 'react-redux';

import BoxHeader from './header';
import BoxTalking from './talking';
import BoxInput from './input';
import styles from './ChatBox.module.css';

type OwnProps = {
  currentChat: ?Chat
};

const ChatBox = (props: OwnProps) => {
  const { currentChat } = props;

  return (
    <div className={styles.container}>
      {currentChat && currentChat.info ? (
        <React.Fragment>
          <BoxHeader />
          <BoxTalking />
          <BoxInput />
        </React.Fragment>
      ) : (
        <div className={styles.default}>
          <i className="fas fa-inbox" />
          <h2>Selecione um usuário</h2>
          <strong>E tenha acesso às mensagens trocadas entre você e ele.</strong>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: Store) => {
  const {
    chat: { currentChat }
  } = state;

  return { currentChat };
};

export default connect(mapStateToProps)(ChatBox);
