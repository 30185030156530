// @flow
import { PatientTypes } from '../../types';

const editPatientNote = (
  notes: PatientAnnotations,
  payload: { id: number, text: string }
): PatientAnnotations => {
  const note: ?PatientAnnotation = notes[payload.id];
  const editedNote: PatientAnnotations = {
    [payload.id]: { ...note, text: payload.text }
  };

  return { ...notes, ...editedNote };
};

const deletePatientNote = (notes: PatientAnnotations, id: number): PatientAnnotations => {
  const note: PatientAnnotations = { [id]: undefined };
  return { ...notes, ...note };
};

const createPatientNote = (
  notes: PatientAnnotations,
  payload: { id: number, note: PatientAnnotation }
): PatientAnnotations => {
  const note: PatientAnnotations = { [payload.id]: payload.note };
  return { ...notes, ...note };
};

export default (state: PatientAnnotations = {}, action: Action): PatientAnnotations => {
  const { type, payload } = action;

  switch (type) {
    case PatientTypes.SAVE_PATIENT_NOTES:
      return { ...payload };
    case PatientTypes.EDIT_PATIENT_NOTE: {
      const notes: PatientAnnotations = editPatientNote(state, payload);
      return { ...notes };
    }
    case PatientTypes.DELETE_PATIENT_NOTE: {
      const notes: PatientAnnotations = deletePatientNote(state, payload);
      return { ...notes };
    }
    case PatientTypes.CREATE_PATIENT_NOTE: {
      const notes: PatientAnnotations = createPatientNote(state, payload);
      return { ...notes };
    }
    default:
      return state;
  }
};
