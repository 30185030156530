// @flow
import { PatientActions, OldPatientActions } from '../redux/actions';
import { PatientServer } from '../server';
import { SecurityController } from './index';

export const fetchAndSaveProfile = (patientId: string) => (dispatch: Dispatch) =>
  PatientServer.fetchProfile(patientId).then(async (profile: PatientProfile) => {
    // VAMOS PEGAR O REASON E DECRIPTAR SE ELE TIVER O has_encrypted_data COMO TRUE
    const { has_encrypted_data, reason } = profile;
    const parsedProfile = has_encrypted_data
      ? {
          ...profile,
          reason: await SecurityController.decryptText(reason, true)
        }
      : profile;
    dispatch(PatientActions.saveProfile(parsedProfile));
    // VAI RETORNAR O UID PARA PODERMOS JA TENTAR PEGAR A PUBLIC KEY DEPOIS
    return profile.uid;
  });

export const fetchAndSaveStats = (patientId: string) => (dispatch: Dispatch) =>
  PatientServer.fetchStats(patientId).then((stats: PatientStatistics) =>
    dispatch(PatientActions.saveStats(stats))
  );

export const fetchAndSaveWeights = (patientId: string) => (dispatch: Dispatch) =>
  PatientServer.fetchWeights(patientId).then((response: { weights: Array<PatientWeight> }) =>
    dispatch(PatientActions.saveWeights(response.weights))
  );

export const fetchAndSaveWorkouts = (patientId: string) => (dispatch: Dispatch) =>
  PatientServer.fetchWorkouts(patientId).then((response: { activities: Array<PatientWorkout> }) =>
    dispatch(PatientActions.saveWorkouts(response.activities))
  );

export const fetchAndSaveMeals = (patientId: string) => (dispatch: Dispatch) =>
  PatientServer.fetchMeals(patientId).then((meals: PatientMeals) =>
    dispatch(PatientActions.saveMeals(meals))
  );

export const fetchAndSaveNotes = (patientId: string) => (dispatch: Dispatch) =>
  PatientServer.fetchNotes(patientId).then(async (notes: PatientAnnotations) => {
    // VAI PEGAR DO SERVIDOR E VER SE EH ENCRIPTADA OU NAO PARA DECRIPTAR
    const parsedNotes = await Promise.all(
      Object.values(notes).map(async note => {
        if (note.encrypted) {
          return {
            ...note,
            text: await SecurityController.decryptText(note.text, false)
          };
        } else {
          return note;
        }
      })
    );
    // VEM COMO ARRAY DO PROMISE.ALL MAS TEM QUE IR COMO OBJETO PARA O REDUX
    const parsedNotesObject = parsedNotes.reduce((obj, item) => ({ ...obj, [item.id]: item }), {});
    dispatch(PatientActions.saveNotes(parsedNotesObject));
  });

export const editNote = (patientId: string, note: PatientAnnotation) => async (
  dispatch: Dispatch
) => {
  const { text } = note;
  // ANTES DE MANDAR TEMOS QUE ENCRIPTAR SE TIVER CHAVES
  const [encryptedText, wasAbleToEncrypt] = await SecurityController.encryptText(text);
  const parsedNote = {
    ...note,
    text: encryptedText,
    encrypted: wasAbleToEncrypt
  };
  PatientServer.editNote(patientId, parsedNote).then(async (n: PatientAnnotation) => {
    // VAI TER QUE DECRIPTAR A MENSAGEM SE PRECISAR PARA MANDAR PARA O REDUX
    const returnedNote = n.encrypted
      ? { ...n, text: await SecurityController.decryptText(n.text, false) }
      : n;
    dispatch(PatientActions.editNote(n.id, returnedNote.text));
  });
};

export const createNote = (patientId: string, note: { date: string, text: string }) => async (
  dispatch: Dispatch
) => {
  const { text, date } = note;
  // ANTES DE CONSTRUIR O NOTE E ENVIAR VAMOS ENCRIPTAR O TEXTO
  const [encryptedText, wasAbleToEncrypt] = await SecurityController.encryptText(text);
  const parsedNote = {
    text: encryptedText,
    encrypted: wasAbleToEncrypt,
    date
  };

  PatientServer.createNote(patientId, parsedNote).then(async (n: PatientAnnotation) => {
    // VAI TER QUE DECRIPTAR A MENSAGEM SE PRECISAR PARA MANDAR PARA O REDUX
    const returnedNote = n.encrypted
      ? { ...n, text: await SecurityController.decryptText(n.text, false) }
      : n;
    dispatch(PatientActions.createNote(n.id, returnedNote));
  });
};

export const deleteNote = (patientId: string, id: number) => (dispatch: Dispatch) =>
  PatientServer.deleteNote(patientId, id).then(() => dispatch(PatientActions.deleteNote(id)));

export const fetchAndSaveGoals = (patientId: string) => (dispatch: Dispatch) =>
  PatientServer.fetchGoals(patientId).then(async (goals: PatientGoals) => {
    // VAI PEGAR DO SERVIDOR E VER SE EH ENCRIPTADA OU NAO PARA DECRIPTAR
    const parsedGoals = await Promise.all(
      Object.values(goals).map(async goal => {
        if (goal.encrypted) {
          return {
            ...goal,
            title: await SecurityController.decryptText(goal.title, false)
          };
        } else {
          return goal;
        }
      })
    );
    // VEM COMO ARRAY DO PROMISE.ALL MAS TEM QUE IR COMO OBJETO PARA O REDUX
    const parsedGoalsObject = parsedGoals.reduce((obj, item) => ({ ...obj, [item.id]: item }), {});
    dispatch(PatientActions.saveGoals(parsedGoalsObject));
  });

export const createGoal = (
  patientId: string,
  goal: {
    title: string,
    expireDate: string,
    frequency: number
  }
) => async (dispatch: Dispatch) => {
  const { title, expireDate, frequency } = goal;
  // ANTES DE CONSTRUIR A META E ENVIAR VAMOS ENCRIPTAR O TEXTO
  const [encryptedText, wasAbleToEncrypt] = await SecurityController.encryptText(title);
  const parsedGoal = {
    title: encryptedText,
    encrypted: wasAbleToEncrypt,
    expire_date: expireDate,
    frequency
  };

  return PatientServer.createGoal(patientId, parsedGoal)
    .then(async (g: PatientGoal) => {
      // VAI TER QUE DECRIPTAR A MENSAGEM SE PRECISAR PARA MANDAR PARA O REDUX
      const returnedGoal = g.encrypted
        ? { ...g, title: await SecurityController.decryptText(g.title, false) }
        : g;
      dispatch(PatientActions.createGoal(g.id, returnedGoal));
    })
    .catch(error => {
      throw error;
    });
};

export const deleteGoal = (
  patientId: string,
  goal: {
    id: number
  }
) => (dispatch: Dispatch) =>
  PatientServer.deleteGoal(patientId, goal).then((response: Object) => {
    dispatch(PatientActions.deleteGoal(goal.id));
  });

export const editDescription = (patientId: string, description: string) => (dispatch: Dispatch) =>
  PatientServer.editDescription(patientId, { description }).then(() =>
    dispatch(PatientActions.editDescription(description))
  );

export const fetchAndSaveDescription = (patientId: string) => (dispatch: Dispatch) =>
  PatientServer.fetchDescription(patientId).then(descObj =>
    dispatch(PatientActions.saveDescription(descObj.description))
  );

export const fetchAndSaveHumorLogs = (patientId: string) => (
  dispatch: Dispatch,
  getState: GetState
) => {
  const {
    patient: { profile }
  } = getState();
  const patientUid = profile && profile.uid;
  return PatientServer.fetchHumorLogs(patientId).then(async response => {
    // Ao fazer o fetch pode ser que a key do paciente ainda nao tenha sido pega do VIRGIL
    // aqui vamos verificar e se nao tiver temos que pega-la
    const patientPublicKey = SecurityController.getPatientPublicKey();
    if (!patientPublicKey) {
      await SecurityController.setUpPatientPublicKey(patientUid);
    }
    const decryptedHumors = await Promise.all(
      response.humor_list.map(async humor => {
        const decValue = await SecurityController.decryptText(humor.string_value, true);
        const decryptedHumor = humor.encrypted
          ? {
              ...humor,
              value: +decValue
            }
          : { ...humor };
        return decryptedHumor;
      })
    );
    dispatch(PatientActions.saveHumorLogs(decryptedHumors));
  });
};

export const fetchOldPatient = patientId => dispatch => {
  return PatientServer.fetchOldParticipant(patientId)
    .then(async res => {
      const notes = await Promise.all(
        Object.values(res.notes).map(async note => {
          if (note.encrypted) {
            return {
              ...note,
              text: await SecurityController.decryptText(note.text, false)
            };
          } else {
            return note;
          }
        })
      );

      const goals = await Promise.all(
        Object.values(res.goals).map(async goal => {
          if (goal.encrypted) {
            return {
              ...goal,
              titulo: await SecurityController.decryptText(goal.title, false),
              title: await SecurityController.decryptText(goal.title, false)
            };
          } else {
            return goal;
          }
        })
      );

      dispatch(OldPatientActions.saveOldPatient({ ...res, notes, goals }));
      return res;
    })
    .catch(err => {
      throw err;
    });
};
