// @flow
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { captureException } from '@sentry/browser';
import { AuthController, SecurityController } from '../../controllers';
import { Spinner } from '../reusable';

const style = {
  spinner: {
    margin: '10px auto',
    textAlign: 'center',
    verticalAlign: 'middle',
    position: 'absolute',
    top: '45%',
    left: '37.5%',
    width: '25%',
    height: '10%'
  }
};

class PrivateRoute extends React.Component<Props, State> {
  static defaultProps = {
    component: null,
    render: () => null,
    children: null,
    exact: false,
    strict: false,
    location: {},
    sensitive: false
  };
  // constructor(props) {
  //   super(props);
  //   this.state = { checkingAuthentication: true, checkingSecuritySetUp: true };
  //
  //   // firebase.auth().onAuthStateChanged(user => {
  //   // });
  // }

  state = { checkingAuthentication: true, checkingSecuritySetUp: true };

  componentDidMount = () => {
    const { refreshAuthenticatedFlag, isAuthenticated } = this.props;
    if (!isAuthenticated) {
      refreshAuthenticatedFlag().then(resp => {
        this.setState({ checkingAuthentication: false, checkingSecuritySetUp: false });
      });
    } else {
      // SE JA TIVER AUTENTICADO EH PORQUE VEIO DO LOGIN E COM ISSO A GENTE
      // TEM QUE REMOVER OS STATES DE checking
      // this.setState({ checkingAuthentication: false, checkingSecuritySetUp: false });
    }
  };

  setupSecurity = async () => {
    const { therapistUID, setUpSecuritySuccess } = this.props;
    // User que estou fazendo o login eh kin@pitang.com.br
    try {
      await SecurityController.setUpSecurity(therapistUID);
      setUpSecuritySuccess();
    } catch (e) {
      captureException(e);
    }
    // this.setState({ finishedSecuritySetup: true });
  };

  render = () => {
    const {
      isAuthenticated,
      signInError,
      securitySetUpSuccess,
      component: Component,
      ...rest
    } = this.props;
    const { checkingAuthentication, checkingSecuritySetUp } = this.state;

    if (isAuthenticated && securitySetUpSuccess) {
      return <Route {...rest} component={Component} />;
    }

    if (signInError) {
      return (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: rest.path }
          }}
        />
      );
    }

    if (checkingAuthentication && checkingSecuritySetUp) {
      return (
        <div style={style.spinner}>
          <p>Recuperando informações de segurança</p>
          <Spinner size="2em" />
        </div>
      );
    }

    return (
      <Redirect
        to={{
          pathname: '/login',
          state: { from: rest.path }
        }}
      />
    );
  };
}

const mapStateToProps = (state: Store) => {
  const {
    auth: { server, firebase, security }
  } = state;

  return {
    isAuthenticated: server.isAuthenticated && firebase.isAuthenticated,
    therapistUID: firebase && firebase.uid,
    signInError: server.signInError,
    securitySetUpSuccess: security.setUpSuccess
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  refreshAuthenticatedFlag: () => AuthController.refreshAuthenticatedFlag()(dispatch),
  setUpSecuritySuccess: () => dispatch(AuthController.setUpSecuritySuccess())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrivateRoute);
