// @flow
import { fetchServerData } from './utils';

export const fetchAppointments = (patientId: string): Promise<Object> => {
  const path: Array<string> = ['appointments', 'retrieve', patientId];
  return fetchServerData(path);
};

export const createAppointment = (
  patientId: string,
  appointment: { schedule: string, participant: number, therapist: number }
): Promise<Object> => {
  const path: Array<string> = ['appointments', 'book'];
  return fetchServerData(path, appointment, 'POST');
};

export const fetchUpcomingAppointments = (): Promise<Object> => {
  const path: Array<string> = ['appointments', 'retrieve-therapist-upcoming'];
  return fetchServerData(path);
};

export const automaticallyChangeAppointmentStatus = (): Promise<Object> => {
  const path: Array<string> = ['appointments', 'automatically-change-status'];
  return fetchServerData(path, {}, 'PUT');
};

export const changeAppointmentStatus = (appointmentId: number, status: string): Promise<Object> => {
  const path: Array<string> = ['appointments', 'change-status', `${appointmentId}`];
  return fetchServerData(path, { status }, 'POST');
};

export const retrieveTherapistAvailableDays = (therapistId: Number) => {
  const path: Array<string> = [
    'appointments',
    'v2',
    `retrieve-therapist-available-days/${therapistId}`
  ];
  return fetchServerData(path);
};

export const fetchTherapistTimeslots = (therapistId, day, duration, patientId) => {
  const path: Array<string> = [
    'appointments',
    `${therapistId}`,
    'retrieve-timeslots',
    `${day}`,
    `${duration}`,
    `patient`,
    `${patientId}`
  ];
  return fetchServerData(path);
};

export const reschedule = (id: number, body: Object) => {
  const path: Array<string> = ['appointments', 'v2', 'reschedule', `${id}`];
  return fetchServerData(path, body, 'PUT');
};
