// import moment from 'moment';
import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import styles from './CreateAvailableDay.module.css';
import { Spinner, Button } from '../../../reusable';
import { UserController } from '../../../../controllers';
import { weekDays } from '../../../../utils/Utils';

const CreateAvailableDay = props => {
  const { createAvailableDays, updateAvailableDay, id, editing, currentTime, setModal } = props;
  const av_id = currentTime?.id;

  const [day, setDay] = useState(editing ? currentTime.day : 0);
  const [start_time, setInitial] = useState(editing ? currentTime.start_time : undefined);
  const [end_time, setLast] = useState(editing ? currentTime.end_time : undefined);

  const [errorMessage, setError] = useState('');
  const [fetching, setFetching] = useState(false);

  const isValid = day !== undefined && start_time !== undefined && end_time !== undefined;

  const body = editing ? { id: av_id, day, start_time, end_time } : { day, start_time, end_time };

  const handleReq = async body => {
    setError('');

    if (editing) {
      setFetching(true);
      updateAvailableDay(body, id)
        .then(res => {
          setModal(false);
        })
        .catch(err => {
          setFetching(false);
          setError(err.message);
        });
    } else if (start_time < end_time) {
      setFetching(true);
      createAvailableDays(body, id)
        .then(res => {
          setTimeout(() => {
            setModal(false);
          }, 2000);
        })
        .catch(err => {
          setFetching(false);
          setError(err.message);
        });
    } else {
      setError('Horario inicial não pode ser maior que o final');
    }
  };

  return (
    <div>
      <form
        onSubmit={
          isValid
            ? e => {
                handleReq(body);
                e.preventDefault();
              }
            : null
        }
      >
        {fetching ? (
          <div className={styles.container}>
            <h4 style={{ textAlign: 'center' }}>Adicionando...</h4>
            <Spinner />
          </div>
        ) : (
          <div className={styles.container}>
            <h4 style={{ textAlign: 'center' }}>
              {editing ? 'Atualize o horário' : 'Adicione um horário'}
            </h4>
            {editing ? null : (
              <div>
                <p>Selecione um dia:</p>
                <select onChange={e => setDay(parseFloat(e.target.value))}>
                  {weekDays.map(days => {
                    return <option value={days.value}>{days.name}</option>;
                  })}
                </select>
              </div>
            )}

            <p>Horário inicial:</p>
            <input
              type="time"
              onChange={e => setInitial(e.target.value.concat(':00'))}
              max={end_time}
            />

            <p>Horário final:</p>
            <input
              type="time"
              onChange={e => setLast(e.target.value.concat(':00'))}
              min={start_time}
            />

            <div className={styles.error}>
              <p>{errorMessage}</p>
            </div>

            <Button
              backgroundColor={isValid ? '#2e9be2' : '#b7d6eb'}
              title={'Adicionar'}
              icon={'fas fa-plus-circle'}
              func={() => null}
              type={'submit'}
            ></Button>
          </div>
        )}
      </form>
    </div>
  );
};

const mapStateToProps = (state: Store) => {
  const {
    user: {
      profile,
      profile: { id }
    }
  } = state;

  return { profile, id };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    createAvailableDays: (body, therapistId) =>
      UserController.createAvailableDays(body, therapistId)(dispatch),
    updateAvailableDay: (body, therapistId) =>
      UserController.updateAvailableDays(body, therapistId)(dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateAvailableDay));
