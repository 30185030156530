// @flow
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import moment from 'moment';

import { retrieveUpcomingAppoinments, goToChatAndMakeCall } from '../chat/schedule/controller';
import UpcomingAppointment from '../chat/schedule/UpcomingAppointment';
import { Spinner } from '../reusable';
import styles from './Schedule.module.css';
import { changeAppointmentStatus } from '../patient/appointments/controller';
import { RouterActions } from '../../redux/actions';

type Props = {
  getUpcomingAppointments: Function,
  upcomingAppointments: Array<PatientAppointment>,
  history: any,
  makeCall: (?number, Function) => void
};

type State = {
  isFetching: boolean
};

// const ScheduleX = props => {
//   const { changeStatus, getUpcomingAppointments, makeCall, history } = props;

//   const handleAppointmentClick = patientId => makeCall(patientId, history?.push);

//   const checkCanCallAppointment = patientId => null;
// };

class Schedule extends React.Component<Props, State> {
  state = { isFetching: true };

  componentDidMount() {
    const { getUpcomingAppointments, saveHistoryOnRedux, setCurrentNav, history } = this.props;
    saveHistoryOnRedux(history);
    setCurrentNav('Próximas Consultas');
    getUpcomingAppointments().then(() => this.setState({ isFetching: false }));
  }

  handleAppointmentClick = (patientId: ?number) => {
    const {
      history: { push },
      makeCall
    } = this.props;
    makeCall(patientId, push);
  };

  checkCanCallAppointment = (appointment: PatientAppointment): boolean => {
    const schedule = moment(appointment.schedule);
    const now = moment();
    const moreThanSchedule = now > schedule;
    const lessThanFinalSchedule = now < schedule.add(30, 'minutes');
    // Adiciona 30min pra ver se agora esta entre o inicio da chamada e 30min dps
    return moreThanSchedule && lessThanFinalSchedule;
  };

  handleStatusUpdate = async (appointmentId: number, status: string) => {
    const { changeStatus, getUpcomingAppointments } = this.props;
    await changeStatus(appointmentId, status);
    getUpcomingAppointments().then(() => this.setState({ isFetching: false }));
  };

  render() {
    const { upcomingAppointments } = this.props;
    const { isFetching } = this.state;
    return (
      <div className={styles.contentContainer}>
        {isFetching ? (
          <div className={styles.spinner}>
            <Spinner />
          </div>
        ) : (
          upcomingAppointments &&
          upcomingAppointments.map(appo => (
            <UpcomingAppointment
              key={appo.id}
              appointment={appo}
              handleClick={() => this.handleAppointmentClick(appo.patient)}
              canCall={this.checkCanCallAppointment(appo)}
              changeStatus={this.handleStatusUpdate}
            />
          ))
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getUpcomingAppointments: () => dispatch(retrieveUpcomingAppoinments),
  makeCall: (patientId, pushHistory) => dispatch(goToChatAndMakeCall(patientId, pushHistory)),
  changeStatus: (appointmentId, status) => dispatch(changeAppointmentStatus(appointmentId, status)),
  setCurrentNav: current => dispatch(RouterActions.setCurrentRoute(current)),
  saveHistoryOnRedux: history => dispatch(RouterActions.saveHistory(history))
});

const mapStateToProps = (state: Store) => {
  const {
    appointments: { upcomingAppointments }
  } = state;
  return { upcomingAppointments };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Schedule)
);
