import styled, { keyframes } from 'styled-components';

const translateVertical = keyframes`
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0%);
  }
`;

export const WarningWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-style: italic;
  color: #666;
  font-family: 'Open Sans', sans-serif;
  i {
    margin: 5px 0;
    font-size: 4vw;
  }
`;

export const ErrorWrapper = styled.div`
  display: block;
  padding: 1em;
  background-color: #ffd2d2;
  color: #d8000c;
  font-family: 'Open Sans', sans-serif;
  animation: ${translateVertical} 0.5s ease-out;
  border-bottom: 2px solid #d8000c;
  i {
    margin: 0 14px;
  }
`;
