// @flow
import { ChatTypes } from '../../types';

const initialState: ChatsInfo = {};

export default (state: ChatsInfo = initialState, action: Action): ChatsInfo => {
  const { type, payload } = action;

  switch (type) {
    case ChatTypes.SAVE_CHATS_INFO:
      return {
        ...state,
        ...payload
      };
    case ChatTypes.CLEAR_CHATS_INFO:
      return {
        ...payload
      };
    case ChatTypes.UPDATE_CHAT_INFO:
      return payload.id ? {
        ...state,
        [payload.id]: {
          ...state[payload.id],
          ...payload.info
        }
      } : {
        ...state
      };
    default:
      return state;
  }
};
