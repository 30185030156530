// @flow
import { fetchServerData } from './utils';

export const retrieveChatsInfo = (therapistId: number): Promise<{ [key: string]: ChatInfo }> => {
  const path = ['chats-page', 'chats-info', `${therapistId}`];
  return fetchServerData(path);
};

export const retrieveOldChatsInfo = (therapistId: number): Promise<{ [key: string]: ChatInfo }> => {
  const path = ['chats-page', 'done-chats-info', `${therapistId}`];
  return fetchServerData(path);
};
