// @flow
import { AuthTypes } from '../../types';

const initialState: FirebaseAuth = {
  isAuthenticated: false,
  uid: null,
  emailVerified: null
};

export default (state: FirebaseAuth = initialState, action: Action): FirebaseAuth => {
  const { type, payload } = action;
  switch (type) {
    case AuthTypes.LOGIN_FIREBASE_SUCCESS:
      return { ...state, isAuthenticated: true, ...payload };
    case AuthTypes.LOGIN_FIREBASE_FAILED:
      return { ...state, isAuthenticated: false };
    case AuthTypes.LOGOUT_FIREBASE_SUCCESS:
      return { ...state, uid: null, emailVerified: null, isAuthenticated: false };
    case AuthTypes.SAVE_FIREBASE_AUTH:
      return { ...state, ...payload };
    default:
      return state;
  }
};
