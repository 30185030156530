// @flow
import * as React from 'react';
import * as Sentry from '@sentry/browser';
import { WarningWrapper, ErrorWrapper } from './styled';

type Props = {
  component?: React$ComponentType<*>,
  render?: mixed => React.Node,
  errorMessage?: string,
  warningMessage?: string,
  children: React.Node
};

type State = {
  hasError: boolean,
  hasWarning: boolean,
  error: ?Error,
  info: ?{ componentStack: string }
};

class ErrorBoundary extends React.Component<Props, State> {
  state = { hasError: false, hasWarning: false, error: null, info: null };

  static defaultProps = {
    component: null,
    render: null,
    warningMessage: null,
    errorMessage: 'Algo deu errado. Tente novamente.'
  };

  forceError = () => {
    this.setState({ hasError: true });
  };

  forceWarning = () => {
    this.setState({ hasWarning: true });
  };

  componentDidCatch(error: Error, info: { componentStack: string }) {
    Sentry.withScope(scope => {
      scope.setExtras(info);
      Sentry.captureException(error);
    });
    this.setState({ error, info });
    this.forceError();
  }

  render() {
    const { hasError, hasWarning, error, info } = this.state;
    const {
      children,
      component: ErrorComponent,
      render: renderError,
      errorMessage,
      warningMessage
    } = this.props;

    if (hasError) {
      if (ErrorComponent) {
        return <ErrorComponent error={error} info={info} />;
      }
      if (renderError) {
        return renderError();
      }

      return (
        <ErrorWrapper>
          <p style={{ whiteSpace: 'pre-wrap' }}>
            <i className="fas fa-exclamation-circle" />
            {errorMessage || (error && error.toString())}
          </p>
        </ErrorWrapper>
      );
    }

    if (hasWarning) {
      return (
        <WarningWrapper>
          <p style={{ whiteSpace: 'pre-wrap' }}>
            <i className="fas fa-exclamation-triangle" />
            {warningMessage}
          </p>
        </WarningWrapper>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
