import styled, { css } from 'styled-components';
import { Colors } from '../../../constants/Colors';

export const StyledPagination = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 1rem;
`;

export const StyledText = styled.p`
  font-family: 'Lato';
  font-size: 16px;
  font-weight: 600;
  color: ${Colors.text};
`;

export const PaginationButton = styled.button`
  background-color: #fff;
  border: 1px solid #000;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  font-size: 1rem;
  border-radius: 6px;
  cursor: pointer;
  margin: 0 0.5rem;

  &:hover {
    background-color: #c11c42;
    color: #fff;
    border: 1px solid #c11c42;
  }
`;

export const ActivePaginationButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  font-size: 1rem;
  border-radius: 6px;
  cursor: pointer;
  margin: 0 0.5rem;
  background-color: #e1204c;
  color: #fff;
  border: 1px solid #e1204c;
`;
