import React, { useState } from 'react';
import { StyledTab, StyledOption, StyledHeader } from './Styles';

const TabData = ({ component }) => <div>{component}</div>;

const Tab = props => {
  const [selected, setSelected] = useState({
    index: 0,
    component: props.children[0].props.children
  });

  return (
    <StyledTab>
      <StyledHeader>
        {props.children?.map(({ props: { index, label, children, data } }) => (
          <StyledOption
            onClick={() => setSelected({ index: index, component: children })}
            selected={index === selected.index}
          >
            {label}
          </StyledOption>
        ))}
      </StyledHeader>
      <TabData component={selected.component} />
    </StyledTab>
  );
};

export default Tab;
