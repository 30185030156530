// @flow
import React from 'react';
import type { RouterHistory, Location } from 'react-router-dom';
import { connect } from 'react-redux';
import { captureException } from '@sentry/browser';
import { AuthController, SecurityController } from '../../controllers';
import { AuthActions } from '../../redux/actions';
import LoginStateless from './Login.stateless';

type Props = {
  logIn: (
    credentials: ServerCredentials | FirebaseCredentials,
    callback: Function
  ) => PromiseAction,
  history: RouterHistory,
  location: Location
};

type State = {
  errorMessage: string
};

class Login extends React.Component<Props, State> {
  state = { errorMessage: '' };

  logInUser = (credentials: ServerCredentials | FirebaseCredentials, callback: Function) => {
    const { logIn, history, location, setUpSecuritySuccess, serverErrorMessage } = this.props;

    this.setState({ errorMessage: '' });
    logIn(credentials, credentials.email)
      .then(async (profile: Object) => {
        // Depois de logar vamos configurar as chaves do Virgil para poder criptografar
        try {
          const token = await AuthController.getVirgilJwtToken(profile.uid);
          await SecurityController.setUpSecurity(profile.uid, token);
          setUpSecuritySuccess();
        } catch (error) {
          // Mesmo dando erro no setUpSecurity podemos deixar entrar ja que no getEthree ele novamente
          // verifica se initializou o eThree
          captureException(error);
          setUpSecuritySuccess();
        }
        history.replace((location.state && location.state.from) || '/');
      })
      .catch(() => {
        this.setState({ errorMessage: serverErrorMessage });
      })
      .finally(callback);
  };

  render() {
    const { serverErrorMessage } = this.props;
    return <LoginStateless errorMessage={serverErrorMessage} logInUser={this.logInUser} />;
  }
}

const mapStateToProps = (state: Store) => {
  const {
    auth: {
      server: { errorMessage: serverErrorMessage }
    }
  } = state;

  return {
    serverErrorMessage
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  logIn: (credentials: ServerCredentials | FirebaseCredentials, username: string) =>
    AuthController.logIn(credentials, username)(dispatch),
  setUpSecuritySuccess: () => dispatch(AuthActions.setUpSecuritySuccess())
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
