import React from 'react';
import { Colors } from '../../../constants/Colors';
import { Icon } from '../';
import { StyledInput, StyledInputDiv, StyledTextInput } from './Styles';

const TextInput = props => {
  const {
    size,
    color,
    icon,
    placeholder,
    onChange,
    onFocus,
    defaultValue,
    name,
    register,
    type
  } = props;

  return (
    <StyledTextInput data-testid="textinput">
      <StyledInputDiv>
        {icon ? <Icon icon={icon} size={size} /> : null}
        <StyledInput
          onChange={e => onChange(e.target.value)}
          size={size}
          color={Colors[color]}
          placeholder={placeholder}
          icon={icon}
          type={type}
          name={name}
          ref={register}
          defaultValue={defaultValue}
          onFocus={onFocus}
        />
      </StyledInputDiv>
    </StyledTextInput>
  );
};

export default TextInput;

TextInput.defaultProps = {
  onChange: () => null,
  defaultValue: null
};
