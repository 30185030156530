import React from 'react';
import { StyledIcon, StyledIconImage } from './Styles';

const Icon = props => {
  const { icon, onClick, size } = props;
  return (
    <StyledIcon>
      <StyledIconImage src={icon} onClick={onClick} size={size}></StyledIconImage>
    </StyledIcon>
  );
};

export default Icon;
