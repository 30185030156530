// @flow
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import styles from './Schedule.module.css';
import { ParsedDate, CalendarAppoDate } from '../../../utils/Utils';
import { Button, TooltipButton, CustomModal, ConfirmModal } from '../../reusable';
import Reschedule from '../../patient/appointments/reschedule/Reschedule';
import { Colors } from '../../../constants/Colors';

type Props = {
  appointment: PatientAppointment,
  handleClick: Function,
  canCall: boolean
};

const translatedStatus = {
  confirmed: 'Confirmada',
  successful: 'Realizada',
  cancelled: 'Cancelada'
};

const UpcomingAppointment = (props: Props) => {
  const {
    appointment: { patientName, schedule, status },
    appointment,
    handleClick,
    canCall,
    changeStatus
  } = props;

  const [rescheduleModal, setRescheduleModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);

  const date = CalendarAppoDate(schedule);
  const callReschedule = appointment => (
    <Reschedule appointment={appointment} setModal={setRescheduleModal} />
  );

  return (
    <div className={styles.appointment}>
      <div className={styles.content}>
        <div className={styles.right}>
          <p className={styles.title}>{patientName}</p>
          <p className={styles.subtitle}>{date}</p>
          {canCall ? (
            <p className={styles.activeSubtitle}>Agora. Clique no ícone para ligar!</p>
          ) : (
            <p className={styles.subtitle}>{translatedStatus[status]}</p>
          )}
        </div>

        <div className={styles.left}>
          <TooltipButton
            func={() => setRescheduleModal(true)}
            icon={'fas fa-calendar'}
            backgroundColor={Colors.primary}
            title="Remarcar consulta"
          />
          <TooltipButton
            func={() => setConfirmModal(true)}
            icon={'fas fa-check'}
            backgroundColor={Colors.blue}
            title="Marcar como realizada"
          />
          {canCall ? (
            <Button func={() => handleClick()} icon={'fas fa-video'} color={'#00d963'} />
          ) : null}
        </div>
      </div>

      <CustomModal
        component={() => callReschedule(appointment)}
        modal={rescheduleModal}
        setModal={setRescheduleModal}
      />

      <ConfirmModal
        func={() => {
          changeStatus(appointment.id, 'successful');
          setConfirmModal(false);
        }}
        title={'Confirmar consulta como realizada?'}
        modal={confirmModal}
        setModal={setConfirmModal}
      />
    </div>
  );
};

export default UpcomingAppointment;
