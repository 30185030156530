// @flow
import React, { useState } from 'react';
import { Spinner, ContentEditable } from '../../../reusable';
import styles from './HeaderRight.module.css';

const HeaderRightStateless = props => {
  const { description, statistics, isLoading, onDescriptionEdited } = props;
  const { goals } = statistics || {};

  const [editorDisabled, setEditor] = useState(true);

  return !isLoading ? (
    <div className={styles.container}>
      <div>
        <ContentEditable
          placeholder="Escreva aqui uma breve descrição sobre o participante"
          isReadOnly={editorDisabled}
          onSubmit={onDescriptionEdited}
          onBlur={() => setEditor(true)}
          value={description}
          maxLength={140}
        />
        {editorDisabled && (
          <button type="button" className={styles.more} onClick={() => setEditor(false)}>
            Clique para editar
          </button>
        )}
      </div>
      <div className={styles.stats}>
        <small>
          <b>Metas:</b>
          <i>{goals ? ` ${goals.completed} batidas` : ' Não consta'}</i>
        </small>
      </div>
    </div>
  ) : (
    <Spinner />
  );
};

export default HeaderRightStateless;
