// @flow
import { PaymentActions } from '../redux/actions';
import { PaymentServer } from '../server';
import { PaymentTypes } from '../redux/types';
import moment from 'moment';
import {
  convertStatus,
  getParsedDate,
  getParsedId,
  getPreviousDate,
  getCurrentDate,
  getTransferPossiblity
} from '../utils/Utils';
import { saveCanTransfer } from '../redux/actions/PaymentActions';

export const fetchAndSaveAccountInfo = (therapistId: number) => async (dispatch: Dispatch) => {
  try {
    const balance = await PaymentServer.fetchAccountInfo(therapistId);
    dispatch(PaymentActions.saveAccountInfo(balance.account));
    dispatch(PaymentActions.saveBalance(balance.account.balance));
    dispatch(PaymentActions.saveBalanceInTransaction(balance.account.balance_in_transaction));
    dispatch(PaymentActions.saveCanTransfer(getTransferPossiblity(balance.account.last_withdraw)));
    return balance;
  } catch (err) {
    throw err;
  }
};

export const transferBalance = (therapistId, ammount) => dispatch => {
  if (ammount > 5) {
    return PaymentServer.transferBalance(therapistId, ammount)
      .then(async transferResponse => {
        dispatch(PaymentActions.saveCanTransfer(false));
        return { success: true, message: 'Saque realizado com sucesso!' };
      })
      .catch(err => {
        throw err;
      });
  } else {
    throw { success: false, message: 'Valor não pode ser menor que R$5,00' };
  }
};

export const fetchAndSaveSubscriptions = (
  therapistId,
  initialDate,
  finalDate,
  page
) => dispatch => {
  return PaymentServer.fetchAppointmentsSubscriptions(therapistId, initialDate, finalDate, page)
    .then(response => {
      dispatch(
        PaymentActions.saveSubscriptionsInfo({ subscriptions: response.subscriptions, page: page })
      );
      return {
        total_pages: response.total_pages,
        howmany_active_subscriptions: response.howmany_active_subscriptions
      };
    })
    .catch(err => {
      return err.message;
    });
};

export const fetchAndSaveCoparticipationInvoicesInfo = (
  therapistId,
  initialDate,
  finalDate
) => dispatch => {
  return PaymentServer.fetchCoparticipationInvoices(therapistId, initialDate, finalDate).then(
    async invoicesInfo => {
      dispatch(PaymentActions.saveCoparticipationsInfo(invoicesInfo));
    }
  );
};

export const getTherapistExtract = () => dispatch => {
  return PaymentServer.retrieveTherapistExtract()
    .then(resp => {
      dispatch(PaymentActions.saveTherapistExtract(resp.extract));
      return resp.extract;
    })
    .catch(_ => {
      return { error: 'Não foi possível encontrar' };
    });
};

export const fetchAndSaveAppointmentsInfo = (therapistId, initialDate, finalDate) => dispatch => {
  return PaymentServer.fetchAppointmentsInfo(therapistId, initialDate, finalDate).then(response => {
    dispatch(PaymentActions.saveAppointmentsInfo(response.invoices));
    localStorage.setItem('invoices', JSON.stringify(response.invoices));
  });
};

export const saveCurrentMonth = month => (dispatch: Dispatch) => {
  dispatch(PaymentActions.saveCurrentMonth(month));
};

// Mes vai de 0 a 11 entao tem que subtrair
export const getCurrentMonth = () => moment().month() + 1;

export const getAmountFromIncomes = incomes => {
  const month = getCurrentMonth();
  // Vai percorrer as assinaturas e separar por mes em relacao ao financial_return_date
  // que eh a data que o terapeuta ira receber
  // assim ele vai saber se recebe esse mes ou o proximo
  const monthAmountObject = incomes.reduce((prevObj, income) => {
    // Assim eu to ignorando as outras partes no destruct que seria assim
    // const [a,b,c] - "2020-05-09".split('-')
    if (income.financial_return_date) {
      const [, returnDateMonthString] = income.financial_return_date.split('-');
      // Transformando em int para poder comparar com o currentMonth
      const returnDateMonth = +returnDateMonthString;
      // Pegando o total atual do mes visto agora
      const incomeValue = income.value_to_receive;

      // Se ele tiver financial_return_date entao ele vai ganhar o pagamento desse mes daqui a 1 mes
      // Mas tb temos que ver se dessa assinatura ele vai receber um pagamento que foi do mes passado agora nesse mes
      // Para isso a gnt ve a diferenca do created_at e do last_paid_at
      // Se for maior que 28 dias(tem q considerar o caso especial de ter comecado em 1 de Fev)
      // entao eh pq ja teve mais de um mes de assinatura e no minimo esse mes eh o primeiro financial_return_date
      const dayDiff = moment(income.last_paid_at).diff(moment(income.created_at), 'days');
      const currentMonthAmount =
        dayDiff >= 28 ? (prevObj[month] || 0) + incomeValue : prevObj[month];
      const returnMonthAmount = prevObj[returnDateMonth]
        ? prevObj[returnDateMonth] + incomeValue
        : incomeValue;
      return {
        ...prevObj,
        [month]: currentMonthAmount,
        [returnDateMonth]: returnMonthAmount
      };
    } else {
      return { ...prevObj };
    }
  }, {});
  return monthAmountObject;
};

export const saveAmount = monthsAmountObject => (dispatch: Dispatch, getState: GetState) => {
  const {
    payment: {
      amount: { returnsByMonth }
    }
  } = getState();

  // Atualizando com o que ja tem no mes
  const monthsKeysStrings = Object.keys(monthsAmountObject);
  const monthsKeys = monthsKeysStrings.map(monthString => +monthString);
  const updatedReturnsByMonthWithReceivedObject = monthsKeys.reduce((prevObj, month) => {
    // Se monthsAmountObject[month] vier undefined e fosse somar daria NaN, entao tem que fazer o || com 0
    const newMonthAmount = returnsByMonth[month]
      ? returnsByMonth[month] + (monthsAmountObject[month] || 0)
      : monthsAmountObject[month];
    return {
      ...prevObj,
      [month]: newMonthAmount
    };
  }, {});

  // Na verdade a gente tem que juntar o que eu peguei das chaves do objeto a ser somado agora
  // com o resto do que ja tem no objeto de meses no REDUX
  // pq se o que a gente for salvar agora tiver menos meses do que o do REDUX ou o resultado vier {} o codigo quebra
  const realReturnsByMonth = {
    ...returnsByMonth,
    ...updatedReturnsByMonthWithReceivedObject
  };

  dispatch(PaymentActions.saveMonthsAmount(realReturnsByMonth));
};

export const cleanAmmount = () => dispatch => {
  dispatch(PaymentActions.cleanTotalAmmount());
};

export const getFinancialInfo = (month, year) => dispatch => {
  const currentMonth = getCurrentDate().month() + 1;
  const currentYear = getCurrentDate().year();

  return PaymentServer.fetchFinancialInfo(currentMonth, currentYear).then(currentMonthResponse => {
    if ('errors' in currentMonthResponse.withdraw) {
      return [
        { id: 'Erro interno', created_at: '', updated_at: '', value: '-', status: 'Sem informação' }
      ];
    }
    const withdrawData = currentMonthResponse.withdraw.items.map(withdraw => ({
      id: getParsedId(withdraw.id),
      created_at: getParsedDate(withdraw.created_at),
      updated_at: getParsedDate(withdraw.updated_at),
      value: withdraw.amount,
      status: convertStatus(withdraw.status) || 'Sem informação'
    }));

    const convertToFloat = amount => parseFloat(amount.split(' ')[1]);

    const currentFinancialData = currentMonthResponse.financial.transactions
      .filter(transaction => transaction.type === 'credit' || transaction.type === 'debit')
      .map(transaction => convertToFloat(transaction.amount))
      .reduce((a, b) => a + b, 0)
      .toFixed(2);

    dispatch(PaymentActions.saveCurrentMonthReceived(currentFinancialData));

    return withdrawData;
  });
};

export const getTotalAmountToReceive = amount => dispatch => {
  dispatch(PaymentActions.saveBalanceToReceive(amount));
};

export const filterPaymentsByText = text => dispatch => {
  const invoices = JSON.parse(localStorage.getItem('invoices'));

  const filterAction = invoices.filter(item => {
    return item?.id?.toLowerCase()?.includes(text.toLowerCase());
  });

  if (text == '') {
    dispatch(PaymentActions.saveAppointmentsInfo(invoices));
  } else {
    dispatch(PaymentActions.saveAppointmentsInfo(filterAction));
  }
};

export const filterPaymentsByDate = (firstDate, secondDate) => dispatch => {
  const invoices = JSON.parse(localStorage.getItem('invoices'));

  const filterAction = invoices.filter(item => {
    return item.financial_return_date > firstDate && item.financial_return_date < secondDate;
  });

  dispatch(PaymentActions.saveAppointmentsInfo(filterAction));
};
