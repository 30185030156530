import React from 'react';
import { TooltipIcon } from '..';
import { getStatusInfo, isStatus } from '../../../utils/Utils';
import {
  StyledTable,
  StyledHead,
  StyledTableBody,
  StyledTH,
  StyledTR,
  StyledTD,
  StyledContent
} from './Styles';

export default function Table(props) {
  const { data, headers } = props;

  const rows = data?.map((row, index) => (
    <Table.TR key={`key_${row}_${new Date().getTime() + index}`}>
      {Object.entries(row)
        .filter(([key, value]) => key !== 'is_coparticipation')
        .map(([key, value], index2) => {
          return (
            <Table.TD key={`key_${key}_${new Date().getTime() + index2}`} rowContent={row}>
              {value}
            </Table.TD>
          );
        })}
    </Table.TR>
  ));

  return (
    <StyledTable {...props}>
      <StyledHead>
        <Table.TR>
          {headers.map((head, index) => (
            <Table.TH key={`key_${head}_${new Date().getTime() + index}`}>{head}</Table.TH>
          ))}
        </Table.TR>
      </StyledHead>
      <StyledTableBody>{rows}</StyledTableBody>
    </StyledTable>
  );
}

Table.TH = props => <StyledTH {...props} />;

Table.TR = props => <StyledTR {...props} />;

Table.TD = props => {
  const { children: item } = props;

  const checkIsStatus = item => isStatus(item);

  const handleTooltip = item => {
    // const getDotColor = item => dotColor(item);
    if (checkIsStatus(item)) {
      return (
        <TooltipIcon placement="left" icon={'mdi mdi-help-circle'} title={getStatusInfo(item)} />
      );
    }
    if (item.toLowerCase() === 'depende do paciente') {
      return (
        <TooltipIcon
          placement="left"
          icon={'mdi mdi-help-circle'}
          title={'Assinaturas desse formato dependem da marcação de consultas do paciente'}
        />
      );
    }
    return false;
  };

  return (
    <StyledTD {...props}>
      <StyledContent
        isStatus={checkIsStatus(item)}
        dependsOnPatient={item.toLowerCase() === 'depende do paciente'}
      >
        {item}
        {handleTooltip(item)}
      </StyledContent>
    </StyledTD>
  );
};
