// @flow
import React, { useEffect, useState } from 'react';
import { blackCalendar } from '../../../assets/images/images';
import { Icon } from '..';
import { initialDate, lastDate } from '../../../utils/Utils';

import {
  StyledDatepicker,
  StyledDate,
  StyledDatepickerIcon,
  StyledDatePickerInputs
} from './Styles';
// import { setSeconds } from 'date-fns';

// eslint-disable-next-line react/prop-types
const Datepicker = ({ onChange }) => {
  const [firstDate, setfirstDate] = useState(initialDate);
  const [secondDate, setsecondDate] = useState(lastDate);

  useEffect(() => {
    Promise.all([onChange(firstDate, secondDate)]).then(resp => {
      const { fromDate, toDate } = resp[0];
      if (resp[0].fromDate && resp[0].toDate) {
        setfirstDate(fromDate);
        setsecondDate(toDate);
      }
    });
  }, [firstDate, secondDate]);

  return (
    <StyledDatepicker>
      <StyledDatepickerIcon>
        <Icon icon={blackCalendar} size="sm" />
      </StyledDatepickerIcon>
      <StyledDatePickerInputs>
        <StyledDate value={firstDate} type="date" onChange={e => setfirstDate(e.target.value)} />
        <div> - </div>
        <StyledDate value={secondDate} type="date" onChange={e => setsecondDate(e.target.value)} />
      </StyledDatePickerInputs>
    </StyledDatepicker>
  );
};

export default Datepicker;

// export const Datepicker = props => {
//   const { initialDate, lastDate, setDate, filter } = props;

//   const handleChange = (event, type) => {
//     setDate(event.target.value, type === 'initial' ? 'initial' : 'last');
//   };

//   return (
//     <div className={styles.datepicker}>
//       <p style={{ color: 'white' }}>
//         Filtrado por {filter}
//         {'\t'}
//       </p>
//       <div className={styles.firstDate}>
//         <input
//           type="date"
//           value={initialDate}
//           max={lastDate}
//           onChange={e => handleChange(e, 'initial')}
//           className={styles.input}
//         />
//       </div>
//       <div className={styles.lastDate}>
//         <input
//           type="date"
//           value={lastDate}
//           onChange={e => handleChange(e, 'last')}
//           className={styles.input}
//         />
//       </div>
//     </div>
//   );
// };
