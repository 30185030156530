// @flow
import { PatientActions } from '../../../redux/actions';
import { AppointmentServer } from '../../../server';

export const createAppointment = (scheduleHour: string): ThunkAction => (
  dispatch: Dispatch,
  getState: GetState
): Promise<any> => {
  const {
    user: {
      profile: { coach_id: therapist }
    },
    patient: {
      profile: { id: participant }
    }
  } = getState();

  const objToServer = {
    participant,
    therapist,
    schedule: scheduleHour
  };

  return AppointmentServer.createAppointment(participant, objToServer).then(response => {
    const {
      appointment: { id, schedule }
    } = response;
    const appointment = { id, schedule, duration: 20, status: 'confirmed' };
    dispatch(PatientActions.createAppointment(appointment));
  });
};

export const fetchAppointments = (patientId: string) => (dispatch: Dispatch): Promise<Object> => {
  return AppointmentServer.fetchAppointments(patientId).then(response => {
    const { appointments, oldAppointments } = response;
    dispatch(PatientActions.saveAppointments(appointments));
    dispatch(PatientActions.saveOldAppointments(oldAppointments));
  });
};

export const changeAppointmentStatus = (appointmentId, status) => (dispatch, getState) => {
  const {
    patient: {
      profile: { id }
    }
  } = getState();

  return AppointmentServer.changeAppointmentStatus(appointmentId, status).then(() => {
    return AppointmentServer.fetchAppointments(id).then(res => {
      const { appointments, oldAppointments } = res;
      dispatch(PatientActions.updateAfterChangeStatus(appointments, oldAppointments));
    });
  });
};

export const getTherapistAvailableDays = (therapistId: number) => {
  return AppointmentServer.retrieveTherapistAvailableDays(therapistId)
    .then(res => {
      const days = res.days.map(d => (d + 1 === 7 ? (d = 0) : d + 1));
      return days;
    })
    .catch(err => {
      return [0, 1, 2, 3, 4, 5, 6];
    });
};

export const retrieveTimeslots = (therapistId, day, duration, patientId) =>
  AppointmentServer.fetchTherapistTimeslots(therapistId, day, duration, patientId)
    .then(res => res)
    .catch(err => {
      throw err;
    });

export const getAppointmentDuration = (planName: string) => {
  if (planName.includes('20min') || planName.includes('basico')) {
    return 20;
  } else if (planName.includes('40min') || planName.includes('johnson')) {
    return 40;
  } else {
    return 40;
  }
};

export const rescheduleAppointment = (
  schedule: string,
  duration: number,
  scheduleId: number,
  userId,
  therapistId
) => (dispatch: Dispatch): Promise<Object> => {
  const body = {
    patient: userId,
    therapist: therapistId,
    schedule,
    duration
  };

  return AppointmentServer.reschedule(scheduleId, body)
    .then(response => {})
    .catch(err => {
      throw err;
    });
};
