import React, { useState } from 'react';
import styles from './VideoFeedback.module.css';
import VideoController from '../../../controllers/VideoController';
import { Button } from '../../reusable';

const RatingIcons = props => {
  const { rating, setRating } = props;
  const [starIcons, setStar] = useState(rating);

  const rate = [1, 2, 3, 4, 5];

  const handleClass = star => {
    return starIcons < star ? 'gray' : 'yellow';
  };

  return (
    <div>
      {rate.map(star => (
        <div
          className={`${styles.star} fa fa-star`}
          style={{ color: handleClass(star) }}
          onClick={() => {
            setRating(star);
            setStar(star);
          }}
          onMouseOver={() => {
            handleClass(star);
            setStar(star);
          }}
          onMouseOut={() => {
            handleClass(rating);
            setStar(rating);
          }}
        ></div>
      ))}
    </div>
  );
};

const VideoFeedback = props => {
  const { setModal } = props;
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState('');

  const [fetching, isFetching] = useState(false);
  const [success, setSuccess] = useState(false);

  const body = { rating, comment, type: 'video' };

  const submit = () => {
    isFetching(true);
    VideoController.submitFeedback(body)
      .then(res => {
        setSuccess(true);
        isFetching(false);

        setTimeout(() => {
          setModal(false);
        }, 2000);
      })
      .catch(err => {});
  };
  return (
    <div className={styles.container}>
      <h4>Avalie a qualidade da sua chamada</h4>
      <RatingIcons rating={rating} setRating={setRating} />
      <textarea className={styles.input} onChange={e => setComment(e.target.value)} />
      {!success && !fetching ? (
        <Button
          title={'Enviar feedback!'}
          icon={'fas fa-check'}
          func={() => submit()}
          color={'#3cb2df'}
        />
      ) : (
        <div className={styles.success}>Obrigado pelo feedback!</div>
      )}
    </div>
  );
};

export default VideoFeedback;
