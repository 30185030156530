import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from './header/Header';
import AvailableDays from './available-days/AvailableDays';
import { RouterActions } from '../../redux/actions';
import styles from './Profile.module.css';

const Profile = props => {
  // eslint-disable-next-line react/prop-types
  const { history, saveHistoryOnRedux } = props;

  useEffect(() => {
    saveHistoryOnRedux(history);
  }, []);

  return (
    <div>
      <Header />
      <div className={styles.cards}>
        <AvailableDays />
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return state;
};

const mapDispatchToProps = dispatch => {
  return {
    saveHistoryOnRedux: history => dispatch(RouterActions.saveHistory(history))
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Profile));
