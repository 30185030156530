import styled from 'styled-components';
import { Colors } from '../../constants/Colors';

export const StyledWallet = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  flex-direction: column;
  padding: 25px;
  background-color: ${Colors.background};
`;

export const StyledWalletTable = styled.div`
  padding: 15px;
  margin-top: 20px;
  width: 100%;
`;

export const DivWithdrawTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const BtnResetFilter = styled.button`
  border: none;
  background-color: transparent;
`;

export const TitleText = styled.p`
  font-size: 1.5rem;
  margin-bottom: 0;
`;

export const StyledWalletButtons = styled.div`
  align-self: center;
  width: 98%;
`;

export const WalletReport = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  padding: 10px 15px;
`;

export const WalletOBS = styled.p`
  margin: 0.5rem 0;
  padding: 0 15px;
`;

export const SelectField = styled.select`
  width: 150px;
`;

export const LoadingDiv = styled.div`
  min-width: 300px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
