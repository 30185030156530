import { PaymentTypes } from '../../types';

const initialState = {
  invoices: [],
  current_month_coparticipation_invoices_payment: 0,
  next_month_coparticipation_invoices_payment: 0
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case PaymentTypes.SAVE_THERAPIST_COPARTICIPATION_INFO:
      return { ...state, ...payload };
    default:
      return state;
  }
};
