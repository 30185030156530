// @flow
import * as React from 'react';
import cx from 'classnames';
import styles from './HeaderLeft.module.css';
import { Avatar, Spinner } from '../../../reusable';
import moment from 'moment';

type Props = {
  profile: ?PatientProfile,
  isLoading: boolean
};

// Gambiarra para quando vier dia eu mudar a ordem de 2019-08-22 para a certa
const getParsedValue = (stage, status) =>
  stage && stage.value && typeof stage.value === 'string'
    ? stage.value
        .split('-')
        .reverse()
        .join('/')
    : stage.value;

const getPlanDescription = (plan: string, planNameId: string): string => {
  if (plan) {
    if (plan.includes('Básica')) {
      return plan + ' - Ligação de 20 minutos';
    } else if (plan.includes('Completa')) {
      return plan + ' - Ligação de 40 minutos';
    } else if (plan.includes('Consulta Avulsa')) {
      return plan + ' - Ligação de 40 minutos';
    } else if (plan.includes('Plano Básico')) {
      return plan + ' - Ligação de 20 minutos';
    } else if (plan.includes('Plano Completo')) {
      return plan + ' - Ligação de 40 minutos';
    } else if (plan.includes('Agendar Sessão')) {
      // Tem que ver qual o name_id para saber de qual empresa eh
      try {
        const companyName = planNameId && planNameId.split('_').slice(-1)[0];
        const company = companyName
          ? companyName.charAt(0).toUpperCase() + companyName.slice(1)
          : '';
        return 'Consulta Avulsa ' + company + ' - Lançamento Gratuito - Ligação de 40 minutos';
      } catch (e) {
        return 'Consulta Avulsa - Lançamento Gratuito - Ligação de 40 minutos';
      }
    } else {
      return plan;
    }
  } else {
    return plan;
  }
};

const HeaderLeftStateless = (props: Props) => {
  const { profile, isLoading, isHistory, oldPatient } = props;
  const name = isHistory ? oldPatient?.nome : profile?.name;
  const age = isHistory ? moment().diff(oldPatient?.data_nascimento, 'years') : profile?.age;
  const thumbnail = isHistory ? null : profile?.thumbnail;
  const stage = isHistory ? 'Finalizado' : profile?.stage;
  const status = isHistory ? 'Finalizado' : profile?.status;
  const phone = isHistory ? oldPatient?.telefone : profile?.phone;
  const plan = isHistory ? oldPatient?.status : profile?.plan;
  const reason = isHistory ? oldPatient?.reason : profile?.reason;
  const planNameId = isHistory ? null : profile?.planNameId;
  const location = isHistory ? oldPatient?.location : profile?.location;
  const email = isHistory ? '' : profile?.email;
  const cpf = isHistory ? '' : profile?.cpf;

  const signupStatus = status || '';
  return !isLoading ? (
    <div className={styles.container}>
      <div className={styles.avatar}>
        <Avatar id="avatar" size="8em" src={thumbnail} name={name} />
      </div>
      <div className={styles.profile}>
        <div className={styles.row}>
          <strong>{name},&nbsp;</strong>
          <small className={styles.bold}>{age} </small>
        </div>
        {reason && (
          <div className={styles.row}>
            <i className={cx('fas fa-crosshairs', styles.icon)} />
            <small>{`Motivo para a terapia: ${reason}`}</small>
          </div>
        )}
        <div className={styles.row}>
          <div className={styles.iconDiv}>
            <i className={cx('fas fa-phone', styles.icon)} />
          </div>
          <small>
            ({phone?.code}){phone?.number}
          </small>
        </div>
        <div className={styles.row}>
          <div className={styles.iconDiv}>
            <i className={cx('far fa-calendar-alt', styles.icon)} />
          </div>
          <small>
            {stage && stage.value
              ? `${stage.type} ${getParsedValue(stage, status)} - ${signupStatus}`
              : `${signupStatus}`}
          </small>
        </div>

        <div className={styles.row}>
          <div className={styles.iconDiv}>
            <i className={cx('fas fa-address-card', styles.icon)} />
          </div>
          <small>
            {' '}
            {location?.city}, {location?.state}{' '}
          </small>
        </div>
        <div className={styles.row}>
          <div className={styles.iconDiv}>
            <i className={cx('fas fa-envelope', styles.icon)} />
          </div>
          <small> {email || '--'} </small>
        </div>
        <div className={styles.row}>
          <div className={styles.iconDiv}>
            <i className={cx('fas fa-user', styles.icon)} />
          </div>
          <small> {cpf || '--'} </small>
        </div>
        <div className={styles.row}>
          <div className={styles.iconDiv}>
            <i className={cx('fas fa-map', styles.icon)} />
          </div>
          <small> {getPlanDescription(plan, planNameId)} </small>
        </div>
      </div>
    </div>
  ) : (
    <Spinner />
  );
};

export default HeaderLeftStateless;
