import styled, { css } from 'styled-components';
import { Colors } from '../../../constants/Colors';

export const StyledCard = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  font-family: Roboto;
  border: 0.5px solid ${Colors.fourth};
  height: 111px;
  border-radius: 4px;
  padding: 20px;
  margin: 15px;
  margin-top: 20px;
  background-color: ${Colors.third};

  @media (max-width: 600px) {
    max-height: 350px;
  }
`;

export const StyledTitle = styled.div`
  font-size: 15px;
`;

export const StyledValue = styled.div`
  font-size: 30px;
  font-weight: bold;

  ${props =>
    props.ammount &&
    css`
      &:before {
        content: 'R$\t';
        font-size: 15px;
      }
    `}
`;

export const StyledTip = styled.div`
  position: absolute;
  display: flex;
  align-self: flex-end;
  justify-content: flex-end;
  bottom: 10px;
`;
