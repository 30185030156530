import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { PaymentController } from '../../controllers';
import { Spinner, Table, Card, Grid } from '../reusable';
import { initialDate, lastDate, subscriptionTableHeaeder } from '../../utils/Utils';
import { StyledClientes, StyledClientsTable, StyledLoadingDiv } from './Styles';
import { RouterActions } from '../../redux/actions';
import PaginatorComponent from '../reusable/pagination/Pagination';

const Clients = props => {
  const { saveHistoryOnRedux, setCurrentNav, history } = props;
  const { profile, subscriptions } = props;
  const { getSubscriptions, getInvoices } = props;

  const [isFetching, setFetching] = useState(true);
  const [page, setPage] = useState(1);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [activeSubscriptions, setActiveSubscriptions] = useState(0);

  const fetching = async () => {
    try {
      setFetching(true);

      saveHistoryOnRedux(history);
      setCurrentNav('Clientes');

      getPaginatedSubscriptions(1);
      await getInvoices(profile.id, initialDate, lastDate);

      setFetching(false);
    } catch (err) {}
  };

  useEffect(() => {
    fetching();
  }, []);

  const getPaginatedSubscriptions = async (page: number) => {
    setIsPageLoading(true);
    const resp = await getSubscriptions(profile.id, initialDate, lastDate, page);
    setTotalPages(resp.total_pages);
    setActiveSubscriptions(resp.howmany_active_subscriptions);
    setIsPageLoading(false);
  };

  useEffect(() => {
    getPaginatedSubscriptions(page);
  }, [page]);

  const goToPage = (newPage: number) => {
    if (totalPages !== null && totalPages >= newPage) {
      setIsPageLoading(true);
      setPage(newPage);
    }
  };

  return !isFetching ? (
    <StyledClientes>
      <Grid rows={1} columns={2}>
        <Card title={'Pacientes Ativos'} value={activeSubscriptions} tip />
      </Grid>

      <StyledClientsTable>
        {isPageLoading ? (
          <StyledLoadingDiv>
            <Spinner />
          </StyledLoadingDiv>
        ) : (
          <Table headers={subscriptionTableHeaeder} data={subscriptions} />
        )}
        <PaginatorComponent activePage={page} handleClick={goToPage} maxPages={totalPages} />
      </StyledClientsTable>
    </StyledClientes>
  ) : (
    <Spinner />
  );
};

const mapStateToProps = state => {
  const {
    user: { profile },
    payment: {
      subscriptionsInfo: { subscriptions }
    }
  } = state;

  return { profile, subscriptions };
};

const mapDispatchToProps = dispatch => {
  return {
    getSubscriptions: (therapistId, initialDate, finalDate, page) =>
      PaymentController.fetchAndSaveSubscriptions(
        therapistId,
        initialDate,
        finalDate,
        page
      )(dispatch),
    getInvoices: (therapistId, initialDate, finalDate) =>
      PaymentController.fetchAndSaveAppointmentsInfo(therapistId, initialDate, finalDate)(dispatch),
    saveAmount: ammount => dispatch(PaymentController.saveAmount(ammount)),
    setCurrentNav: current => dispatch(RouterActions.setCurrentRoute(current)),
    saveHistoryOnRedux: history => dispatch(RouterActions.saveHistory(history))
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Clients));
