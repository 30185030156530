import React from 'react';
import { StyledPagination, ActivePaginationButton, PaginationButton } from './Styles';

type PaginatorProps = {
  activePage: number,
  handleClick: (newPage: number) => void,
  maxPages: number
};

export default function PaginatorComponent({ activePage, handleClick, maxPages }: PaginatorProps) {
  const isShorterPages = maxPages > 7;
  const showProperPages = (pagesArray: number[]): number[] => {
    let newPagesArray: number[] = [];
    if (maxPages > 7) {
      let midPage = JSON.parse(JSON.stringify(activePage));
      if (midPage > maxPages - 2) {
        midPage = midPage === maxPages ? midPage - 2 : midPage - 1;
      } else if (midPage < 3) {
        midPage = midPage === 1 ? midPage + 2 : midPage + 1;
      }
      newPagesArray = [1, midPage - 1, midPage, midPage + 1, maxPages];
    } else {
      newPagesArray = [...pagesArray];
    }
    return newPagesArray;
  };

  const pages = showProperPages(Array.from({ length: maxPages }, (_, i) => i + 1));

  return (
    <StyledPagination>
      {pages.map((pageNumber, index) => {
        const need3DotsBefore =
          isShorterPages && index === pages.length - 1 && pages[index - 1] !== pageNumber - 1;

        const need3DotsAfter = isShorterPages && index === 0 && pages[index + 1] !== pageNumber + 1;

        return (
          <React.Fragment key={`fragment_${pageNumber}`}>
            {need3DotsBefore ? <span>...</span> : null}
            {pageNumber === activePage ? (
              <ActivePaginationButton onClick={() => handleClick(pageNumber)}>
                {pageNumber}
              </ActivePaginationButton>
            ) : (
              <PaginationButton onClick={() => handleClick(pageNumber)}>
                {pageNumber}
              </PaginationButton>
            )}

            {need3DotsAfter ? <span>...</span> : null}
          </React.Fragment>
        );
      })}
    </StyledPagination>
  );
}
