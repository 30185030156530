// @flow
import { PatientTypes } from '../../types';

const createPatientGoal = (goals: PatientGoals, payload: { id: number, goal: PatientGoal }) => {
  const goal: PatientGoal = { [payload.id]: payload.goal };
  return { ...goals, ...goal };
};

const deletePatientGoal = (goals: PatientGoals, goalId: number) => {
  const { [goalId]: goalRemoved, ...newGoals } = goals;
  return newGoals;
};

const updatePatientGoal = (goals: PatientGoals, payload: { id: number, goal: PatientGoal }) => {
  return { ...goals, [payload.id]: payload.goal };
};

export default (state: PatientGoals = {}, action: Action): PatientGoals => {
  const { type, payload } = action;

  switch (type) {
    case PatientTypes.SAVE_PATIENT_GOALS:
      return { ...payload };
    case PatientTypes.CREATE_PATIENT_GOAL: {
      const goals: PatientGoals = createPatientGoal(state, payload);
      return { ...goals };
    }
    case PatientTypes.DELETE_PATIENT_GOAL: {
      const goals: PatientGoals = deletePatientGoal(state, payload);
      return { ...goals };
    }
    case PatientTypes.UPDATE_PATIENT_GOAL: {
      const goals: PatientGoals = updatePatientGoal(state, payload);
      return { ...goals };
    }
    default:
      return state;
  }
};
