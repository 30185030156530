import { PatientTypes } from '../../types';

const initialState = {
  data: {
    nome: '',
    status: '',
    data_nascimento: '',
    telefone: '',
    data_inicio: '',
    data_saida: '',
    descricao: '',
    uid: '',
    reason: '',
    old_chat_id: '',
    appointments: [],
    notes: [],
    humor: [],
    goals: []
  }
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case PatientTypes.SAVE_OLD_PATIENT:
      return { data: payload };
    case PatientTypes.CLEAR_OLD_PATIENT:
      return { ...initialState };
    default:
      return state;
  }
};
