// @flow
import React from "react";
import Container from "./Avatar.styled";

type Props = {
  size?: string,
  src?: string,
  name?: string,
  background?: string,
  color?: string,
  rounded?: boolean,
  href?: string,
  onClick?: Function
};

const Avatar = (props: Props) => {
  const { size, src, name, background, color, rounded, href, onClick } = props;

  const defaultName: string = name || "";
  const defaultBackground: string = background || "";
  const defaultColor: string = color || "";
  const defaultRounded: string = rounded ? "true" : "false";

  const defaultSrc: string =
    `https://ui-avatars.com/api/?name=${defaultName.replace(/ /g, "+")}` +
    `&background=${defaultBackground}&color=${defaultColor}` +
    `&size=256&rounded=${defaultRounded}`;

  return (
    <Container
      href={href}
      onClick={onClick}
      size={size}
      src={src}
      defaultSrc={defaultSrc}
    />
  );
};

Avatar.defaultProps = {
  size: "8em",
  src: undefined,
  name: "No Name",
  background: "ddd",
  color: "eb5c74",
  rounded: true,
  href: undefined,
  onClick: () => {}
};

export default Avatar;
