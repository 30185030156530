// @flow
import React, { useRef, useState } from 'react';
import styles from './Tooltip.module.css';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { Button as CustomButton } from '../index';

export const TooltipIcon = props => {
  const { title, icon, clickFunction, placement } = props;

  return (
    <>
      <OverlayTrigger
        placement={placement}
        delay={{ show: 250, hide: 400 }}
        overlay={props => (
          <Tooltip id="overlay-example" {...props}>
            {title}
          </Tooltip>
        )}
      >
        <span className={`${icon} ${styles.icon}`} onClick={() => clickFunction()}></span>
      </OverlayTrigger>
    </>
  );
};

export const TooltipButton = props => {
  const { func, icon, color, title, backgroundColor } = props;
  return (
    <OverlayTrigger
      placement="right"
      delay={{ show: 250, hide: 400 }}
      overlay={props => (
        <Tooltip id="overlay-example" {...props}>
          {title}
        </Tooltip>
      )}
    >
      <CustomButton func={func} icon={icon} color={color} backgroundColor={backgroundColor} />
    </OverlayTrigger>
  );
};

TooltipIcon.defaultProps = {
  clickFunction: () => null
};
