// @flow
import * as React from 'react';
import { withRouter } from 'react-router-dom';
import type { Match } from 'react-router-dom';
import { connect } from 'react-redux';
import { PatientController } from '../../../../controllers';
import { ErrorBoundary } from '../../../reusable';

import NotesCardStateless from './NotesCard.stateless';

type OwnProps = {
  match: Match
};

type State = {
  isFetching: boolean
};

type Props = {
  note: PatientAnnotation,
  editNote: (note: PatientAnnotation) => PromiseAction
};

class NotesCard extends React.Component<Props, State> {
  state = { isFetching: false };

  toggleFetching = () => {
    this.setState(prevState => ({ isFetching: !prevState.isFetching }));
  };

  forceWarningMessage = () => {
    if (this.errorBoundary) this.errorBoundary.forceWarning();
  };

  editNoteData = (text: string) => {
    const { note, editNote } = this.props;

    this.toggleFetching();

    editNote({ ...note, text })
      .catch(this.forceWarningMessage)
      .finally(this.toggleFetching);
  };

  errorBoundary: ?React$ElementRef<*>;

  render() {
    const { note, isHistoric } = this.props;
    const { isFetching } = this.state;

    return (
      <ErrorBoundary
        ref={ref => {
          this.errorBoundary = ref;
        }}
        warningMessage="Não foi possível realizar esta ação"
      >
        <NotesCardStateless
          note={note}
          isFetching={isFetching}
          editNote={this.editNoteData}
          isHistoric={isHistoric}
        />
      </ErrorBoundary>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch, ownProps: OwnProps) => {
  const { match } = ownProps;
  const patientId: string = match.params.id || '';

  return {
    editNote: (note: PatientAnnotation) => PatientController.editNote(patientId, note)(dispatch)
  };
};

export default withRouter(connect(null, mapDispatchToProps)(NotesCard));
