// @flow
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import HumorStateless from './Humor.stateless';
import { PatientController, SecurityController } from '../../../../controllers';
import { ErrorBoundary } from '../../../reusable';

type Props = {
  humorLogs: Array<PatientHumor>,
  retrieveHumorLogs: () => Promise<void>
};

type State = {
  loading: boolean
};

class HumorMonitoring extends React.Component<Props, State> {
  state = { loading: true };

  componentDidMount() {
    const { retrieveHumorLogs, patientUid } = this.props;
    // SE no componentDidMount ja tiver o patientUid no redux entao podemos setar a key do virgilsecurity
    // e ja pegar os logs decriptados, se nao tiver entao so depois que atualizar o redux
    if (patientUid) {
      retrieveHumorLogs()
        .then(() => {})
        .catch(error => {
          this.errorBoundary.forceError();
        })
        .finally(() => this.setState({ loading: false }));
      // .finally(() => //
    }
  }

  async componentDidUpdate(prevProps) {
    const { loading } = this.state;
    const { humorLogs, patientUid, retrieveHumorLogs } = this.props;

    if (humorLogs && !humorLogs.length && this.errorBoundary && !loading) {
      this.errorBoundary.forceWarning();
    }

    // Quando o patientUid for atualizado com o uid eh que podemos pegar os logs
    // ja que eles vao vir encriptados e so com a key do virgil setada pelo uid eh que podemos decriptar
    if (prevProps.patientUid !== patientUid) {
      // Se agora tem patientUid vamos setar a key
      await SecurityController.setUpPatientPublicKey(patientUid);
      retrieveHumorLogs().then(() => {
        this.setState({ loading: false });
      });
    }
  }

  getErrorBoundaryRef = (ref: React.ElementRef<*>) => {
    this.errorBoundary = ref;
  };

  errorBoundary: ?React.ElementRef<*>;

  render() {
    const { loading } = this.state;
    const { humorLogs, isHistoric, oldHumor } = this.props;
    return (
      <ErrorBoundary
        warningMessage=" Usuário não possui registros ainda"
        ref={this.getErrorBoundaryRef}
      >
        <HumorStateless
          isLoading={isHistoric ? false : loading}
          humorLogs={isHistoric ? oldHumor : humorLogs}
        />
      </ErrorBoundary>
    );
  }
}

const mapStateToProps = (state: Store) => {
  const {
    patient: { humorLogs, profile },
    oldPatient: {
      data: { humor }
    }
  } = state;
  // Agora tambem olha o patientUid para poder setar a virgil key do paciente e decriptar os humorLogs
  return { humorLogs, patientUid: profile && profile.uid, oldHumor: humor };
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: Object) => {
  const { match } = ownProps;
  const patientId: string = match.params.id || '';

  return {
    retrieveHumorLogs: () => dispatch(PatientController.fetchAndSaveHumorLogs(patientId))
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(HumorMonitoring)
);
