// @flow
import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import type { Match } from 'react-router-dom';
import { configureScope, captureException } from '@sentry/browser';

import { PatientController, SecurityController } from '../../../../controllers';
import { ErrorBoundary } from '../../../reusable';
import HeaderLeftStateless from './HeaderLeft.stateless';

type Props = {
  profile: ?PatientProfile,
  fetchAndSaveProfile: void => PromiseAction,
  match: Match
};

type OwnProps = {
  match: Match
};

type State = {
  isLoading: boolean
};

class HeaderLeft extends React.Component<Props, State> {
  state = { isLoading: true };

  componentDidMount() {
    const {
      profile,
      match: {
        params: { id: idFromUrl }
      }
    } = this.props;

    const profileId = profile && profile.id;
    if (!profileId || idFromUrl !== profileId) {
      this.fetchProfileData();
    } else {
      this.setState({ isLoading: false });
    }
  }

  getErrorBoundaryRef = (ref: React.ElementRef<*>) => {
    this.errorBoundary = ref;
  };

  fetchProfileData = async () => {
    const { fetchAndSaveProfile, profile, therapistName } = this.props;

    // DEPOIS DE PEGAR O PERFIL EU VOU PEGAR O UID RETORNADO
    // E SETAR O PUBLIC DO PACIENTE
    const profileName = profile && profile.name;
    fetchAndSaveProfile()
      .then(async patientUid => {
        // CONFIGURANDO O SCOPE PARA TER MAIS INFOS NO ERRO
        configureScope(scope => {
          scope.setExtras({ patientName: profileName });
          scope.setTag('patientName', profileName);
          scope.setTag('therapistName', therapistName);
        });
        await SecurityController.setUpPatientPublicKey(patientUid);
        // const userUid = await SecurityController.getTherapistPublicKey();
      })
      .catch(error => {
        captureException(error);
        if (this.errorBoundary) {
          this.errorBoundary.forceError();
        }
      })
      .finally(() => this.setState({ isLoading: false }));
  };

  errorBoundary: ?React$ElementRef<*>;

  render() {
    const { profile, oldPatient, isHistory } = this.props;
    const { isLoading } = this.state;

    return (
      <ErrorBoundary ref={this.getErrorBoundaryRef}>
        <HeaderLeftStateless
          profile={profile}
          oldPatient={oldPatient}
          isHistory={isHistory}
          isLoading={isLoading}
        />
      </ErrorBoundary>
    );
  }
}

const mapStateToProps = (state: Store) => {
  const {
    patient: { profile },
    user: { profile: therapistProfile },
    oldPatient: { data: oldPatient }
  } = state;
  return { profile, therapistName: therapistProfile && therapistProfile.name, oldPatient };
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: OwnProps) => {
  const { match } = ownProps;
  const patientId: string = match.params.id || '';

  return {
    fetchAndSaveProfile: () => PatientController.fetchAndSaveProfile(patientId)(dispatch)
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(HeaderLeft)
);
