import styled, { css } from 'styled-components';

export const StyledTab = styled.div`
  display: flex;
  padding: 0px 15px;
  justify-content: space-around;
  flex-direction: column;
  width: 100%;
`;

export const StyledHeader = styled.div`
  display: flex;
  padding: 0px 0px;
  justify-content: space-around;
  flex-direction: row;
`;

export const StyledOption = styled.div`
  width: 100%;
  padding: 10px 15px;
  text-align: center;
  background-color: #c4c4c4;
  color: black;
  cursor: pointer;

  ${props =>
    props.selected &&
    css`
      background-color: #2b9bea;
      color: white;
    `}
`;
