// @flow
import { PatientTypes } from '../types';

export const saveProfile = (profile: PatientProfile): Action => ({
  type: PatientTypes.SAVE_PATIENT_PROFILE,
  payload: profile
});

export const saveStats = (stats: PatientStatistics): Action => ({
  type: PatientTypes.SAVE_PATIENT_STATS,
  payload: stats
});

export const saveWeights = (weights: Array<PatientWeight>): Action => ({
  type: PatientTypes.SAVE_PATIENT_WEIGHTS,
  payload: weights
});

export const saveWorkouts = (workouts: Array<PatientWorkout>): Action => ({
  type: PatientTypes.SAVE_PATIENT_WORKOUTS,
  payload: workouts
});

export const saveMeals = (meals: PatientMeals): Action => ({
  type: PatientTypes.SAVE_PATIENT_MEALS,
  payload: meals
});

export const saveNotes = (notes: PatientAnnotations): Action => ({
  type: PatientTypes.SAVE_PATIENT_NOTES,
  payload: notes
});

export const editNote = (id: number, text: string): Action => ({
  type: PatientTypes.EDIT_PATIENT_NOTE,
  payload: { id, text }
});

export const deleteNote = (id: number): Action => ({
  type: PatientTypes.DELETE_PATIENT_NOTE,
  payload: id
});

export const createNote = (id: number, note: PatientAnnotation): Action => ({
  type: PatientTypes.CREATE_PATIENT_NOTE,
  payload: { id, note }
});

export const saveGoals = (goals: PatientGoals): Action => ({
  type: PatientTypes.SAVE_PATIENT_GOALS,
  payload: goals
});

export const createGoal = (id: number, goal: PatientGoal): Action => ({
  type: PatientTypes.CREATE_PATIENT_GOAL,
  payload: { id, goal }
});

export const updateGoal = (id: number, goal: PatientGoal): Action => ({
  type: PatientTypes.UPDATE_PATIENT_GOAL,
  payload: { id, goal }
});

export const deleteGoal = (id: number): Action => ({
  type: PatientTypes.DELETE_PATIENT_GOAL,
  payload: id
});

export const editDescription = (description: string): Action => ({
  type: PatientTypes.EDIT_PATIENT_DESCRIPTION,
  payload: { text: description }
});

export const saveDescription = (description: string): Action => ({
  type: PatientTypes.SAVE_PATIENT_DESCRIPTION,
  payload: { text: description }
});

export const createAppointment = (appointment: PatientAppointment): Action => ({
  type: PatientTypes.CREATE_APPOINTMENT,
  payload: appointment
});

export const saveAppointments = (appointments: Array<PatientAppointment>): Action => ({
  type: PatientTypes.SAVE_PATIENT_APPOINTMENTS,
  payload: appointments
});

export const saveOldAppointments = (appointments: Array<PatientAppointment>): Action => ({
  type: PatientTypes.SAVE_OLD_PATIENT_APPOINTMENTS,
  payload: appointments
});

export const updateAppointment = (appointment: PatientAppointment): Action => ({
  type: PatientTypes.UPDATE_PATIENT_APPOINTMENT,
  payload: appointment
});

export const saveHumorLogs = (humorLogs: Array<PatientHumor>): Action => ({
  type: PatientTypes.SAVE_HUMOR_LOGS,
  payload: humorLogs
});

export const updateAfterChangeStatus = (nextAppointments, pastAppointments) => ({
  type: PatientTypes.UPDATE_AFTER_CHANGE_STATUS,
  payload: [nextAppointments, pastAppointments]
});
