// @flow
import { PatientTypes } from '../../types';

export default (state: Array<PatientHumor> = [], action: Action): Array<PatientHumor> => {
  const { type, payload } = action;

  switch (type) {
    case PatientTypes.SAVE_HUMOR_LOGS:
      return [...payload];
    default:
      return state;
  }
};
