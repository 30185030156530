// @flow

import { HOWMANY_SUBSCRIPTIONS } from '../utils/consts';
import { fetchServerData, fetchServerPdfData } from './utils';

export const fetchAccountInfo = (therapistId: number): Promise<any> => {
  const path: Array<string> = ['payments', 'accounts', 'therapist', therapistId];
  return fetchServerData(path);
};

export const transferBalance = (therapistId: number, ammount: number): Promise<any> => {
  const path: Array<string> = ['payments', 'request-withdraw', 'therapist', therapistId];
  return fetchServerData(path, ammount, 'POST');
};

export const fetchAppointmentsSubscriptions = (
  therapistId: number,
  initialDate: string,
  finalDate: string,
  page: number
): Promise => {
  const path: Array<string> = [
    'payments',
    'subscriptions',
    'therapist',
    therapistId,
    initialDate,
    finalDate,
    `?page=${page}&per_page=${HOWMANY_SUBSCRIPTIONS}`
  ];
  return fetchServerData(path);
};

export const fetchCoparticipationInvoices = (
  therapistId: number,
  initialDate: string,
  finalDate: string
): Promise => {
  const path: Array<string> = [
    'payments',
    'coparticipation-invoices',
    'therapist',
    therapistId,
    initialDate,
    finalDate
  ];
  return fetchServerData(path);
};

export const fetchAppointmentsInfo = (
  therapistId: number,
  initialDate: string,
  finalDate: string
): Promise => {
  const path: Array<string> = [
    'payments',
    'invoices',
    'therapist',
    therapistId,
    initialDate,
    finalDate
  ];
  return fetchServerData(path);
};

export const fetchFinancialInfo = (initialDate, finalDate) => {
  const path = ['payments', 'request-financial', initialDate, finalDate];
  return fetchServerData(path);
};

export const retrieveTherapistExtract = () => {
  const path = ['payments', 'therapist', 'extract', 'to-receive'];
  return fetchServerData(path);
};

export const generateTherapistMonthlyReport = (month, year, therapistId) => {
  return fetchServerPdfData([
    'payments',
    'therapist-monthly-report',
    'download',
    'pdf',
    month,
    year,
    therapistId
  ]);
};
