// @flow
import { AppointmentTypes } from '../types';

export const saveUpcomingAppointments = (appointments: Array<PatientAppointment>): Action => ({
  type: AppointmentTypes.SAVE_UPCOMING_APPOINTMENTS,
  payload: appointments
});

export const clearUpcomingAppointments = (): Action => ({
  type: AppointmentTypes.CLEAR_UPCOMING_APPOINTMENTS,
  payload: []
});
