import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { PaymentController } from '../../controllers';
import { Spinner, Table, Grid, TextInput, Datepicker, Button } from '../reusable';
import { initialDate, lastDate, paymentTableHeader } from '../../utils/Utils';
import { StyledPayments, StyledPaymentsTable } from './Styles';
import { RouterActions } from '../../redux/actions';
import { search } from '../../assets/images/images';
import { Colors } from '../../constants/Colors';

const Wallet = props => {
  const { saveHistoryOnRedux, setCurrentNav, history } = props;
  const { profile, invoices, getAccountInfo, getInvoices, filterByText, filterByDate } = props;

  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [isFetching, setFetching] = useState(true);
  const [isPageLoading, setIsPageLoading] = useState(false);

  const fetching = async () => {
    try {
      setFetching(true);

      saveHistoryOnRedux(history);
      setCurrentNav('Recebimentos');

      getAccountInfo(profile.id);

      await getInvoices(profile.id, initialDate, lastDate);

      setFetching(false);
    } catch (err) {}
  };

  const handleChangeDate = (firstDate, secondDate) => {
    const secondMoment = moment(secondDate);
    const firstMoment = moment(firstDate);
    let intervalAllowed =
      secondMoment.year() === firstMoment.year() && secondMoment.month() === firstMoment.month();
    const dateDiff = secondMoment.diff(firstMoment, 'days');
    if (!intervalAllowed || dateDiff > 31 || dateDiff < 1) {
      if (firstDate !== fromDate) {
        // modificou a data inicial
        secondDate = firstMoment.endOf('month').format('YYYY-MM-DD');
      } else {
        // modificou a data final
        firstDate = secondMoment.startOf('month').format('YYYY-MM-DD');
      }
    }

    setFromDate(firstDate);
    setToDate(secondDate);
    // filterByDate(firstDate, secondDate);
    return { fromDate: firstDate, toDate: secondDate };
  };

  const fetchAndFilter = async (firstDate, secondDate) => {
    // TODO: finalizar o limite do range da busca
    setIsPageLoading(true);
    try {
      saveHistoryOnRedux(history);
      getAccountInfo(profile.id);

      await getInvoices(profile.id, firstDate, secondDate);
      filterByDate(firstDate, secondDate);
    } catch (err) {}
    setIsPageLoading(false);
  };

  useEffect(() => {
    fetching();
  }, []);

  return !isFetching ? (
    <StyledPayments>
      <form
        onSubmit={e => {
          e.preventDefault();
          fetchAndFilter(fromDate, toDate);
        }}
      >
        <Grid rows={1} columns={4}>
          <TextInput
            icon={search}
            placeholder={'Buscar'}
            size={'sm'}
            onChange={filterByText}
            onFocus={() => getInvoices(profile.id, initialDate, lastDate)}
          />
          <Datepicker onChange={handleChangeDate} />
          <Button
            backgroundColor={Colors.blue}
            color={Colors.fourth}
            type="submit"
            title={`Buscar`}
            style={{ padding: '8px', marginTop: 10, height: 47 }}
            func={() => fetchAndFilter(fromDate, toDate)}
          ></Button>
          {/* <Button title={'Gerar relatório financeiro do mês'} backgroundColor={Colors.primary} /> */}
        </Grid>
      </form>

      {isPageLoading ? (
        <Spinner />
      ) : (
        <StyledPaymentsTable>
          <Table headers={paymentTableHeader} data={invoices} />
        </StyledPaymentsTable>
      )}
    </StyledPayments>
  ) : (
    <Spinner />
  );
};

const mapStateToProps = state => {
  const {
    user: { profile },
    payment: {
      invoicesInfo: { invoices }
    }
  } = state;

  // const profile = { id: 181 };

  return { profile, invoices };
};

const mapDispatchToProps = dispatch => {
  return {
    saveHistoryOnRedux: history => dispatch(RouterActions.saveHistory(history)),
    setCurrentNav: current => dispatch(RouterActions.setCurrentRoute(current)),
    getAccountInfo: therapistId => dispatch(PaymentController.fetchAndSaveAccountInfo(therapistId)),
    getInvoices: (therapistId, initialDate, finalDate) =>
      PaymentController.fetchAndSaveAppointmentsInfo(therapistId, initialDate, finalDate)(dispatch),
    filterByText: text => dispatch(PaymentController.filterPaymentsByText(text)),
    filterByDate: (first, second) => dispatch(PaymentController.filterPaymentsByDate(first, second))
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Wallet));
