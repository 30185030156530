// @flow
import { AuthTypes } from '../types';

/* SERVER AUTH ACTION CREATORS */
export const setLoginServerSuccess = () => ({
  type: AuthTypes.LOGIN_SERVER_SUCCESS,
  payload: null
});

export const setLoginServerFailed = (errorMessage: string) => ({
  type: AuthTypes.LOGIN_SERVER_FAILED,
  payload: { errorMessage }
});

export const setLogoutServerSuccess = () => ({
  type: AuthTypes.LOGOUT_SERVER_SUCCESS,
  payload: null
});

/* FIREBASE AUTH ACTION CREATORS */
export const setLoginFirebaseSuccess = (uid: string) => ({
  type: AuthTypes.LOGIN_FIREBASE_SUCCESS,
  payload: { uid }
});

export const setLoginFirebaseFailed = () => ({
  type: AuthTypes.LOGIN_FIREBASE_FAILED,
  payload: null
});

export const setLogoutFirebaseSuccess = () => ({
  type: AuthTypes.LOGOUT_FIREBASE_SUCCESS,
  payload: null
});

export const saveFirebaseAuthData = (firebaseAuth: FirebaseAuth) => ({
  type: AuthTypes.SAVE_FIREBASE_AUTH,
  payload: firebaseAuth
});

// SECURITY ACTIONS
export const setUpSecuritySuccess = () => ({
  type: AuthTypes.SECURITY_SETUP_SUCCESS,
  payload: null
});

export const setUpSecurityFailed = () => ({
  type: AuthTypes.SECURITY_SETUP_FAILED,
  payload: null
});
