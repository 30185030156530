// @flow
import * as React from 'react';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import type { Match } from 'react-router-dom';

import { PatientController } from '../../../controllers';
import { ErrorBoundary } from '../../reusable';
import PatientNotesStateless from './PatientNotes.stateless';
import styles from './PatientNotes.module.css';

type OwnProps = {
  match: Match
};

type Props = {
  notes: PatientAnnotations,
  fetchAndSaveNotes: () => PromiseAction,
  createNote: (note: { text: string, date: string }) => PromiseAction,
  match: Match,
  profileId: string
};

type State = {
  text: string,
  isLoading: boolean,
  isCreating: boolean
};

class PatientNotes extends React.Component<Props, State> {
  state = { isLoading: true, isCreating: false, text: '' };

  errorBoundary: { current: ?React$ElementRef<*> } = React.createRef();

  componentDidMount() {
    const {
      match: {
        params: { id: idFromUrl }
      },
      profileId
    } = this.props;

    if (!profileId || profileId !== idFromUrl) {
      this.fetchNotesData();
    } else {
      this.setState({ isLoading: false });
    }
  }

  fetchNotesData = () => {
    const { fetchAndSaveNotes } = this.props;

    fetchAndSaveNotes()
      .catch(() => {
        if (this.errorBoundary.current) {
          this.errorBoundary.current.forceError();
        }
      })
      .finally(() => this.setState({ isLoading: false }));
  };

  onTextChange = (event: SyntheticInputEvent<HTMLTextAreaElement>) => {
    this.setState({ text: event.target.value });
  };

  onSubmit = (event: SyntheticMouseEvent<HTMLButtonElement>) => {
    const { createNote } = this.props;
    const { text } = this.state;
    const note = {
      text,
      date: moment().format('YYYY-MM-DD HH:mm')
    };

    this.setState({ isCreating: true }, () =>
      createNote(note)
        .then(() => this.setState({ text: '' }))
        .catch(() => {
          if (this.errorBoundary.current) {
            this.errorBoundary.current.forceError();
          }
        })
        .finally(() => this.setState({ isCreating: false }))
    );

    event.preventDefault();
  };

  render() {
    const { notes, isHistoric, oldNotes } = this.props;
    const { isLoading, isCreating, text } = this.state;

    return (
      <div className={styles.container}>
        <ErrorBoundary ref={this.errorBoundary}>
          <PatientNotesStateless
            notes={isHistoric ? oldNotes : notes}
            text={text}
            onTextChange={this.onTextChange}
            onSubmit={this.onSubmit}
            isLoading={isLoading}
            isCreating={isCreating}
            isHistoric={isHistoric}
          />
        </ErrorBoundary>
      </div>
    );
  }
}

const mapStateToProps = (state: Store) => {
  const {
    patient: { notes, profile }
  } = state;

  return { notes, profileId: profile && profile.id };
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: OwnProps) => {
  const { match } = ownProps;
  const patientId: string = match.params.id || '';

  return {
    fetchAndSaveNotes: () => PatientController.fetchAndSaveNotes(patientId)(dispatch),
    createNote: note => PatientController.createNote(patientId, note)(dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PatientNotes));
