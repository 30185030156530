// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import { ChatController } from '../../../../controllers';
import BoxInputStateless from './BoxInput.stateless';

//must implement send Image
const ChatInputArea = props => {
  const { sendText } = props;

  const onSubmitInput = data => {
    if (data) sendText(data);
  };

  return <BoxInputStateless onSubmit={onSubmitInput} />;
};

const mapDispatchToProps = dispatch => ({
  sendText: message => dispatch(ChatController.sendText(message))
});

export default connect(null, mapDispatchToProps)(ChatInputArea);
