// @flow
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ChatList from '../list';
import { StyledContentNavigation, StyledContentContainer } from '../ChatStyles';

const ContentNavigation = props => {
  const { chatInfo } = props;

  //Implement has calendar logic
  // const hasCalendar = profile && profile.hasCalendarAssociated;
  const isOnMobile = window.innerWidth < 600;
  const selectedChatAndIsOnMobile = isOnMobile && chatInfo;

  return (
    !selectedChatAndIsOnMobile && (
      <StyledContentNavigation>
        <StyledContentContainer>
          <ChatList />
        </StyledContentContainer>
      </StyledContentNavigation>
    )
  );
};

const mapStateToProps = state => {
  const {
    chat: { currentChat }
  } = state;

  return { chatInfo: currentChat && currentChat.info };
};

export default withRouter(connect(mapStateToProps, null)(ContentNavigation));
