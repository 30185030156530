import { RouterTypes } from '../../types';

const initialState = {
  history: null,
  currentRoute: 'Mensagens'
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case RouterTypes.SAVE_HISTORY:
      return { ...state, history: payload };
    case RouterTypes.SET_CURRENT_ROUTE:
      return { ...state, currentRoute: payload };
    default:
      return state;
  }
};
