// @flow
export const SAVE_THERAPIST_ACCOUNT_INFO: string = 'SAVE_THERAPIST_ACCOUNT_INFO';
export const CREATE_THERAPIST_TRANSFER_BALANCE: string = 'CREATE_THERAPIST_TRANSFER_BALANCE';
export const SAVE_THERAPIST_APPOITMENTS_INFO: string = 'SAVE_THERAPIST_APPOITMENTS_INFO';
export const SAVE_THERAPIST_COPARTICIPATION_INFO: string = 'SAVE_THERAPIST_COPARTICIPATION_INFO';
export const SAVE_CURRENT_MONTH: string = 'SAVE_CURRENT_MONTH';
export const SAVE_THERAPIST_AMOUNT: string = 'SAVE_THERAPIST_AMOUNT';
export const CLEAN_THERAPIST_AMOUNT: string = 'CLEAN_THERAPIST_AMOUNT';

//Subscriptions
export const SAVE_THERAPIST_SUBSCRIPTIONS: string = 'SAVE_THERAPIST_SUBSCRIPTIONS';
export const SAVE_SUBSCRIPTIONS_AMOUNT: string = 'SAVE_SUBSCRIPTIONS_AMOUNT';

//Invoices
export const SAVE_INVOICES_AMOUNT: string = 'SAVE_INVOICES_AMOUNT';

//Financial
export const SAVE_THERAPIST_FINANCIAL: string = 'SAVE_THERAPIST_FINANCIAL';
export const SAVE_THERAPIST_WITHDRAW_HISTORIC: string = 'SAVE_THERAPIST_WITHDRAW_HISTORIC';
export const SAVE_CURRENT_BALANCE: string = 'SAVE_CURRENT_BALANCE';
export const SAVE_CURRENT_BALANCE_IN_TRANSACTION: string = 'SAVE_CURRENT_BALANCE_IN_TRANSACTION';
export const SAVE_CAN_TRANSFER: string = 'SAVE_CAN_TRANSFER';
export const SAVE_CURRENT_BALANCE_TO_RECEIVE: string = 'SAVE_CURRENT_BALANCE_TO_RECEIVE';
export const SAVE_CURRENT_MONTH_RECEIVES: string = 'SAVE_CURRENT_MONTH_RECEIVES';
export const SAVE_PAST_MONTH_RECEIVES: string = 'SAVE_PAST_MONTH_RECEIVES';
export const SAVE_THERAPIST_EXTRACT = 'SAVE_THERAPIST_EXTRACT';
