// @flow
import { fetchServerData } from './utils';

export const logIn = (credentials: ServerCredentials, username: string): Promise<UserProfile> => {
  const path: Array<string> = ['auth', 'therapist-login'];
  return fetchServerData(path, { ...credentials, username }, 'POST');
};

export const logOut = (): Promise<{}> => {
  const path: Array<string> = ['auth', 'therapist-logout'];
  return fetchServerData(path, {}, 'POST');
};

export const isAuthenticated = (): Promise<UserProfile> => {
  const path: Array<string> = ['auth', 'therapist-profile'];
  return fetchServerData(path, {}, 'GET');
};

export const getVirgilJwt = (uid): Promise => {
  const path: Array<string> = ['auth', 'get-virgil-jwt'];
  return fetchServerData(path, { uid }, 'POST');
};

export const checkUpdatedDevice = (): Promise => {
  const path: Array<string> = ['auth', 'update-device'];
  return fetchServerData(path, { }, 'PUT');
};

