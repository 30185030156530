import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  width: ${props => props.size};
  height: ${props => props.size};
  margin: 30px auto;
  border-radius: 100%;
  border: 1px solid ${props => props.bgColor};
  border-top-color: ${props => props.fgColor};
  animation: ${rotate} 1s linear infinite;
`;

Spinner.defaultProps = {
  size: '4em',
  fgColor: '#eb5c74',
  bgColor: '#ccc'
};

export default Spinner;
