// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import { configureScope } from '@sentry/browser';
import { ChatController, SecurityController } from '../../../../controllers';
import BoxTalkingStateless from './BoxTalking.stateless';

type OwnProps = {
  chatSize: number,
  chatId: string,
  patientUid: string
};

type Props = {
  fetchMessages: void => PromiseAction,
  fetchBatchedMessages: (chatSize: number) => Promise<boolean>
};

type State = {
  isFetching: boolean,
  isFetchingBatch: boolean,
  fetchedAllMessages: boolean
};

class BoxTalking extends React.Component<Props & OwnProps, State> {
  state = {
    isFetching: true,
    isFetchingBatch: false,
    fetchedAllMessages: false
  };

  componentDidMount() {
    // this.fetchInitialMessages();
    this.getUserPublicKeyAndFetchMessages();
  }

  componentDidUpdate(prevProps) {
    const { chatId } = this.props;

    if (prevProps.chatId !== chatId) {
      // this.fetchInitialMessages();
      this.getUserPublicKeyAndFetchMessages();
    }
  }

  onScroll = () => {
    const { fetchedAllMessages } = this.state;

    if (!fetchedAllMessages) {
      this.fetchPreviousMessages();
    }
  };

  fetchPreviousMessages = () => {
    const { fetchBatchedMessages, chatSize } = this.props;

    this.setState({ isFetchingBatch: true }, () =>
      fetchBatchedMessages(chatSize)
        .then(isFinished => this.setState(() => (isFinished ? { fetchedAllMessages: true } : null)))
        .finally(() => this.setState({ isFetchingBatch: false }))
    );
  };

  fetchInitialMessages = () => {
    const { fetchMessages } = this.props;
    this.setState({ isFetching: true, fetchedAllMessages: false }, () =>
      fetchMessages().finally(() => this.setState({ isFetching: false }))
    );
  };

  getUserPublicKeyAndFetchMessages = async () => {
    const { patientUid, patientName, therapistName } = this.props;
    // Quando selecionarmos um chat vamos pegar a public key do usuario e botar no Security
    // Pegando o uid do paciente do chat escolhido
    // Configurando o Sentry para ter mais info quando tiver erro
    configureScope(scope => {
      scope.setExtras({ patientName: patientName });
      scope.setTag('patientName', patientName);
      scope.setTag('therapistName', therapistName);
    });
    await SecurityController.setUpPatientPublicKey(patientUid);
    // const userUid = await SecurityController.getTherapistPublicKey();

    this.fetchInitialMessages();
  };

  render() {
    const { isFetchingBatch, isFetching } = this.state;

    return (
      <BoxTalkingStateless
        isFetching={isFetching}
        isFetchingPrevious={isFetchingBatch}
        onScroll={this.onScroll}
      />
    );
  }
}

const mapStateToProps = (state: Store) => {
  const {
    chat: { currentChat },
    user: { profile }
  } = state;

  return {
    chatSize: Object.keys(currentChat.messages).length,
    chatId: currentChat.info && currentChat.info.id,
    patientUid: currentChat.info && currentChat.info.uid,
    patientName: currentChat.info && currentChat.info.patientName,
    therapistName: profile && profile.name
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchMessages: () => dispatch(ChatController.fetchMessages()),
  fetchBatchedMessages: (chatSize: number) =>
    dispatch(ChatController.fetchBatchedMessages(chatSize))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BoxTalking);
