// @flow
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AuthController, ChatController } from '../../controllers';
import { Spinner, CustomModal } from '../reusable';
import ChatBox from './box';
import ContentNavigation from './contentNavigation/ContentNavigation';
import styles from './Chat.module.css';
import { VideoServer } from '../../server';
import VideoFeedback from '../video/feedback-video/VideoFeedback';
import { RouterActions } from '../../redux/actions';
import { StyledChatContainer } from './ChatStyles';

const Chat = props => {
  const {
    hasUsers,
    profile,
    isChatOpen,
    fetchChatsInfo,
    history,
    automaticallyUpdateAppointmentsStatus,
    saveHistoryOnRedux
  } = props;
  const isOnMobile = window.innerWidth < 600;

  const fromVideo = history.location?.state?.route && localStorage.getItem('feedback') > 180;

  const [isFetching, setFetching] = useState(true);
  const [feedbackModal, setFeedbackModal] = useState(fromVideo ? true : false);

  const clearVideoToken = (therapist, patient) => VideoServer.deleteTokens(therapist, patient);
  const fetchChatsData = () => fetchChatsInfo().finally(() => setFetching(false));
  const callFeedbackModal = () => <VideoFeedback setModal={setFeedbackModal} />;

  useEffect(() => {
    const therapist = localStorage.getItem('therapist');
    const patient = localStorage.getItem('patient');
    automaticallyUpdateAppointmentsStatus();
    fetchChatsData();
    localStorage.setItem('isHistory', false);

    if (therapist && patient) {
      clearVideoToken(therapist, patient);
      localStorage.removeItem('patient');
    }

    saveHistoryOnRedux(history);
  }, []);

  return !isFetching ? (
    <StyledChatContainer>
      {hasUsers ? (
        <React.Fragment>
          <ContentNavigation profile={profile} />
          {isOnMobile && isChatOpen ? <ChatBox /> : null}
          {!isOnMobile ? <ChatBox /> : null}

          <CustomModal
            modal={feedbackModal}
            setModal={setFeedbackModal}
            component={() => callFeedbackModal()}
          />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <ContentNavigation profile={profile} />
          <div className={styles.emptyUsersContainer}>
            <div className={styles.default}>
              <i className="fas fa-users" />
              <h2>Boas vindas à plataforma da Moodar!</h2>
              <strong>
                No momento você se encontra sem pacientes, no entanto, a medida que você for
                escolhidx eles aparecerão por aqui.
              </strong>
              <strong>
                {
                  'Para ficar disponível aos novos pacientes, associe o seu Calendar à nossa plataforma em "Sign in with Google".'
                }
              </strong>
              <strong>
                {
                  'Ao associá-lo, nós só teremos permissão para criar, atualizar e deletar eventos referentes às suas consultas na Moodar.'
                }
              </strong>
            </div>
          </div>
        </React.Fragment>
      )}
    </StyledChatContainer>
  ) : (
    <div className={styles.spinner}>
      <Spinner />
    </div>
  );
};

const mapStateToProps = (state: Store) => {
  const {
    chat: {
      chats,
      currentChat: { info }
    },
    user: { profile }
  } = state;

  return { hasUsers: !!Object.keys(chats).length, profile, isChatOpen: info };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchChatsInfo: () => dispatch(ChatController.fetchChatsInfo()),
  fetchOldChatsInfo: () => dispatch(ChatController.fetchOldChats()),
  automaticallyUpdateAppointmentsStatus: () =>
    AuthController.automaticallyUpdateAppointmentsStatus(),
  saveHistoryOnRedux: history => dispatch(RouterActions.saveHistory(history))
});

export default connect(mapStateToProps, mapDispatchToProps)(Chat);
