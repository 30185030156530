// @flow
import { ChatTypes } from "../../types";

const initialState: Chat = {
  messages: {},
  info: undefined
};

export default (state: Chat = initialState, action: Action): Chat => {
  const { type, payload } = action;

  switch (type) {
    case ChatTypes.SET_CURRENT_CHAT:
      return {
        ...state,
        info: payload
      };
    case ChatTypes.SET_MESSAGES:
      return {
        ...state,
        messages: payload
      };
    case ChatTypes.PUSH_MESSAGES:
      return {
        ...state,
        messages: {
          ...state.messages,
          ...payload
        }
      };
    case ChatTypes.PUSH_PREV_MESSAGES:
      return {
        ...state,
        messages: {
          ...payload,
          ...state.messages
        }
      };
    case ChatTypes.UPDATE_MESSAGE:
      return {
        ...state,
        messages: {
          ...state.messages,
          [payload.id]: {
            ...state.messages[payload.id],
            ...payload.message
          }
        }
      };
    default:
      return state;
  }
};
