// @flow
import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import type { Match } from 'react-router-dom';

import { PatientController } from '../../../controllers';
import { ErrorBoundary } from '../../reusable';
import PatientGoalsStateless from './PatientGoals.stateless';

type Props = {
  goals: PatientGoals,
  fetchAndSaveGoals: void => PromiseAction,
  match: Match,
  profileId: string
};

type OwnProps = {
  match: Match
};

type State = {
  isFetching: boolean,
  modalVisible: boolean
};

class PatientGoal extends React.Component<Props, State> {
  state = { isFetching: true, modalVisible: false };

  componentDidMount() {
    const {
      match: {
        params: { id: idFromUrl }
      },
      profileId
    } = this.props;

    if (!profileId || profileId !== idFromUrl) {
      this.fetchGoalsData();
    } else {
      this.setState({ isFetching: false });
    }
  }

  getErrorBoundaryRef = (ref: React.ElementRef<*>) => {
    this.errorBoundary = ref;
  };

  fetchGoalsData = () => {
    const { fetchAndSaveGoals } = this.props;
    fetchAndSaveGoals()
      .catch(() => {
        if (this.errorBoundary) this.errorBoundary.forceError();
      })
      .finally(() => this.setState({ isFetching: false }));
  };

  deletePatientGoal = (id: number) => {
    const { deleteGoal } = this.props;
    deleteGoal({ id });
  };

  toggleModal = () => {
    this.setState(prevState => ({ modalVisible: !prevState.modalVisible }));
  };

  onRequestOpenModal = (event: SyntheticMouseEvent<HTMLButtonElement>) => {
    this.toggleModal();
    event.preventDefault();
  };

  errorBoundary: ?React.ElementRef<*>;

  render() {
    const { goals, oldGoals, isHistory } = this.props;
    const { isFetching, modalVisible } = this.state;
    return (
      <ErrorBoundary ref={this.getErrorBoundaryRef}>
        <PatientGoalsStateless
          goals={isHistory ? oldGoals : goals}
          isFetching={isFetching}
          modalVisible={modalVisible}
          onRequestOpenModal={this.onRequestOpenModal}
          onRequestCloseModal={this.toggleModal}
          onDelete={this.deletePatientGoal}
          isHistory={isHistory}
        />
      </ErrorBoundary>
    );
  }
}

const mapStateToProps = (state: Store) => {
  const {
    patient: { goals, profile },
    oldPatient
  } = state;

  const oldGoals = oldPatient.data.goals;

  return { goals, profileId: profile && profile.id, oldGoals };
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: OwnProps) => {
  const { match } = ownProps;
  const patientId: string = match.params.id || '';

  return {
    fetchAndSaveGoals: () => PatientController.fetchAndSaveGoals(patientId)(dispatch),
    deleteGoal: (goal: Object) => PatientController.deleteGoal(patientId, goal)(dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PatientGoal));
