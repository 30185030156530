import moment from 'moment';
import { Colors } from '../constants/Colors';
import { PaymentController } from '../controllers';

//Previous Month Payments Stuff
export const TwoMonthsBeforeInitialDate = moment()
  .startOf('month')
  .subtract(2, 'month')
  .format('YYYY-MM-DD');

export const TwoMonthsBeforeLastDate = moment()
  .startOf('month')
  .subtract(2, 'month')
  .format('YYYY-MM-DD');

export const lastMonthInitialDate = moment()
  .startOf('month')
  .subtract(1, 'month')
  .format('YYYY-MM-DD');

export const lastMonthLastDate = moment()
  .endOf('month')
  .subtract(1, 'month')
  .format('YYYY-MM-DD');

export const checkCreatedAtDate = item => item.created_at >= TwoMonthsBeforeInitialDate;

export const checkFinancialReturnDate = item =>
  item.financial_return_date >= lastMonthInitialDate &&
  item.financial_return_date <= lastMonthLastDate;

export const checkLastPaidDate = item =>
  item.created_at <= lastMonthLastDate &&
  (item.last_paid_at || item.paid_at) >= lastMonthInitialDate;

export const isFromPreviousMonth = item => checkLastPaidDate(item);

export const previousMonthTotalValueToReceive = object => {
  return object
    .filter(item => isFromPreviousMonth(item))
    .map(item => item.value_to_receive)
    .reduce((a, b) => a + b, 0);
};

export const initialDate = moment()
  .startOf('month')
  .format('YYYY-MM-DD');

export const lastDate = moment()
  .endOf('month')
  .format('YYYY-MM-DD');

export const isFromCurrentMonth = item =>
  item.financial_return_date >= initialDate && item.financial_return_date <= lastDate;

export const currentMonthTotalValueToReceive = object =>
  object
    .filter(item => isFromCurrentMonth(item))
    .map(item => item.value_to_receive)
    .reduce((a, b) => a + b, 0);

export const ParsedDate = (date, type) => {
  let dateObj;
  switch (type) {
    case 'appo':
      dateObj = new Date(date);

      return `${
        weekDays[dateObj.getDay() === 0 ? 6 : dateObj.getDay() - 1].name
      }, ${dateObj.getDate()} de ${
        monthNames[dateObj.getMonth()].bigName
      } de ${dateObj.getFullYear()} às ${
        dateObj.getHours() < 10 ? `0${dateObj.getHours()}` : dateObj.getHours()
      }:${
        dateObj.getMinutes() < 10 ? `0${dateObj.getMinutes()}` : dateObj.getMinutes()
      } - Horário de Brasília`;
    // Terça-feira, 13 de Setembro de 2022 às 06:20 - Horário de Brasília
    // return moment(date)
    //   .format('LLLL')
    //   .concat(' - Horário de Brasília');
    default:
      dateObj = new Date(date);

      return `${
        weekDays[dateObj.getDay() === 0 ? 6 : dateObj.getDay() - 1].name
      }, ${dateObj.getDate()} de ${
        monthNames[dateObj.getMonth()].bigName
      } de ${dateObj.getFullYear()} às ${dateObj.getHours()}:${
        dateObj.getMinutes() < 10 ? `0${dateObj.getMinutes()}` : dateObj.getMinutes()
      } - Horário de Brasília`;
  }
};

// export const getParsedDate = stringDate => {
//   return stringDate ? moment(stringDate).format('DD/MM/YY') : '----';
// };

export const getParsedDate = stringDate => {
  let parsedDate = '----';
  if (stringDate) {
    parsedDate = moment(stringDate);
    parsedDate = !parsedDate.isValid() ? stringDate : parsedDate.format('DD/MM/YY');
  }
  return parsedDate;
};

export const CalendarAppoDate = date => {
  const calendarDate = moment(date).calendar(); // Hoje às 21:30
  let dateString = ParsedDate(date, 'appo');
  if (calendarDate.includes('Hoje') || calendarDate.includes('Amanhã')) {
    const day = calendarDate.split(' ')[0];
    dateString = `${day}, ${ParsedDate(date, 'appo')}`;
  }
  return dateString;
};

export const ContactListDate = date => {
  return date;
};

export const getPaymentStatus = item => {
  if (item.status !== 'paid') {
    return 'Pendente';
  } else {
    const currentMonth = PaymentController.getCurrentMonth();
    let nextBillingMonth = +moment(item.next_billing_date || item.due_date)
      .format('DD/MM')
      .split('/')[1];

    return nextBillingMonth === currentMonth ? 'A pagar no vencimento' : 'Paga';
  }
};

export const getMonthAmount = (items, saveAmount) => {
  const itemsArray = Object.values(items);
  const monthsAmountObject = PaymentController.getAmountFromIncomes(itemsArray);
  const currentMonth = PaymentController.getCurrentMonth();
  saveAmount(monthsAmountObject);
  return [monthsAmountObject[currentMonth], monthsAmountObject[currentMonth + 1]];
};

export const checkBrowser = () => {
  const { opr } = window;
  const isOpera =
    (!!window.opr && opr && !!opr.addons) ||
    !!window.opera ||
    navigator.userAgent.indexOf(' OPR/') >= 0;
  // const isFirefox = typeof InstallTrigger !== 'undefined';
  // const isSafari =
  //   /constructor/i.test(window.HTMLElement) ||
  //   (function(p) {
  //     return p.toString() === '[object SafariRemoteNotification]';
  //   })(!window['safari']);
  const isIE = /*@cc_on!@*/ false || !!document.documentMode;
  const isEdge = !isIE && !!window.StyleMedia;
  // const isChrome = !!window.chrome && window.navigator.vendor.includes('Google');

  const blockedBrowsers = [isOpera, isEdge, isIE];
  if (
    blockedBrowsers.some(function(blocked) {
      return blocked === true;
    })
  ) {
    alert(
      'Seu navegador não é compatível com a chamada de vídeo\n\nPor favor, acesse a plataforma com alguns dos navegadores abaixo:\n\n- Google Chrome\n- Mozilla Firefox\n- Safari'
    );
  }
};

export const sortByPaymentStatus = (a, b) => {
  if (a.status === 'Paga' && b.status !== 'Paga') return -1;
  // if (a.status === 'A pagar no mês' && b.status === 'Pagamento atrasado') return -1;
  return 0;
};

export const sortByName = (a, b) => (a.name > b.name ? 1 : -1);

export const sortByDueDate = (a, b) => (moment(a.due_date).diff(moment(b.due_date)) < 0 ? -1 : 1);

export const monthNames = [
  { value: 1, name: 'Jan', bigName: 'Janeiro' },
  { value: 2, name: 'Fev', bigName: 'Fevereiro' },
  { value: 3, name: 'Mar', bigName: 'Março' },
  { value: 4, name: 'Abr', bigName: 'Abril' },
  { value: 5, name: 'Mai', bigName: 'Maio' },
  { value: 6, name: 'Jun', bigName: 'Junho' },
  { value: 7, name: 'Jul', bigName: 'Julho' },
  { value: 8, name: 'Ago', bigName: 'Agosto' },
  { value: 9, name: 'Set', bigName: 'Setembro' },
  { value: 10, name: 'Out', bigName: 'Outubro' },
  { value: 11, name: 'Nov', bigName: 'Novembro' },
  { value: 12, name: 'Dez', bigName: 'Dezembro' }
];

export const weekDays = [
  { value: 0, name: 'Segunda-feira' },
  { value: 1, name: 'Terça-feira' },
  { value: 2, name: 'Quarta-feira' },
  { value: 3, name: 'Quinta-feira' },
  { value: 4, name: 'Sexta-feira' },
  { value: 5, name: 'Sábado' },
  { value: 6, name: 'Domingo' }
];

// export const hours = [
//   00:00:00,
//   00:20:00,
//   00:40:00,
//   01:00:00,
//   01:20:00,
//   01:40:00,
//   02:00:00,
//   02:20:00,
//   02:40:00,
//   03:00:00,
//   03:20:00,
//   03:40:00,
//   04:00:00,
//   04:20:00,
//   04:40:00,
//   05:00:00,
//   05:20:00,
//   05:40:00,
//   06:00:00,
//   06:20:00,
//   06:40:00,
//   07:00:00,
//   07:20:00,
//   07:40:00,
//   08:00:00,
//   08:20:00,
//   08:40:00,
//   09:00:00,
//   09:20:00,
//   09:40:00,
//   10:00:00,
//   10:20:00,
//   10:40:00,
//   11:00:00,
//   11:20:00,
//   11:40:00,
//   12:00:00,
//   12:20:00,
//   12:40:00,
//   13:00:00,
//   13:20:00,
//   13:40:00,
//   14:00:00,
//   14:20:00,
//   14:40:00,
//   15:00:00,
//   15:20:00,
//   15:40:00,
//   16:00:00,
//   16:20:00,
//   16:40:00,
//   17:00:00,
//   17:20:00,
//   17:40:00,
//   18:00:00,
//   18:20:00,
//   18:40:00,
//   19:00:00,
//   19:20:00,
//   19:40:00,
//   20:00:00,
//   20:20:00,
//   20:40:00,
//   21:00:00,
//   21:20:00,
//   21:40:00,
//   22:00:00,
//   22:20:00,
//   22:40:00,
//   23:00:00,
//   23:20:00,
//   23:40:00,
// ];

export const getPlanDescription = (plan, planNameId) => {
  console.log(plan, planNameId);
  const plan40 = ['completo', 'quinzenal', 'avulsa_completa'];
  const freeAppointments = 'Agendar Sessão';

  const companyName = planNameId?.split('_').slice(-1)[0];
  const company = companyName ? companyName.charAt(0).toUpperCase() + companyName.slice(1) : '';

  if (planNameId.includes('basico')) {
    return plan + ' - Ligação de 20 minutos';
  } else if (planNameId.includes('quinzenal')) {
    return plan + ' - Ligação de 40 minutos';
  } else if (planNameId.includes('completo_trinta')) {
    return plan + ' - Ligação de 30 minutos';
  } else if (plan40.some(complete => plan.includes(complete))) {
    return plan + ' - Ligação de 40 minutos';
  } else if (plan.includes(freeAppointments)) {
    return 'Consulta Avulsa ' + company + ' - Lançamento Gratuito - Ligação de 40 minutos';
  } else {
    return plan;
  }
};

export const convertStatus = status =>
  ({
    paid: 'Pago',
    suspended: 'Suspensa',
    pending: 'Pendente',
    expired: 'Expirada',
    accepted: 'Liberado',
    released: 'Liberado',
    refunded: 'Reembolsado'
  }[status]);

export const getStatusInfo = status =>
  ({
    Pago: 'A fatura foi paga',
    Recebida: 'O valor está liberado para saque',
    Processando: 'O valor está sendo liberado para a liberação do saque',
    Reembolsada: 'O valor pago foi reembolsado',
    Pendente: 'Aguardando pagamento',
    Expirada: 'data de pagamento expirou, \nsuporte entrará em contato',
    Cancelada: 'Tratamento foi suspendido',
    Suspensa: 'Tratamento foi suspendido',
    'Sem informação': 'Entre em contato com o suporte \npara saber mais informações',
    Liberado: 'O valor já foi transferido pra sua conta bancária',
    'Assinatura ativa': 'Este paciente está ativo na plataforma e com o pagamento em dia',
    'Consultas avulsas':
      'Este nunca teve um plano de consultas ativo, mas realiza consultas avulsas',
    'Assinatura suspensa': 'Este paciente suspendeu sua assinatura na plataforma',
    'Pagamento pendente':
      'Este paciente está ativo na plataforma, mas o pagamento da sua próxima fatura está pendente'
  }[status]);

export const getStatusByPlan = plan => {
  const planName = plan.toLowerCase();
  const recurrentPlans = [
    'quinzenal',
    'completa',
    'completo',
    '40min',
    '20min',
    'básico',
    'básica'
  ];
  const isAvulsa = planName.includes('avulsa');
  const isRecurrent = recurrentPlans.some(plan => planName.includes(plan));

  if (isAvulsa) return 'Consultas avulsas';
  if (isRecurrent) return 'Assinatura ativa';
};

export const getPatientStatus = (status, plan) =>
  ({
    Paga: getStatusByPlan(plan),
    Suspensa: 'Assinatura suspensa',
    'A pagar no mês': 'Pagamento pendente'
  }[status]);

export const dotColor = item =>
  ({
    Pago: Colors.green,
    'Sem informação': Colors.gray,
    Pendente: Colors.yellow,
    Suspensa: Colors.red
  }[item]);

export const isStatus = item =>
  [
    'Pago',
    'Sem informação',
    'Pendente',
    'Suspensa',
    'Expirada',
    'Liberado',
    'Assinatura ativa',
    'Assinatura suspensa',
    'Consultas avulsas',
    'Pagamento pendente'
  ].some(status => status.includes(item));

export const getParsedId = id => {
  if (id) {
    const currentId = id;
    const firstIdHalf = id?.slice(0, 4);
    const secondIdHalf = currentId.substring(currentId.length - 4, currentId.length);
    return `${firstIdHalf}-${secondIdHalf}`;
  }

  return ' - ';
};

export const getPreviousDate = () => moment().subtract(1, 'month');

export const getCurrentDate = () => moment();

export const getTransferPossiblity = withdrawDate => {
  const withdrawMonth = moment(withdrawDate).month() + 1;
  const currentMonth = getCurrentDate().month() + 1;
  return withdrawMonth !== currentMonth;
};

export const getActivePatients = items => {
  return items.filter(
    item =>
      item.current_month_payment_status === 'Paga' ||
      item.current_month_payment_status === 'A pagar no mês'
  ).length;
};

export const subscriptionTableHeaeder = [
  '#',
  'Paciente',
  'Plano',
  'Status',
  'Progresso do mês',
  'Disponível via',
  'Data de Renovação'
];

export const withdrawTableHeaeder = [
  'Código',
  'Data de criação',
  'Data de atualização',
  'Valor',
  'Status'
];

export const paymentTableHeader = [
  'Código da Fatura',
  'Nome do pagador',
  'Razão do Plano',
  'Valor da Fatura',
  'Data de Recebimento',
  'Status da Fatura'
];
