import React from 'react';
import { StyledCalendar, StyledCalendarFrame } from './Styles';

const GoogleCalendar = props => {
  return (
    <StyledCalendar>
      <StyledCalendarFrame
        src="https://calendar.google.com/calendar/u/0/embed"
        title="W3Schools Free Online Web Tutorials"
      />
    </StyledCalendar>
  );
};

export default GoogleCalendar;
