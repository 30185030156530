// @flow
import { captureException, captureMessage } from '@sentry/browser';
import Security from '../libs/security/Security';

export const setUpTherapistPublicKey = async (therapistUid: string) => {
  // const therapistPublicKey = await Security.lookupPublicKeys(therapistUid);
  // await Security.setUserPublicKey(therapistPublicKey);
  try {
    // Setando primeiro a patientUid pq dps se der merda ele ainda vai tentar pegar a publicKey de novo
    Security.setUids(null, therapistUid);

    // const therapistPublicKey = await Security.findUsers(therapistUid);
    const therapistPublicKey = await Security.findUsers(therapistUid);

    await Security.setUserPublicKey(therapistPublicKey);
  } catch (error) {
    captureException(error);
    // throw error;
  }
};

export const getTherapistPublicKey = async () => {
  const publicKey = await Security.getUserPublicKey();
  return publicKey;
};

export const getPatientPublicKey = () => {
  const publicKey = Security.getRecipientPublicKey();
  return publicKey;
};

export const setUpPatientPublicKey = async (patientUid: string) => {
  try {
    // Setando primeiro a patientUid pq dps se der merda ele ainda vai tentar pegar a publicKey de novo
    Security.setUids(patientUid, null);

    const patientPublicKey = await Security.findUsers(patientUid);
    await Security.setRecipientPublicKey(patientPublicKey);
  } catch (error) {
    captureException(error);
    // throw error;
  }
};

export const setUpSecurity = async (therapistUid: string) => {
  if (therapistUid) {
    try {
      await Security.initialize(therapistUid);
      await Security.setUids(null, therapistUid);
      await Security.restorePrivateKeyOrRegister(therapistUid);
      // await Security.backupUserPrivateKey(userUID);
      await setUpTherapistPublicKey(therapistUid);
    } catch (error) {
      captureException(error);
      throw error;
    }
  } else {
    captureMessage('Nao tem therapistUid no setUpSecurity');
    throw new Error();
  }
};

// We need a method to check because if publicKey is either null or {} it will cause errors
// Invalid format of data Error when decrypting from {} publick key encryption
// Foundation Error Recipient defined with id is not found within message info during data decryption
// when decrypting from null public key encryption
const isPublicKeyEmpty = (publicKey: Object) => {
  return !publicKey || Object.keys(publicKey).length === 0;
};

// Sets again the publicKey of either the therapist or patient if they had not been set
const setUpMissingPublicKey = async (userType: string) => {
  switch (userType) {
    case 'therapist':
      const therapistUid = Security.getTherapistUid();
      if (therapistUid) {
        await setUpTherapistPublicKey(therapistUid);
      }
      return;
    case 'patient':
      const userUid = Security.getUserUid();
      if (userUid) {
        await setUpPatientPublicKey(userUid);
      }
      return;
    default:
      return;
  }
};

const retrievePublicKey = (typeUser: string) => {
  return {
    patient: Security.getRecipientPublicKey(),
    therapist: Security.getUserPublicKey()
  }[typeUser];
};

const checkPublicKeyIsEmptyAndSetIfNeeded = async (userType: string) => {
  let publicKey = retrievePublicKey(userType);
  if (isPublicKeyEmpty(publicKey)) {
    await setUpMissingPublicKey(userType);

    // After setting it we retrieve the key again
    publicKey = retrievePublicKey(userType);
  }
  return publicKey;
};

/**
  Returns the publicKey of the user or therapist depending on encryptedByPatient
  It first checks whether the publicKey is not null.
  If it is it sets the public key again and returns it
 */
const getUserOrTherapistPublicKey = async (userType: string) => {
  let publicKey = await checkPublicKeyIsEmptyAndSetIfNeeded(userType);

  // We try twice to get the publicKey not empty
  if (isPublicKeyEmpty(publicKey)) {
    publicKey = await checkPublicKeyIsEmptyAndSetIfNeeded(userType);
  }

  return publicKey;
};

export const encryptText = async (textToEncrypt: string) => {
  // If recipientPublicKey is null or undefined we cant encrypt otherwise there will be an error
  // Thus we try to set it again to encryptText afterwards
  const recipientPublicKey = await getUserOrTherapistPublicKey('patient');
  // const recipientPublicKey = await Security.getRecipientPublicKey();

  // SE TIVER PUBLIC KEY DO PACIENTE ENTAO MANDA A MENSAGEM ENCRIPTADA
  // SE NAO TIVER NAO ENCRIPTA E TEM QUE SINALIZAR ISSO PARA O METODO QUE CHAMA PODER COLOCAR A TAG
  let encryptedText = textToEncrypt;
  let wasAbleToEncrypt = false;
  if (!isPublicKeyEmpty(recipientPublicKey)) {
    try {
      encryptedText = await Security.encryptText(textToEncrypt, recipientPublicKey);
      wasAbleToEncrypt = true;
    } catch (error) {
      captureException(error);
    }
  } else {
    captureMessage('User não tem chave ainda e não encriptou');
  }
  return [encryptedText, wasAbleToEncrypt];
};

export const decryptText = async (text: string, encryptedByPatient: boolean) => {
  // const publicKey = encryptedByPatient ? Security.getRecipientPublicKey() : Security.getUserPublicKey();
  // let decryptedText = null;
  // try {
  //   decryptedText = await Security.decryptText(text, publicKey);
  // } catch (error) {
  //   captureException(error);
  // }
  // return decryptedText;

  if (text) {
    const userKeyToRetrieve = encryptedByPatient ? 'patient' : 'therapist';
    const publicKey = await getUserOrTherapistPublicKey(userKeyToRetrieve);
    let decryptedText = null;

    // NAO SEI SE TENHO QUE CHECAR SE O publicKey EH EMPTY OU NAO PARA DECRIPTAR
    try {
      decryptedText = await Security.decryptText(text, publicKey);
    } catch (error) {
      captureException(error);
    }
    return decryptedText;
  }
  return '';
};
