// @flow
import * as Sentry from '@sentry/browser';
import moment from 'moment';
import { FirebaseMessage } from '../firebase';
import { ChatActions } from '../redux/actions';
import { ChatServer } from '../server';

const MESSAGES_BATCH_SIZE = 50;

// ==================== CONTROLLER API ====================

// Metodo para somente criar o badge se for para um chat que nao seja o currentChat
const verifyAndCreatedBadge = (chatId: string) => (dispatch: Dispatch, getState: GetState) => {
  const {
    chat: {
      currentChat: { info }
    }
  } = getState();
  const currentChatId = info && info.id;
  if (!(currentChatId === chatId)) {
    dispatch(ChatActions.setBadge(chatId));
  }
};

export const fetchChatsInfo = () => (dispatch: Dispatch, getState: GetState): Promise<any> => {
  const {
    user: { profile }
  } = getState();
  dispatch(ChatActions.clearChatsInfo());
  const id = profile.id ? profile.id : profile.coach_id;
  return ChatServer.retrieveChatsInfo(id)
    .then(chatsInfo => {
      const sortedChatsInfo = Object.values(chatsInfo)
        .sort((a, b) => {
          return moment(a?.lastTimeOnApp) > moment(b?.lastTimeOnApp);
        })
        .reverse();

      const objectToAssignChat = {};

      sortedChatsInfo.forEach(key => {
        let chatId = key?.id;
        Object.assign(objectToAssignChat, { [chatId]: key });
      });

      dispatch(ChatActions.saveChatsInfo(objectToAssignChat));
      FirebaseMessage.activeBadges(Object.keys(objectToAssignChat), chatId =>
        dispatch(verifyAndCreatedBadge(chatId))
      );
    })
    .catch(error => Sentry.captureException(error));
};

export const fetchOldChats = () => async (dispatch: Dispatch, getState: GetState): Promise<any> => {
  const {
    user: { profile }
  } = getState();
  dispatch(ChatActions.clearChatsInfo());
  const id = profile.id ? profile.id : profile.coach_id;
  const chats = await ChatServer.retrieveOldChatsInfo(id);
  dispatch(ChatActions.saveChatsInfo(chats));
};

export const selectCurrentChat = (chatId: string): ThunkAction => (
  dispatch: Dispatch,
  getState: GetState
): void => {
  try {
    const {
      chat: {
        chats: { [chatId]: chatInfo },
        currentChat: { info }
      }
    } = getState();

    if (info) {
      FirebaseMessage.clearMessageListener(info.id);
      FirebaseMessage.clearBadge(info.id);
      FirebaseMessage.activeBadge(info.id, () => dispatch(verifyAndCreatedBadge(info.id)));
    }

    FirebaseMessage.clearBadge(chatId);
    dispatch(ChatActions.clearBadge(chatId));

    dispatch(ChatActions.setCurrentChat({ ...chatInfo, id: chatId }));
  } catch (err) {
    Sentry.captureException(err);
  }
};

export const clearUnreadMessagesBadge = (chatId: string) => (dispatch: Dispatch) => {
  // FirebaseMessage.clearMessageListener(chatId);
  FirebaseMessage.clearBadge(chatId);
  dispatch(ChatActions.clearBadge(chatId));
};

export const fetchMessages = (): ThunkAction => (
  dispatch: Dispatch,
  getState: GetState
): Promise<any> => {
  try {
    const {
      chat: {
        currentChat: {
          info: { id, uid: patientUid }
        }
      }
    } = getState();

    return FirebaseMessage.fetchMessages(id, patientUid, MESSAGES_BATCH_SIZE).then(messages => {
      dispatch(ChatActions.setMessages(messages));
      FirebaseMessage.listenMessages(id, patientUid, msg =>
        dispatch(ChatActions.pushMessages(msg))
      );
    });
  } catch (err) {
    Sentry.captureException(err);
  }
};

export const sendText = (text: string): ThunkAction => (
  dispatch: Dispatch,
  getState: GetState
): void => {
  const {
    user: {
      profile: { uid: _id, name, id }
    },
    chat: {
      currentChat: {
        info: { id: chatId }
      }
    }
  } = getState();

  const msg = {
    _id: Date.now().toString(),
    createdAt: moment().format('YYYY-MM-DD HH:mm:ss'),
    text,
    user: { _id, name, serverId: id || null }
  };

  // VOU ENCRIPTAR QUANDO MANDAR PARA O FIREBASE MAS NAO COMO SALVA NO REDUX
  dispatch(ChatActions.pushNewMessage(msg));
  FirebaseMessage.sendText(chatId, msg);
};

export const sendImage = (file: Object): ThunkAction => (
  dispatch: Dispatch,
  getState: GetState
): void => {
  const {
    user: {
      profile: { uid: _id, name }
    },
    chat: {
      currentChat: {
        info: { id }
      }
    }
  } = getState();

  const img = new Image();

  img.onload = () => {
    const msg = {
      _id: Date.now().toString(),
      createdAt: moment().format('YYYY-MM-DD HH:mm:ss'),
      image: '',
      user: { _id, name },
      metadata: {
        aspectRatio: img.height / img.width,
        rotation: 0
      }
    };

    dispatch(ChatActions.pushNewMessage(msg));
    FirebaseMessage.sendImage(id, msg, file);
  };

  img.src = URL.createObjectURL(file);
};

export const fetchBatchedMessages = (chatSize: number): ThunkAction => (
  dispatch: Dispatch,
  getState: GetState
): Promise<boolean> => {
  const {
    chat: {
      currentChat: {
        info: { id, uid: patientUid }
      }
    }
  } = getState();

  return FirebaseMessage.fetchMessages(id, patientUid, MESSAGES_BATCH_SIZE, chatSize)
    .then(messages => {
      dispatch(ChatActions.pushPrevMessages(messages));
      return Object.keys(messages).length < MESSAGES_BATCH_SIZE;
    })
    .catch(err => Sentry.captureException(err));
};
