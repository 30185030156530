// @flow
import * as React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Spinner } from '../../../reusable';
import TalkingBubble from './bubble';
import DateTime from './datetime';
import styles from './BoxTalking.module.css';

type Props = {
  isFetching: boolean,
  isFetchingPrevious: boolean,
  onScroll: Function
};

type OwnProps = {
  messages: Messages,
  chatSize: number,
  userId: number
};

class BoxTalkingStateless extends React.Component<Props & OwnProps> {
  componentDidUpdate(prevProps: Props & OwnProps) {
    const { isFetching, chatSize } = this.props;

    if (prevProps.chatSize + 1 === chatSize || (prevProps.isFetching && !isFetching)) {
      this.scrollToBottom();
    }
  }

  scrollHandler = (event: SyntheticEvent<HTMLDivElement>) => {
    const { onScroll } = this.props;
    if (!event.currentTarget.scrollTop) {
      onScroll();
    }
  };

  getDateBetweenMessages = (currentMessage: Message, nextMessage: Message) => {
    if (nextMessage) {
      const format: string = 'MM/DD/YYYY';
      const currentDay: string = moment(currentMessage?.createdAt).format(format);
      const nextDay: string = moment(nextMessage?.createdAt).format(format);
      const diff: number = moment(nextDay).diff(moment(currentDay), 'days');

      if (diff > 0) {
        return moment(nextDay).format('ll');
      }
      return null;
    }
    return null;
  };

  renderTopSpinner = () => {
    const { isFetchingPrevious } = this.props;
    return (
      isFetchingPrevious && (
        <div className={styles.topSpinner}>
          <Spinner size="2em" />
        </div>
      )
    );
  };

  renderMessages = () => {
    const { messages, userId } = this.props;
    const iterable: Array<Message> = (Object.values(messages): any);

    return iterable.map((message: Message, index: number) => {
      // Agora lida com o caso de ele antes nao mandar msg do webapp com serverId
      // Se serverId for undefined ja cai no right, se nao for e forem diferentes entao a msg eh do paciente
      const side: string = message?.user?._id && message?.user?._id !== userId ? 'left' : 'right';
      // const side: string = message.user.serverId !== userId ? 'left' : 'right';
      const nextMessage: Message = iterable[index + 1];
      const dateMessage: ?string = this.getDateBetweenMessages(message, nextMessage);

      return message.user?._id ? (
        <React.Fragment key={message._id}>
          <div className={styles.message}>
            <TalkingBubble message={message} side={side} />
          </div>
          {dateMessage && <DateTime>{dateMessage}</DateTime>}
        </React.Fragment>
      ) : null;
    });
  };

  getScrollView = (ref: React.ElementRef<*>) => {
    this.scrollView = ref;
  };

  scrollToBottom = () => {
    if (this.scrollView) {
      this.scrollView.lastElementChild.scrollIntoView({ behaviour: 'smooth' });
    }
  };

  scrollView: ?React.ElementRef<*>;

  render() {
    const { isFetching, messages } = this.props;
    const hasMessages = Object.keys(messages).length > 0;

    return !isFetching ? (
      <div ref={this.getScrollView} className={styles.container} onScroll={this.scrollHandler}>
        {this.renderTopSpinner()}
        {hasMessages ? (
          this.renderMessages()
        ) : (
          <div className={styles.default}>
            <i className="far fa-comments" />
            <h2>Ainda não há mensagens entre vocês</h2>
            <strong>Envie uma mensagem agora e inicie uma conversa.</strong>
          </div>
        )}
      </div>
    ) : (
      <Spinner />
    );
  }
}

const mapStateToProps = (state: Store) => {
  const {
    chat: { currentChat },
    user: { profile }
  } = state;

  return {
    messages: currentChat.messages,
    chatSize: Object.keys(currentChat.messages).length,
    userId: profile.uid
  };
};

export default connect(mapStateToProps)(BoxTalkingStateless);
