import React from 'react';
import { Button } from '../../';
import { AppoCard, AppoDate, AppoStatus, AppoOptions } from './Styles';
import { ParsedDate } from '../../../../utils/Utils';

const getStatus = {
  confirmed: 'Confirmada',
  canceled: 'Cancelada',
  successful: 'Realizada'
};

const AppointmentsCard = props => {
  const { appointment, isHistoric, setCurrentAppo, setModal, setConfirmModal } = props;

  return (
    <AppoCard key={appointment.id}>
      <AppoDate>
        <AppoStatus>{getStatus[appointment.status]}</AppoStatus>
        {ParsedDate(appointment.schedule, 'appo')}
      </AppoDate>
      {!isHistoric ? (
        <AppoOptions>
          {appointment.status === 'confirmed' ? (
            <Button
              style={{ color: '#3cb2df' }}
              func={() => {
                setCurrentAppo(appointment);
                setConfirmModal(true);
              }}
              icon={'fas fa-check-circle'}
              title={'Marcar como realizada'}
            />
          ) : null}
          <Button
            style={{ color: '#31738c' }}
            func={() => {
              setCurrentAppo(appointment);
              setModal(true);
            }}
            icon={'fas fa-calendar'}
            title={'Remarcar consulta'}
          />
        </AppoOptions>
      ) : null}
    </AppoCard>
  );
};

export default AppointmentsCard;
