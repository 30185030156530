// @flow
import * as React from 'react';
import PatientHeader from './header';
import PatientMonitoring from './monitoring';
import PatientNotes from './notes';
// import PatientGoals from './goals';
import PatientAppointments from './appointments';
import styles from './Patient.module.css';

const isOnMobile = window.innerWidth < 600;

const Patient = () => (
  <div className={styles.container}>
    <PatientHeader />
    <PatientMonitoring />
    {isOnMobile ? <PatientAppointments /> : <PatientNotes />}
    {isOnMobile ? <PatientNotes /> : <PatientAppointments />}
  </div>
);

export default Patient;
