// @flow
import { PatientTypes } from "../../types";

export default (
  state: PatientStatistics = null,
  action: Action
): PatientStatistics => {
  const { type, payload } = action;

  switch (type) {
    case PatientTypes.SAVE_PATIENT_STATS:
      return { ...state, ...payload };
    default:
      return state;
  }
};
