// @flow
import { PatientTypes } from "../../types";

const initialState: Object = {
  text: undefined
};

const PatientDescriptionReducer = (
  state: PatientDescription = initialState,
  action: Action
) => {
  const { type, payload } = action;
  switch (type) {
    case PatientTypes.SAVE_PATIENT_DESCRIPTION:
      return { ...payload };
    case PatientTypes.EDIT_PATIENT_DESCRIPTION:
      return { ...payload };
    default:
      return state;
  }
};

export default PatientDescriptionReducer;
