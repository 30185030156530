import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import { Provider } from 'react-redux';
import { FirebaseCore } from './firebase';

import * as serviceWorker from './serviceWorker';
import App from './App';
import store from './redux/store';
import './index.css';

const Root = () => (
  <Provider store={store}>
    <App />
  </Provider>
);

Sentry.init({ dsn: 'https://7df4a40e8f83496cba502d0f61d9b74c@o444305.ingest.sentry.io/5437900' });

FirebaseCore.initApp();
ReactDOM.render(<Root />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// const x = [
//   {
//     summary: 'Consulta com Paciente 01',
//     description: 'Consulta semanal de 40 minutos',
//     start: { dateTime: '2020-08-18T22:30:00-03:00', timeZone: 'America/Recife' },
//     end: { dateTime: '2020-08-18T23:10:00-03:00', timeZone: 'America/Recife' },
//     attendees: [{ email: 'paciente1@moodar.com' }],
//     visibility: 'private',
//     reminders: {
//       useDefault: False,
//       overrides: [
//         { method: 'email', minutes: 1440 },
//         { method: 'email', minutes: 120 },
//         { method: 'popup', minutes: 30 }
//       ]
//     }
//   },
//   {
//     summary: 'Consulta com Paciente 01',
//     description: 'Consulta semanal de 40 minutos',
//     start: { dateTime: '2020-08-25T22:30:00-03:00', timeZone: 'America/Recife' },
//     end: { dateTime: '2020-08-25T23:10:00-03:00', timeZone: 'America/Recife' },
//     attendees: [{ email: 'paciente1@moodar.com' }],
//     visibility: 'private',
//     reminders: {
//       useDefault: False,
//       overrides: [
//         { method: 'email', minutes: 1440 },
//         { method: 'email', minutes: 120 },
//         { method: 'popup', minutes: 30 }
//       ]
//     }
//   },
//   {
//     summary: 'Consulta com Paciente 01',
//     description: 'Consulta semanal de 40 minutos',
//     start: { dateTime: '2020-09-01T22:30:00-03:00', timeZone: 'America/Recife' },
//     end: { dateTime: '2020-09-01T23:10:00-03:00', timeZone: 'America/Recife' },
//     attendees: [{ email: 'paciente1@moodar.com' }],
//     visibility: 'private',
//     reminders: {
//       useDefault: False,
//       overrides: [
//         { method: 'email', minutes: 1440 },
//         { method: 'email', minutes: 120 },
//         { method: 'popup', minutes: 30 }
//       ]
//     }
//   },
//   {
//     summary: 'Consulta com Paciente 01',
//     description: 'Consulta semanal de 40 minutos',
//     start: { dateTime: '2020-09-08T22:30:00-03:00', timeZone: 'America/Recife' },
//     end: { dateTime: '2020-09-08T23:10:00-03:00', timeZone: 'America/Recife' },
//     attendees: [{ email: 'paciente1@moodar.com' }],
//     visibility: 'private',
//     reminders: {
//       useDefault: False,
//       overrides: [
//         { method: 'email', minutes: 1440 },
//         { method: 'email', minutes: 120 },
//         { method: 'popup', minutes: 30 }
//       ]
//     }
//   }
// ];

// const b = {
//   kind: 'calendar#event',
//   etag: '"3194855146372000"',
//   id: '8m78ncc8omscfsjmf497fd0btg',
//   status: 'confirmed',
//   htmlLink:
//     'https://www.google.com/calendar/event?eid=OG03OG5jYzhvbXNjZnNqbWY0OTdmZDBidGcgc29kaWdpdG9jYXZhbG8xQG0',
//   created: '2020-08-14T17:52:52.000Z',
//   updated: '2020-08-14T17:52:53.549Z',
//   summary: 'Consulta com Paciente 01',
//   description: 'Consulta semanal de 40 minutos',
//   creator: { email: 'sodigitocavalo1@gmail.com', self: True },
//   organizer: { email: 'sodigitocavalo1@gmail.com', self: True },
//   start: { dateTime: '2020-08-27T22:30:00-03:00', timeZone: 'America/Recife' },
//   end: { dateTime: '2020-08-27T23:10:00-03:00', timeZone: 'America/Recife' },
//   visibility: 'private',
//   iCalUID: '8m78ncc8omscfsjmf497fd0btg@google.com',
//   sequence: 0,
//   attendees: [{ email: 'paciente1@moodar.com', responseStatus: 'needsAction' }],
//   hangoutLink: 'https://meet.google.com/ssm-wikc-fqb',
//   conferenceData: {
//     createRequest: {
//       requestId: '0oim6aun63pbnc3v0r4bpu04m8',
//       conferenceSolutionKey: { type: 'hangoutsMeet' },
//       status: { statusCode: 'success' }
//     },
//     entryPoints: [
//       {
//         entryPointType: 'video',
//         uri: 'https://meet.google.com/ssm-wikc-fqb',
//         label: 'meet.google.com/ssm-wikc-fqb'
//       }
//     ],
//     conferenceSolution: {
//       key: { type: 'hangoutsMeet' },
//       name: 'Google Meet',
//       iconUri:
//         'https://lh5.googleusercontent.com/proxy/bWvYBOb7O03a7HK5iKNEAPoUNPEXH1CHZjuOkiqxHx8OtyVn9sZ6Ktl8hfqBNQUUbCDg6T2unnsHx7RSkCyhrKgHcdoosAW8POQJm_ZEvZU9ZfAE7mZIBGr_tDlF8Z_rSzXcjTffVXg3M46v'
//     },
//     conferenceId: 'ssm-wikc-fqb',
//     signature: 'ADR/mfPvofcXqnZXZ6P2E9YnKWt0'
//   },
//   reminders: {
//     useDefault: False,
//     overrides: [
//       { method: 'email', minutes: 1440 },
//       { method: 'email', minutes: 120 },
//       { method: 'popup', minutes: 30 }
//     ]
//   }
// };
