import { PaymentTypes } from '../../types';

const initialState = {
  withdraws: [],
  balance: 0,
  balanceInTransaction: 0,
  balanceToReceive: 0,
  currentMonthReceived: 0,
  pastMonthReceived: 0,
  canTransferInMonth: null,
  extract: []
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case PaymentTypes.SAVE_THERAPIST_FINANCIAL:
      return { ...state, ...payload };
    case PaymentTypes.SAVE_THERAPIST_WITHDRAW_HISTORIC:
      return { ...state, withdraws: payload };
    case PaymentTypes.SAVE_CURRENT_BALANCE:
      return { ...state, balance: payload };
    case PaymentTypes.SAVE_CURRENT_BALANCE_IN_TRANSACTION:
      return { ...state, balanceInTransaction: payload };
    case PaymentTypes.SAVE_CURRENT_BALANCE_TO_RECEIVE:
      return { ...state, balanceToReceive: payload };
    case PaymentTypes.SAVE_CURRENT_MONTH_RECEIVES:
      return { ...state, currentMonthReceived: payload };
    case PaymentTypes.SAVE_PAST_MONTH_RECEIVES:
      return { ...state, pastMonthReceived: payload };
    case PaymentTypes.SAVE_CAN_TRANSFER:
      return { ...state, canTransferInMonth: payload };
    case PaymentTypes.SAVE_THERAPIST_EXTRACT: {
      return { ...state, extract: payload };
    }
    default:
      return state;
  }
};
