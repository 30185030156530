import {
  currentMonthTotalValueToReceive,
  getParsedDate,
  getPatientStatus,
  previousMonthTotalValueToReceive
} from '../../../utils/Utils';
import { HOWMANY_SUBSCRIPTIONS } from '../../../utils/consts';
import { PaymentTypes } from '../../types';

const initialState = {
  subscriptions: [],
  lastMonthAmount: 0,
  page: 1
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case PaymentTypes.SAVE_THERAPIST_SUBSCRIPTIONS: {
      const totalAmount = currentMonthTotalValueToReceive(payload.subscriptions);

      const lastMonthSubscriptionsValue = previousMonthTotalValueToReceive(payload.subscriptions);

      const subscriptions = payload.subscriptions
        .map((subscription, index) => {
          return {
            index: String((payload.page - 1) * HOWMANY_SUBSCRIPTIONS + (index + 1)),
            patient_name: subscription.patient_name,
            plan: subscription.plan,
            status:
              getPatientStatus(subscription.current_month_payment_status, subscription.plan) ||
              'Sem informação',
            successful_appointments: subscription.successful_appointments,
            availableIn: subscription.receiving_type,
            nextBillingDate: getParsedDate(subscription.next_billing_date)
          };
        })
        .sort((a, b) => b.status === 'paid');

      return {
        ...state,
        subscriptions,
        page: payload.page,
        totalAmount,
        lastMonthAmount: lastMonthSubscriptionsValue
      };
    }
    case PaymentTypes.SAVE_SUBSCRIPTIONS_AMOUNT: {
      return { ...state, totalAmount: payload };
    }
    default: {
      return state;
    }
  }
};
