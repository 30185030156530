// @flow
import React from 'react';
import moment from 'moment';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from 'recharts';
import { Spinner } from '../../../reusable';
import styles from './HumorMonitoring.module.css';

type HumorProps = {
  humorLogs: ?Array<PatientHumor>,
  isLoading: boolean
};

type CustomTooltipProps = {
  active: boolean,
  payload: Array<{
    payload: PatientHumor
  }>
};

const CustomTooltip = (props: CustomTooltipProps) => {
  const { active, payload } = props;
  const humor = payload && payload[0] && payload[0].payload;
  const { date, value } = humor || {};
  const humorStatus = ['Horrível', 'Mal', 'Regular', 'Bem', 'Radiante'];
  const iconsClass = [
    'far fa-sad-tear',
    'far fa-frown',
    'far fa-meh',
    'far fa-smile',
    'far fa-laugh-beam'
  ];

  const dateString = `${
    moment(date, 'YYYY-MM-DD')
      .format('LLLL')
      .split(',')[0]
  }, ${moment(date, 'YYYY-MM-DD').format('ll')}`;

  return (
    active && (
      <div className={styles.tooltip}>
        <p>
          <b>Humor:</b>{' '}
          <i>
            {humorStatus[value - 1]} <i className={iconsClass[value - 1]}></i>
          </i>
        </p>
        <p>
          <b>Dia:</b> <i>{dateString}</i>
        </p>
      </div>
    )
  );
};

class HumorStateless extends React.Component<HumorProps> {
  stripeYearFromDate = (humor: PatientHumor) =>
    humor.date
      .split('-')
      .slice(1, 3)
      .reverse()
      .join('/');

  render() {
    const { humorLogs, isLoading } = this.props;
    return !isLoading ? (
      <div className={styles.container}>
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart data={humorLogs} margin={{ left: -30, top: 30 }}>
            <XAxis dataKey={this.stripeYearFromDate} />
            {/* <YAxis domain={['dataMin', 'dataMax']} /> */}
            <YAxis domain={[1, 5]} />
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip content={CustomTooltip} />
            <Area
              type="monotone"
              dataKey="value"
              stroke="#41bfe7"
              fill="#41bfe7"
              activeDot={{ r: 8 }}
            />
          </AreaChart>
        </ResponsiveContainer>
        <p style={{ alignSelf: 'center' }}>Escala de Humor de 1 a 5: De Horrível à Radiante</p>
      </div>
    ) : (
      <Spinner />
    );
  }
}

export default HumorStateless;
