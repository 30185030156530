import React, { useState } from 'react';
import { Button, ContentEditable } from '../../../reusable';
import { StyledContainer, StyledEditor, StyledTextArea, StyledSubmit } from './Styles';
import 'draft-js/dist/Draft.css';

const BoxInputStateless = props => {
  const { onSubmit } = props;

  const [text, setText] = useState('');

  const onChangeEditor = editorState => {
    const text = editorState.getCurrentContent().getPlainText();
    setText(text);
  };

  const handleChange = event => {
    setText(event.target.value);
  };

  const handleSubmit = () => onSubmit(text);

  const canUseDraft = window.innerWidth > 600;

  return (
    <StyledContainer>
      <StyledEditor>
        {canUseDraft ? (
          <ContentEditable
            placeholder="Digite uma mensagem"
            allowShiftReturn
            clearOnSubmit
            onChange={onChangeEditor}
            onSubmit={handleSubmit}
          />
        ) : (
          <StyledTextArea value={text} onChange={handleChange} />
        )}
      </StyledEditor>
      <StyledSubmit className="fas fa-paper-plane" type="submit" onClick={handleSubmit} />
    </StyledContainer>
  );
};

export default BoxInputStateless;
