// @flow
import { PatientTypes } from '../types';

export const saveOldPatient = patient => ({
  type: PatientTypes.SAVE_OLD_PATIENT,
  payload: patient
});

export const clearOldPatient = () => ({
  type: PatientTypes.CLEAR_OLD_PATIENT,
  payload: {}
});
