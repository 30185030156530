import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { PaymentController } from '../../controllers';
import { Spinner, Table, Card, Grid, Button } from '../reusable';
import StartDatePicker from '../reusable/datepicker/startdate/startdate';
import { monthNames, withdrawTableHeaeder } from '../../utils/Utils';
import {
  StyledWallet,
  StyledWalletTable,
  StyledWalletButtons,
  TitleText,
  DivWithdrawTitle,
  BtnResetFilter,
  WalletReport,
  LoadingDiv,
  SelectField,
  WalletOBS
} from './Styles';
import { RouterActions } from '../../redux/actions';
import { Colors } from '../../constants/Colors';
import TransferModal from './TransferModal';
import { generateTherapistMonthlyReport } from '../../server/PaymentServer';

const Wallet = props => {
  const { saveHistoryOnRedux, setCurrentNav, history } = props;

  const {
    getFinancialInfo,
    getAccountInfo,
    getTotalAmountToReceive,
    transferBalance,
    getExtract
  } = props;

  const {
    account,
    profile,
    financial: { balance, balanceInTransaction, canTransferInMonth, extract }
  } = props;

  const lastMonthFirstDay = moment()
    .startOf('month')
    .add(-1, 'days')
    .startOf('month');

  const getAllowedMonths = year => {
    if (Number(year) === 2023) {
      return [11, 12];
    }
    if (Number(year) > 2023) {
      const nowMonth = new Date().getMonth() + 1;
      const months = [];
      let newMonth = 1;
      while (newMonth <= nowMonth) {
        months.push(newMonth);
        newMonth += 1;
      }
      return months;
    }
    return [];
  };

  const getAllowedYears = () => {
    const lastAllowedYear = new Date().getFullYear();
    const years = [];
    let year = 2023;
    while (year <= lastAllowedYear) {
      years.push(year);
      year += 1;
    }
    return years;
  };

  const [financialInfo, setFinancialInfo] = useState([]);
  const [isFetching, setFetching] = useState(true);
  const [modal, setModal] = useState(false);
  const [transferReturn, setTransferReturn] = useState('');
  const [success, setSuccess] = useState(undefined);
  const [rangeFinancialInfo, setRangeFinancialInfo] = useState([]);
  const [dateWithdrawFilter, setDateWithdrawFilter] = useState('');
  const [valueToReceive, setValueToReceive] = useState(0);
  const [monthReport, setMonthReport] = useState(lastMonthFirstDay.format('MM'));
  const [yearReport, setYearReport] = useState(lastMonthFirstDay.format('YYYY'));
  const [allowedMonths, setAllowedMonths] = useState(
    getAllowedMonths(lastMonthFirstDay.format('YYYY'))
  );
  const [loadingReport, setLoadingReport] = useState(false);
  const allowedYears = getAllowedYears();

  const autoCloseModal = () =>
    setTimeout(() => {
      setTransferReturn('');
      setModal(false);
    }, 5000);

  const handleModal = (visible, message, newSuccess) => {
    setModal(visible);
    setTransferReturn(message);
    setSuccess(newSuccess);
    autoCloseModal();
  };

  const requestTransfer = async () => {
    try {
      setModal(true);
      transferBalance(profile?.id, balance)
        .then(async transferResponse => {
          handleModal(true, transferResponse.message, transferResponse.success);
          await getAccountInfo(profile.id);
          autoCloseModal();
        })
        .catch(err => {
          handleModal(true, err.message, err.success);
        });
    } catch (err) {
      handleModal(true, err.message, err.success);
    }
  };

  const generateMonthlyReport = () => {
    setLoadingReport(true);
    generateTherapistMonthlyReport(monthReport, yearReport, profile?.id)
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `report-${monthReport}-${yearReport}.pdf`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setLoadingReport(false);
      });
  };

  const calculateValueToReceive = selectedExtract => {
    let newValue = 0;
    const extractConsidered = selectedExtract || extract;
    if (extractConsidered.length > 0) {
      extractConsidered.forEach(singleExtract => {
        newValue += singleExtract.value;
      });
    }
    setValueToReceive((newValue / 100).toFixed(2));
  };

  const fetching = async () => {
    try {
      saveHistoryOnRedux(history);
      setCurrentNav('Carteira');

      await getAccountInfo(profile.id);

      setFetching(true);

      // await getTotalAmountToReceive(currentMonthAmount);
      const responses = await Promise.all([
        getAccountInfo(profile.id),
        getFinancialInfo(),
        getExtract()
      ]);
      const financialResponse = responses[1];
      const extractResponse = responses[2];
      calculateValueToReceive(extractResponse);

      setFinancialInfo(financialResponse);
      setRangeFinancialInfo(financialResponse);

      setFetching(false);
    } catch (err) {}
  };

  useEffect(() => {
    const runFetch = () => {
      fetching();
    };
    runFetch();
  }, []);

  const filterWithdraw = selectedDate => {
    const dateObj = moment(selectedDate, 'YYYY-MM-DD');
    if (dateObj.isValid()) {
      const filteredFinancialByMonth = financialInfo.filter(info => {
        const createdAt = moment(info.created_at, 'DD/MM/YYYY');
        return dateObj <= createdAt;
      });
      setRangeFinancialInfo(filteredFinancialByMonth);
    } else {
      setRangeFinancialInfo([...financialInfo]);
    }
  };

  return !isFetching ? (
    <StyledWallet>
      <Grid rows={1} columns={3}>
        <Card title="Saldo em Conta" value={balance.toFixed(2)} ammount />
        <Card tip title="Saldo a Receber" value={valueToReceive} ammount />
        <Card
          tip
          title="Total previsto do Mês"
          value={(Number(balance) + Number(valueToReceive)).toFixed(2)}
          ammount
        />
      </Grid>

      <StyledWalletButtons>
        <Grid rows={1} columns={3} customColumns={[0.7, 0.5, 0.5, 0.3]}>
          <Button
            backgroundColor={canTransferInMonth ? Colors.blue : Colors.fourth}
            color={canTransferInMonth ? Colors.fourth : Colors.gray1}
            title="Transferir Saldo em Conta para conta bancária"
            func={() => requestTransfer()}
            disabled={!canTransferInMonth}
          ></Button>

          {!canTransferInMonth ? (
            <Button
              backgroundColor={Colors.primary}
              title={`Última transferência em ${moment(account.last_withdraw).format(
                'DD/MM/YYYY'
              )}`}
              disabled
            />
          ) : (
            <div></div>
          )}
          <div></div>
          <div></div>
        </Grid>
      </StyledWalletButtons>

      <Grid rows={1} columns={3}>
        <Card tip title="Dinheiro em Trânsito" value={balanceInTransaction.toFixed(2)} ammount />
      </Grid>

      <WalletReport>
        <SelectField onChange={e => setMonthReport(Number(e.target.value))} value={monthReport}>
          {allowedMonths.map((month, index) => {
            return (
              <option key={`month_${month}_${new Date().getTime() + index}`} value={month}>
                {monthNames[month - 1].bigName}
              </option>
            );
          })}
        </SelectField>
        <SelectField
          onChange={e => {
            const newYear = Number(e.target.value);
            setYearReport(newYear);
            const newAllowedMonths = getAllowedMonths(newYear);
            setAllowedMonths(newAllowedMonths);
            setMonthReport(newAllowedMonths[0]);
          }}
          value={yearReport}
        >
          {allowedYears.map((year, index) => {
            return (
              <option value={year} key={`year_${year}_${new Date().getTime() + index}`}>
                {year}
              </option>
            );
          })}
        </SelectField>
        {loadingReport ? (
          <LoadingDiv>
            <Spinner size="2em" />
          </LoadingDiv>
        ) : (
          <Button
            backgroundColor={Colors.blue}
            color={canTransferInMonth ? Colors.fourth : Colors.gray1}
            title={`Gerar Relatório de ${
              monthReport ? monthNames[Number(monthReport) - 1].bigName : null
            }`}
            style={{ minWidth: '300px', padding: '8px', margin: 0 }}
            func={() => generateMonthlyReport()}
          ></Button>
        )}
      </WalletReport>
      <WalletOBS>
        OBS: O relatório do mês vigente ({lastMonthFirstDay.format('MM')}/
        {lastMonthFirstDay.format('YYYY')}) é revisado pela nossa equipe e pode ser alterado até o
        5º dia útil. Em caso de dúvidas ou correções entre em contato com o suporte.
      </WalletOBS>

      <StyledWalletTable>
        <DivWithdrawTitle>
          <TitleText>
            <strong>Histórico de saques</strong>
          </TitleText>
          <DivWithdrawTitle>
            <BtnResetFilter
              type="button"
              onClick={e => {
                e.preventDefault();
                setRangeFinancialInfo([...financialInfo]);
                setDateWithdrawFilter('');
              }}
            >
              Limpar filtro
            </BtnResetFilter>
            <StartDatePicker
              onChange={filterWithdraw}
              firstDate={dateWithdrawFilter}
              setFirstDate={setDateWithdrawFilter}
              maxDate={moment().format('YYYY-MM-DD')}
            />
          </DivWithdrawTitle>
        </DivWithdrawTitle>
        <Table headers={withdrawTableHeaeder} data={rangeFinancialInfo} />
      </StyledWalletTable>
      <TransferModal
        onRequestOpenModal={() => setModal(true)}
        onRequestCloseModal={() => setModal(false)}
        modalVisible={modal}
        message={transferReturn}
        success={success}
      />
    </StyledWallet>
  ) : (
    <Spinner />
  );
};

const mapStateToProps = state => {
  const {
    user: { profile },
    payment: { month, financial, account }
  } = state;

  return {
    profile,
    month,
    account,
    financial
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getFinancialInfo: (month, year) => dispatch(PaymentController.getFinancialInfo(month, year)),
    getExtract: () => dispatch(PaymentController.getTherapistExtract()),
    saveHistoryOnRedux: history => dispatch(RouterActions.saveHistory(history)),
    setCurrentNav: current => dispatch(RouterActions.setCurrentRoute(current)),
    getAccountInfo: therapistId => dispatch(PaymentController.fetchAndSaveAccountInfo(therapistId)),
    getTotalAmountToReceive: value => dispatch(PaymentController.getTotalAmountToReceive(value)),
    transferBalance: (id, balance) => dispatch(PaymentController.transferBalance(id, balance))
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Wallet));
