// @flow
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import AppointmentsStateless from './Appointments.stateless';
import { fetchAppointments, changeAppointmentStatus } from './controller';

const Appointments = props => {
  const {
    fetchAndSaveAppointments,
    profileId,
    changeStatus,
    appointments,
    patientName,
    isHistoric,
    oldAppointments
  } = props;

  const [modalVisible, setModalVisible] = useState(false);
  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    if (profileId) {
      fetchAndSaveAppointments(profileId).then(() => setIsFetching(false));
    }
  }, []);

  const toggleModal = () => setModalVisible(!modalVisible);

  const onRequestOpenModal = event => {
    toggleModal();
    event.preventDefault();
  };

  const handleStatusUpdate = (appointmentId, status) => changeStatus(appointmentId, status);

  return (
    <AppointmentsStateless
      modalVisible={modalVisible}
      onRequestOpenModal={onRequestOpenModal}
      onRequestCloseModal={toggleModal}
      appointments={isHistoric ? oldAppointments : appointments}
      oldAppointments={oldAppointments}
      isFetching={isFetching}
      patientName={patientName}
      changeStatus={handleStatusUpdate}
      isHistoric={isHistoric}
    />
  );
};

const mapStateToProps = (state: Store, ownProps) => {
  const {
    patient: {
      appointments: { appointments, oldAppointments },
      profile
    }
  } = state;

  const {
    match: {
      params: { id }
    }
  } = ownProps;

  const patientName = profile && profile.name;

  return { appointments, oldAppointments, profileId: id, patientName };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchAndSaveAppointments: profileId => dispatch(fetchAppointments(profileId)),
  changeStatus: (appointmentId, status) => dispatch(changeAppointmentStatus(appointmentId, status))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Appointments));
