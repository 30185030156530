//  @flow
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';

export const put = (path: string, file: Object): Promise<any> => {
  return firebase
    .storage()
    .ref(path)
    .put(file);
};

export const putList = (paths: Array<string>, files: Array<Object>): Promise<any> => {
  return Promise.all(
    paths.map((path: string, idx: number) =>
      firebase
        .storage()
        .ref(path)
        .put(files[idx])
    )
  );
};

export const getURL = (path: string): Promise<any> => {
  return firebase
    .storage()
    .ref()
    .child(path)
    .getDownloadURL();
};
