import styled from 'styled-components';
import { Colors } from '../../constants/Colors';

export const StyledClientes = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  flex-direction: column;
  padding: 25px;
  background-color: ${Colors.background};
`;

export const StyledClientsTable = styled.div`
  padding: 15px;
  margin-top: 20px;
  width: 100%;

  @media (max-width: 600px) {
    margin: auto;
    max-width: 350px;
    overflow: scroll;
  }
`;

export const StyledLoadingDiv = styled.div`
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;
