// @flow
import { ChatTypes } from '../types';

// ==================== CHATS API ====================

export const saveChatsInfo = (chatsInfo: ChatsInfo): Action => ({
  type: ChatTypes.SAVE_CHATS_INFO,
  payload: chatsInfo
});

export const clearChatsInfo = (): Action => ({
  type: ChatTypes.CLEAR_CHATS_INFO,
  payload: {}
});

export const setBadge = (chatId: string): Action => ({
  type: ChatTypes.UPDATE_CHAT_INFO,
  payload: { id: chatId, info: { unreadMessages: true } }
});

export const clearBadge = (chatId: string): Action => ({
  type: ChatTypes.UPDATE_CHAT_INFO,
  payload: { id: chatId, info: { unreadMessages: false } }
});

// ==================== CURRENT CHAT API ====================

export const setCurrentChat = (chatInfo: ?ChatInfo): Action => ({
  type: ChatTypes.SET_CURRENT_CHAT,
  payload: chatInfo
});

export const setMessages = (messages: Messages): Action => ({
  type: ChatTypes.SET_MESSAGES,
  payload: messages
});

export const pushNewMessage = (msg: Message): Action => ({
  type: ChatTypes.PUSH_MESSAGES,
  payload: { [msg._id]: msg }
});

export const pushMessages = (messages: Messages): Action => ({
  type: ChatTypes.PUSH_MESSAGES,
  payload: messages
});

export const pushPrevMessages = (messages: Messages): Action => ({
  type: ChatTypes.PUSH_PREV_MESSAGES,
  payload: messages
});
