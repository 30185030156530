import styled from 'styled-components';

const hasCustomDisplay = display => {
  return Array.isArray(display);
};

const getColumns = columns => {
  const array = new Array(columns);
  return hasCustomDisplay(columns) ? columns.join(' ') : array.fill('1fr').join(' ');
};

export const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: ${props => getColumns(props.columns)};

  @media (max-width: 600px) {
    grid-template-columns: unset;
  }
`;
