import React from 'react';
import { connect } from 'react-redux';
import { SideMenuActions } from '../../../redux/actions';
import { StyledTitle } from './Styles';
import { Icon } from '../';
import { sandwich } from '../../../assets/images/images';

const Nav = ({ currentRoute, isAuthenticated, setExpanded }) => {
  const isOnMobile = window.innerWidth < 600;

  return isAuthenticated ? (
    <StyledTitle>
      {currentRoute}
      {isOnMobile ? <Icon icon={sandwich} onClick={() => setExpanded()} /> : null}
    </StyledTitle>
  ) : null;
};

const mapStateToProps = state => {
  const {
    router: { history, currentRoute },
    auth: {
      server: { isAuthenticated: serverAuth },
      firebase: { isAuthenticated: firebaseAuth },
      security: { setUpSuccess: securityAuth }
    }
  } = state;

  const isAuthenticated = serverAuth && firebaseAuth && securityAuth;

  return { history, currentRoute, isAuthenticated };
};

const mapDispatchToProps = dispatch => ({
  setExpanded: () => dispatch(SideMenuActions.setSideMenuExpanded())
});

export default connect(mapStateToProps, mapDispatchToProps)(Nav);
