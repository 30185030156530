// @flow
import { UserTypes } from '../types';

export const saveUserProfile = (profile: UserProfile): Action => ({
  type: UserTypes.SAVE_USER_PROFILE,
  payload: profile
});

export const clearUserProfile = (): Action => ({
  type: UserTypes.CLEAR_USER_PROFILE,
  payload: {}
});

export const updateUserProfile = profile => ({
  type: UserTypes.UPDATE_USER_PROFILE,
  payload: profile
});
