export const Colors = {
  primary: '#212331',
  seconday: '#EDF7FF',
  third: '#FFFFFF',
  fourth: '#C6C6C6',
  fifth: '#313341',
  gray1: '#EEEEEE',
  black: '#000000',
  blue: '#2D9BE2',
  black2: '#212331',
  background: '#EDF7FF',
  green: '#3F7F3F',
  gray: 'gray',
  red: '#8E0000',
  yellow: '#A0A000',
  white1: '#AAAAAA'
};
