// @flow
export const SAVE_CHATS_INFO: string = 'SAVE_CHATS_INFO';
export const CLEAR_CHATS_INFO: string = 'CLEAR_CHATS_INFO';
export const UPDATE_CHAT_INFO: string = 'UPDATE_CHAT_INFO';

export const SET_CURRENT_CHAT: string = 'SET_CURRENT_CHAT';
export const SET_MESSAGES: string = 'SET_MESSAGES';
export const PUSH_MESSAGES: string = 'PUSH_MESSAGES';
export const PUSH_PREV_MESSAGES: string = 'PUSH_PREV_MESSAGES';
export const UPDATE_MESSAGE: string = 'UPDATE_MESSAGE';
