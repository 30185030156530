// @flow
import { AppointmentTypes } from '../types';

const initialState: AppointmentsState = {
  upcomingAppointments: []
};

const sortAppointments = (appointments: PatientAppointments): PatientAppointments => {
  return appointments.sort((a, b) => (+(a.schedule < b.schedule) > 0 ? -1 : 1));
};

const AppointmentsReducer = (state: AppointmentsState = initialState, action: Action) => {
  const { type, payload } = action;

  switch (type) {
    case AppointmentTypes.SAVE_UPCOMING_APPOINTMENTS:
      return { ...state, upcomingAppointments: sortAppointments(payload) };
    case AppointmentTypes.CLEAR_UPCOMING_APPOINTMENTS:
      return { ...state, upcomingAppointments: payload };
    default:
      return state;
  }
};

export default AppointmentsReducer;
