// @flow
/* server auth action types */
export const LOGIN_SERVER_SUCCESS: string = 'LOGIN_SERVER_SUCCESS';
export const LOGIN_SERVER_FAILED: string = 'LOGIN_SERVER_FAILED';
export const LOGOUT_SERVER_SUCCESS: string = 'LOGOUT_SERVER_SUCCESS';

/* firebase auth action types */
export const LOGIN_FIREBASE_SUCCESS: string = 'LOGIN_FIREBASE_SUCCESS';
export const LOGIN_FIREBASE_FAILED: string = 'LOGIN_FIREBASE_FAILED';
export const LOGOUT_FIREBASE_SUCCESS: string = 'LOGOUT_FIREBASE_SUCCESS';
export const SAVE_FIREBASE_AUTH: string = 'SAVE_FIREBASE_AUTH';

// actions for the security status
export const SECURITY_SETUP_SUCCESS: string = 'SECURITY_SETUP_SUCCESS';
export const SECURITY_SETUP_FAILED: string = 'SECURITY_SETUP_FAILED';
