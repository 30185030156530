// @flow
import * as React from 'react';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import type { Match } from 'react-router-dom';
import { PatientController } from '../../../../controllers';
import { ErrorBoundary } from '../../../reusable';

import GoalsCreateStateless from './GoalsCreate.stateless';

type Props = {
  createGoal: (goal: {
    title: string,
    expireDate: string,
    frequency: number
  }) => Promise<Object>,
  closeModal: Function
};

type OwnProps = {
  match: Match
};

type State = {
  date: Date,
  title: string,
  frequency: number,
  isCreatingGoal: boolean
};

class GoalsCreate extends React.Component<Props, State> {
  state = { date: new Date(), title: '', frequency: 1, isCreatingGoal: false };

  errorBoundary: { current: ?React$ElementRef<*> } = React.createRef();

  handleTitleChange = (event: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({ title: event.target.value });
  };

  handleFrequencyChange = (event: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({ frequency: parseInt(event.target.value, 10) });
  };

  handleDateChange = (date: Date) => this.setState({ date });

  handleSubmit = (event: SyntheticMouseEvent<HTMLButtonElement>) => {
    const { title, frequency, date } = this.state;
    const { createGoal, closeModal } = this.props;

    // Indo colocar o spinner para a requisicao de criar meta
    this.setState({ isCreatingGoal: true });

    const expireDate: string = moment(date).format('YYYY-MM-DD 23:59');

    event.preventDefault();
    if (title && frequency && expireDate !== 'Invalid date') {
      createGoal({ title, frequency, expireDate })
        .then(resp => {
          this.setState({ isCreatingGoal: false });
          closeModal();
        })
        .catch(error => {
          this.setState({ isCreatingGoal: false });
          if (this.errorBoundary.current) {
            this.errorBoundary.current.forceError();
          }
        });
    }
  };

  render() {
    const errorMessage =
      'Algo deu errado ao tentar enviar a meta para o paciente. Recarregue a página para ver se mesmo assim ela foi criado.';
    return (
      <ErrorBoundary ref={this.errorBoundary} errorMessage={errorMessage}>
        <GoalsCreateStateless
          onTitleChange={this.handleTitleChange}
          onFrequencyChange={this.handleFrequencyChange}
          onDateChange={this.handleDateChange}
          onSubmit={this.handleSubmit}
          {...this.state}
        />
      </ErrorBoundary>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch, ownProps: OwnProps) => {
  const { match } = ownProps;
  const patientId: string = match.params.id || '';

  return {
    createGoal: (goal: { title: string, expireDate: string, frequency: number }) =>
      PatientController.createGoal(patientId, goal)(dispatch)
  };
};

export default withRouter(
  connect(
    null,
    mapDispatchToProps
  )(GoalsCreate)
);
