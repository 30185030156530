import React from 'react';
import { TooltipIcon } from '..';
import { StyledCard, StyledTip, StyledTitle, StyledValue } from './Styles';

const handleTooltipText = title =>
  ({
    'Pacientes Ativos': 'Pacientes em assinatura recorrente',
    'Saldo em Conta': 'Suspensa',
    'Saldo a Receber': 'Estimativa do mês atualizada do valor pendente a entrar na conta',
    'Recebimentos este Mês': 'Recebimentos este Mês',
    'Recebimentos Mês Anterior': 'Recebimentos Mês Anterior',
    'Dinheiro em Trânsito': 'Dinheiro em Trânsito'
  }[title]);

const Card = ({ title, value, ammount, tip }) => {
  return (
    <StyledCard>
      <StyledTitle>{title}</StyledTitle>
      <StyledValue ammount={ammount}>{value}</StyledValue>
      {tip ? (
        <StyledTip>
          <TooltipIcon
            title={handleTooltipText(title)}
            icon={'mdi mdi-information-outline'}
            clickFunction={() => null}
          />
        </StyledTip>
      ) : null}
    </StyledCard>
  );
};

export default Card;
