import {
  convertStatus,
  currentMonthTotalValueToReceive,
  getActivePatients,
  getParsedDate,
  getParsedId,
  previousMonthTotalValueToReceive
} from '../../../utils/Utils';
import { PaymentTypes } from '../../types';

const initialState = {
  invoices: [],
  totalAmount: 0,
  activeInvoices: 0,
  lastMonthAmount: 0
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case PaymentTypes.SAVE_THERAPIST_APPOITMENTS_INFO:
      const totalAmount = currentMonthTotalValueToReceive(payload);
      const activeInvoices = getActivePatients(payload);

      const lastMonthInvoicesValue = previousMonthTotalValueToReceive(payload);

      const invoices = payload
        .map(invoice => {
          return {
            id: getParsedId(invoice.id),
            patient_name: invoice.patient_name,
            plan: invoice.plan,
            value: `R$${invoice.value_to_receive}`,
            financial_return_date: getParsedDate(invoice.financial_return_date),
            status: convertStatus(invoice.status) || 'Sem informação'
          };
        })
        .sort((a, b) => b.status === 'Pago');

      return {
        ...state,
        invoices,
        totalAmount,
        activeInvoices,
        lastMonthAmount: lastMonthInvoicesValue
      };
    case PaymentTypes.SAVE_INVOICES_AMOUNT:
      return { ...state, totalAmount: payload };
    default:
      return state;
  }
};
