import styled from 'styled-components';

export const Container = styled.button`
  background-color: ${props => (props.active ? '#ddd' : 'white')};
  min-height: 5.5em;
  border: none;
  outline: none;
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.4em;
  border-bottom: 1px solid #ddd;
  font-size: medium;
  &:hover {
    background-color: ${props => (props.active ? '#ddd' : '#eee')};
  }
`;

export const Body = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 10px;
  font-family: Montserrat;
`;

export const Title = styled.strong``;

export const SubTitle = styled.small`
  font-style: italic;
  text-align: left;
`;

export const Badge = styled.i`
  font-size: 25px;
  color: #f298a7;
`;

export const InsideBody = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: Montserrat;
`;

export const NewUserTag = styled.div`
  background-color: green;
  margin-left: 10px;
  color: white;
  padding: 2px;
`;
