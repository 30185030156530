// @flow
import { fetchUpcomingAppointments } from '../../../server/AppointmentServer';
import { saveUpcomingAppointments } from '../../../redux/actions/AppointmentsActions';
import { ChatController } from '../../../controllers';

export const retrieveUpcomingAppoinments = async (dispatch: Dispatch) => {
  const response = await fetchUpcomingAppointments();
  const parsedAppointments = response.appointments.map(appo => ({
    ...appo,
    patientName: appo.patient_name
  }));
  dispatch(saveUpcomingAppointments(parsedAppointments));
};

export const goToChatAndMakeCall = (id: number, replaceRoute: Function) => (
  dispatch: Dispatch,
  getState: GetState
): void => {
  const {
    chat: { chats }
  } = getState();
  const patientChat = Object.values(chats).find(chat => chat.patientId === id);
  dispatch(ChatController.selectCurrentChat(patientChat.id));
  replaceRoute('/video');
};
