import styled from 'styled-components';

const iconSize = size => (size === 'sm' || size === 'md' ? '25px' : '32px');

export const StyledIcon = styled.div``;

export const StyledIconImage = styled.img`
  color: white;
  height: ${props => iconSize(props.size)};
  width: ${props => iconSize(props.size)};
`;
