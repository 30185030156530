import { PaymentTypes } from '../types';

export const saveAccountInfo = (accountInfo): Action => ({
  type: PaymentTypes.SAVE_THERAPIST_ACCOUNT_INFO,
  payload: accountInfo
});

export const createBalanceTransfer = (ammount): Action => ({
  type: PaymentTypes.CREATE_THERAPIST_TRANSFER_BALANCE,
  payload: ammount
});

export const saveSubscriptionsInfo = (subscriptionsInfo): Action => ({
  type: PaymentTypes.SAVE_THERAPIST_SUBSCRIPTIONS,
  payload: subscriptionsInfo
});

export const saveSubscriptionsTotalAmount = (amount): Action => ({
  type: PaymentTypes.SAVE_SUBSCRIPTIONS_AMOUNT,
  payload: amount
});

export const saveInvoicesTotalAmount = (amount): Action => ({
  type: PaymentTypes.SAVE_INVOICES_AMOUNT,
  payload: amount
});

export const saveAppointmentsInfo = (appointments): Action => ({
  type: PaymentTypes.SAVE_THERAPIST_APPOITMENTS_INFO,
  payload: appointments
});

export const saveCoparticipationsInfo = (coparticipationInvoices): Action => ({
  type: PaymentTypes.SAVE_THERAPIST_COPARTICIPATION_INFO,
  payload: coparticipationInvoices
});

export const saveCurrentMonth = (date): Action => ({
  type: PaymentTypes.SAVE_CURRENT_MONTH,
  payload: date
});

export const saveMonthsAmount = (monthsAmount): Action => ({
  type: PaymentTypes.SAVE_THERAPIST_AMOUNT,
  payload: monthsAmount
});

export const cleanTotalAmmount = (): Action => ({
  type: PaymentTypes.CLEAN_THERAPIST_AMOUNT,
  payload: [[], 0]
});

//Financial
export const saveTherapistFinancial = (financialInfo): Action => ({
  type: PaymentTypes.SAVE_THERAPIST_FINANCIAL,
  payload: financialInfo
});

export const saveCurrentMonthReceived = (amount): Action => ({
  type: PaymentTypes.SAVE_CURRENT_MONTH_RECEIVES,
  payload: amount
});

export const saveBalance = (amount): Action => ({
  type: PaymentTypes.SAVE_CURRENT_BALANCE,
  payload: amount
});

export const saveCanTransfer = (possibility): Action => ({
  type: PaymentTypes.SAVE_CAN_TRANSFER,
  payload: possibility
});

export const saveBalanceInTransaction = (amount): Action => ({
  type: PaymentTypes.SAVE_CURRENT_BALANCE_IN_TRANSACTION,
  payload: amount
});

export const saveBalanceToReceive = (amount): Action => ({
  type: PaymentTypes.SAVE_CURRENT_BALANCE_TO_RECEIVE,
  payload: amount
});

export const savePreviousMonthReceived = (amount): Action => ({
  type: PaymentTypes.SAVE_PAST_MONTH_RECEIVES,
  payload: amount
});

export const saveTherapistExtract = payload => ({
  type: PaymentTypes.SAVE_THERAPIST_EXTRACT,
  payload
});
