// @flow
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import  { Match } from 'react-router-dom';
import { PatientController } from '../../../../controllers';
import HeaderRightStateless from './HeaderRight.stateless';
import { captureException } from '@sentry/browser';



const HeaderRight = props => {
  const {
    match: {
      params: { id: idFromUrl }
    },
    profileId,
    fetchAndSaveStats,
    fetchAndSaveDescription,
    editDescription,
    patientDescription,
    statistics
  } = props;

  const [isLoading, setLoading] = useState(true);

  const fetchStatsDataAndDescription = async () => {
    try {
      await fetchAndSaveStats();
      await fetchAndSaveDescription();
      setLoading(false);
    } catch (err) {
      captureException(err);
    }
  };

  useEffect(() => {
    !profileId || idFromUrl !== profileId ? fetchStatsDataAndDescription() : setLoading(false);
  }, []);

  return (
    <HeaderRightStateless
      description={patientDescription}
      statistics={statistics}
      isLoading={isLoading}
      onDescriptionEdited={text => editDescription(text)}
    />
  );
};

const mapStateToProps = (state: Store) => {
  const {
    patient: {
      statistics,
      description: { text: patientDescription },
      profile
    }
  } = state;

  return {
    statistics,
    patientDescription,
    profileId: profile && profile.id
  };
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: OwnProps) => {
  const { match } = ownProps;
  const patientId: string = match.params.id || '';

  return {
    fetchAndSaveStats: () => PatientController.fetchAndSaveStats(patientId)(dispatch),
    editDescription: (description: string) =>
      PatientController.editDescription(patientId, description)(dispatch),
    fetchAndSaveDescription: () => PatientController.fetchAndSaveDescription(patientId)(dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HeaderRight));
