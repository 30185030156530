import styled from 'styled-components';
import { Colors } from '../../constants/Colors';

export const StyledCalendar = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  flex-direction: column;
  padding: 25px;
  background-color: ${Colors.background};
  height: 94vh;
`;

export const StyledCalendarFrame = styled.iframe`
  display: flex;
  height: 100%;
`;
