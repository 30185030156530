// @flow
import { PatientTypes } from "../../types";

export default (
  state: PatientProfile = null,
  action: Action
): PatientProfile => {
  const { type, payload } = action;

  switch (type) {
    case PatientTypes.SAVE_PATIENT_PROFILE:
      return { ...state, ...payload };
    default:
      return state;
  }
};
