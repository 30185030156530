// @flow
import React from 'react';
import Modal from 'react-modal';
import { Spinner } from '../reusable';
import styles from './Balance.module.css';

const TransferModal = (props: Props) => {
  const { modalVisible, onRequestCloseModal, message, success } = props;

  return (
    <React.Fragment>
      <Modal
        isOpen={modalVisible}
        onRequestClose={onRequestCloseModal}
        ariaHideApp={false}
        style={{
          content: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            overflow: 'none'
          }
        }}
      >
        {!message ? (
          <div>
            Realizando transferência
            <Spinner style={{ marginTop: 30 }} />
          </div>
        ) : message && success ? (
          <div>
            {message}
            <br />
            <br />
            <div className={styles.success}>
              <i className={{ textAlign: 'center' }} class="far fa-check-circle fa-4x"></i>
            </div>
          </div>
        ) : (
          <div>
            {message}
            <br />
            <br />
            <div className={styles.error}>
              <i className={{ textAlign: 'center' }} class="fas fa-exclamation-triangle fa-4x"></i>
            </div>
          </div>
        )}
      </Modal>
    </React.Fragment>
  );
};

export default TransferModal;
