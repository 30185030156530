import { PaymentTypes } from '../../types';

const initialState = {
  returnsByMonth: {},
  monthTotal: 0
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case PaymentTypes.SAVE_THERAPIST_AMOUNT:
      return { ...state, monthTotal: payload, returnsByMonth: payload };
    case PaymentTypes.CLEAN_THERAPIST_AMOUNT:
      return { ...state, monthAmmount: payload[0], monthTotal: payload[1], returnsByMonth: {} };
    default:
      return state;
  }
};
