import styled from 'styled-components';
import { Colors } from '../../../../constants/Colors';

export const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5em;
  max-height: 20vh;

  @media (max-width: 600px) {
    background-color: ${Colors.third};
  }
`;

export const StyledEditor = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0.5em 1em;
  background-color: white;
  width: 95%;
  border: 1px solid #ddd;
  font-size: 14px;
  overflow-y: auto;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;

  @media (max-width: 600px) {
    padding: unset;
    width: 85%;
  }
`;

export const StyledTextArea = styled.textarea`
  padding: 5px;
  margin: 0;
  border: none;
  border-radius: 100px;
  max-height: 100px;
  height: 50px;
`;

export const StyledChatContainer = styled.div`
  display: grid;
  grid-template-columns: 30vw 70vw;
  height: 100%;
  background-color: ${Colors.background};
  display: flex;

  @media (max-width: 600px) {
    grid-template-columns: 100%;
  }
`;

export const StyledSubmit = styled.div`
  border-radius: 100%;
  padding: 10px;

  @media (max-width: 600px) {
    padding: 15px;
  }
`;
