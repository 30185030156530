import styled, { css } from 'styled-components';
import { Colors } from '../../../constants/Colors';

export const StyledTable = styled.table`
  background-color: white;
  font-size: 15px;
  font-family: Roboto;
  font-weight: 300;
  width: 100%;

  @media (max-width: 600px) {
    overflow: scroll;
    max-height: 350px;
  }
`;

export const StyledHead = styled.thead``;

export const StyledTableBody = styled.thead``;

export const StyledTH = styled.th`
  background-color: ${Colors.primary};
  color: ${Colors.fourth};
  padding-left: 20px;
  padding-right: 20px;
  border: 1px solid #888888;
`;

export const StyledTR = styled.tr`
  height: 45px;
`;

export const StyledTD = styled.td`
  border: 1px solid #888888;
  padding-left: 20px;
  height: 45px;

  &:last-child {
    width: 35%;
  }
  &:nth-last-child(2) {
    width: 12%;
  }
`;

export const StyledContent = styled.div`
  ${props =>
    props.isStatus &&
    css`
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-right: 20px;
      min-width: 180px;
    `}
  ${props =>
    props.dependsOnPatient &&
    css`
      display: flex;
      flex-direction: row;
      gap: 0.5rem;
      margin-right: 20px;
      min-width: 180px;
    `}
`;

export const StyledStatusDot = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: none;
  background-color: ${props => props.color};
`;
