// @flow
import * as React from 'react';
import moment from 'moment';

import {
  Bubble,
  BubbleDetail,
  Container,
  Message,
  Image,
  Footer,
  Time,
  Tick
} from './TalkingBubble.styled';

import { Spinner } from '../../../../reusable';

type Props = {
  message: Message,
  side: string
};

const BubbleColors = {
  right: '#f298a7',
  left: 'white'
};

const TimeColors = {
  right: '#444',
  left: '#888'
};

const TalkingBubble = (props: Props) => {
  const { message, side } = props;
  const createdAt = message?.createdAt;
  const text = message?.text;
  const image = message?.image;
  const metadata = message?.metadata;
  const synced = message?.synced;
  const tickClass = synced ? 'fas fa-check' : 'far fa-clock';

  return (
    <Bubble side={side}>
      <Container side={side} color={BubbleColors[side]} role="link" tabIndex={0}>
        {text && <Message>{text}</Message>}
        {image && <Image src={image} ratio={metadata.aspectRatio} />}
        {image === '' && <Spinner size="5em" />}
        <Footer color={TimeColors[side]}>
          <Time>{moment(createdAt).format('LT')}</Time>
          {side === 'right' && <Tick className={tickClass} />}
        </Footer>
      </Container>
      <BubbleDetail side={side} color={BubbleColors[side]} />
    </Bubble>
  );
};

export default TalkingBubble;
