import styled from "styled-components";

export default styled.a`
  width: ${p => p.size};
  height: ${p => p.size};
  background: url(${p => p.src}),
    url(${p => p.defaultSrc}) no-repeat center center;
  border-radius: 50%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  cursor: pointer;
`;
