import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import styles from './Reschedule.module.css';
import moment from 'moment';
import Calendar from 'react-calendar';
import {
  retrieveTimeslots,
  rescheduleAppointment,
  getAppointmentDuration,
  getTherapistAvailableDays,
  fetchAppointments,
  changeAppointmentStatus
} from '../controller';
import 'react-calendar/dist/Calendar.css';
import { Spinner, Button } from '../../../reusable';
import { retrieveUpcomingAppoinments } from '../../../chat/schedule/controller';
import { captureException } from '@sentry/browser';

const Timeslots = props => {
  const { agenda, appoTime, setAppoTime } = props;
  const hours = Object.keys(agenda);
  const handleHour = time => (time === appoTime ? '#36abf8' : undefined);

  return (
    <div className={styles.agenda}>
      {hours.map(hour => {
        return (
          <div
            className={styles.hour}
            style={{ backgroundColor: handleHour(hour) }}
            onClick={() => setAppoTime(hour)}
          >
            {hour}
          </div>
        );
      })}
    </div>
  );
};

const Reschedule = props => {
  const {
    appointment,
    therapistId,
    plan,
    userId,
    name,
    setModal,
    reschedule,
    fetchAndSaveAppointments,
    getUpcomingAppointments,
    changeStatus
  } = props;

  const [availableDays, setAvailableDays] = useState([]);
  const [agenda, setAgenda] = useState({});

  const [appoHour, setAppoHour] = useState('');
  const [day, setDay] = useState(undefined);

  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState(false);

  const appoDuration = plan ? getAppointmentDuration(plan) : appointment.duration;
  const patientName = appointment.patientName || name;
  const patientId = appointment.patient || userId;

  const maxDate = moment()
    .add(30, 'days')
    .toDate();

  useEffect(() => {
    getTherapistAvailableDays(therapistId)
      .then(res => setAvailableDays(res))
      .catch(err => setAvailableDays([]));
    if (day) {
      retrieveTimeslots(therapistId, moment(day).format('YYYY-MM-DD'), appoDuration, patientId)
        .then(res => {
          setError(false);
          setAgenda(res);
        })
        .catch(err => {
          setError('Não há horários disponíveis nesse dia :(');
          setAgenda({});
          setDay(undefined);
          setAppoHour(undefined);
        });
    }
  }, [day]);

  const nextAvailableDay = day => {
    return day;
  };

  const submitReschedule = async (
    schedule,
    appoDuration,
    appointmentId,
    patientId,
    therapistId
  ) => {
    setFetching(true);
    reschedule(schedule, appoDuration, appointmentId, patientId, therapistId)
      .then(async res => {
        setModal(false);
        setFetching(false);
        await fetchAndSaveAppointments(patientId);
        await getUpcomingAppointments();
        if (appointment.status === 'successful') {
          changeStatus(appointment?.id, 'confirmed');
        }
      })
      .catch(err => {
        captureException(err);
      });
  };

  const schedule = `${moment(day).format('YYYY-MM-DD')} ${appoHour}`;

  return (
    <div className={styles.reschedule}>
      <div className={styles.header}>
        <h4>
          <strong>Quando</strong> quer remarcar com {patientName.split(' ')[0]}?
        </h4>
      </div>
      <div className={styles.calendar}>
        <Calendar
          className={'react-calendar'}
          value={nextAvailableDay(day)}
          onChange={e => setDay(e)}
          minDate={new Date()}
          maxDate={maxDate}
          tileDisabled={({ date }) => !availableDays.some(day => day === moment(date).day())}
        />
      </div>
      <Timeslots agenda={agenda} setAppoTime={setAppoHour} appoTime={appoHour} />
      {error ? <div className={styles.error}>{error}</div> : null}
      {fetching ? (
        <Spinner />
      ) : (
        <Button
          backgroundColor={appoHour && day ? '#2e9be2' : '#c6e4f7'}
          title={'Atualizar consulta'}
          icon={'fas fa-check'}
          func={() =>
            appoHour && day
              ? submitReschedule(schedule, appoDuration, appointment.id, patientId, therapistId)
              : null
          }
        ></Button>
      )}
    </div>
  );
};

const mapStateToProps = state => {
  const {
    user: {
      profile: { id }
    },
    patient: { profile }
  } = state;

  const plan = profile?.plan;
  const name = profile?.name;
  const userId = profile?.id;

  return { therapistId: id, plan, userId, name };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  reschedule: (schedule, appoDuration, appointmentid, patientId, therapistId) =>
    dispatch(rescheduleAppointment(schedule, appoDuration, appointmentid, patientId, therapistId)),
  fetchAndSaveAppointments: profileId => dispatch(fetchAppointments(profileId)),
  getUpcomingAppointments: () => dispatch(retrieveUpcomingAppoinments),
  changeStatus: (appointmentId, status) => dispatch(changeAppointmentStatus(appointmentId, status))
});

export default connect(mapStateToProps, mapDispatchToProps)(Reschedule);
