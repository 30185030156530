import styled from "styled-components";

export default styled.div`
  display: inline;
  padding: 0.4em 0.6em;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  background-color: white;
  border-radius: calc(calc(12px + 0.8em) / 2);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  color: #404040;
  margin: 10px auto;
`;
