// @flow
import firebase from 'firebase/compat/app';
import firebaseProperties from './firebase.properties.json';

export const initApp = () =>
  firebase.initializeApp({
    apiKey: firebaseProperties.apiKey,
    authDomain: firebaseProperties.authDomain,
    databaseURL: firebaseProperties.databaseURL,
    projectId: firebaseProperties.projectId,
    storageBucket: firebaseProperties.storageBucket,
    messagingSenderId: firebaseProperties.messagingSenderId,
    appId: firebaseProperties.appId
  });
