// @flow
// import { functions, auth } from 'firebase';
import { EThree } from '@virgilsecurity/e3kit-browser';
// import { EThree } from '@virgilsecurity/e3kit';
// import { EThree } from '@virgilsecurity/e3kit-browser/dist/browser.asmjs.es';
import { captureException, captureMessage } from '@sentry/browser';
import { AuthController } from '../../controllers';

class Security {
  userPublicKey: ?Object;

  recipientPublicKey: ?Object;

  recipientsPublicKeys: Object;

  userUid: ?string;

  therapistUid: ?string;

  eThree: Object;

  constructor() {
    // Vao ser usadas para poder encrypt e decrypt od dados
    this.userPublicKey = null;
    this.recipientPublicKey = null;
    this.recipientsPublicKeys = {};
  }

  setUserPublicKey = (publicKey: Object) => {
    this.userPublicKey = publicKey;
  };

  setRecipientPublicKey = (publicKey: Object) => {
    this.recipientPublicKey = publicKey;
  };

  addRecipientPublicKey = (patientUid: string, publicKey: string) => {
    this.recipientsPublicKeys[patientUid] = publicKey;
  };

  getUserPublicKey = () => {
    return this.userPublicKey;
  };

  getRecipientPublicKey = () => {
    return this.recipientPublicKey;
  };

  setUids = (userUid: ?string, therapistUid: ?string) => {
    // We save the user and therapist Uid to be used later in case their public keys were not set
    // and they're returned null when trying to encrypt message
    // If they come as null we dont set them to preserve previously saved uids
    if (userUid) {
      this.userUid = userUid;
    }

    if (therapistUid) {
      this.therapistUid = therapistUid;
    }
  };

  getUserUid = () => {
    return this.userUid;
  };

  getTherapistUid = () => {
    return this.therapistUid;
  };

  getEThree = async () => {
    if (!this.eThree) {
      // Vai tentar setar o eThree duas vezes se for preciso
      try {
        captureMessage('eThree not initialized, trying to initialize again');
        await this.initialize(this.therapistUid);
        return this.eThree;
      } catch (error) {
        captureException(error);
        try {
          captureMessage('eThree not initialized, trying to initialize again 2');
          await this.initialize(this.therapistUid);
          return this.eThree;
        } catch (error2) {
          captureException(error2);
          throw error2;
        }
      }
    }
    return this.eThree;
  };

  // loadWasm = async () => {
  //   try {
  //     const e3kit = await import('@virgilsecurity/e3kit-browser');
  //     // this.setState({wasm});
  //     const alice = await e3kit.EThree.initialize(createGetToken('alice'));
  //   } catch(err) {
  //     console.error(`Unexpected error in loadWasm. [Message: ${err.message}]`);
  //   }
  // };

  initialize = async (therapistUid): Promise<Object> => {
    const initializeFunction = () =>
      AuthController.getVirgilJwtToken(therapistUid || this.therapistUid);

    try {
      const eThree = await EThree.initialize(initializeFunction);
      this.eThree = eThree;
    } catch (error) {
      captureException(error);
      throw error;
    }
  };

  restorePrivateKeyOrRegister = async (userUid: string) => {
    const eThree = await this.getEThree();

    if (userUid) {
      const hasLocalPrivateKey = await eThree.hasLocalPrivateKey();

      const updateTherapistDevice = await AuthController.checkUpdatedDevice();

      if (updateTherapistDevice.updated_device) {
        try {
          return await this.cleanAndRestore(userUid);
        } catch (err) {
          captureMessage('Não conseguiu dar CleanAndRestore', err);
        }
      } else {
        return !hasLocalPrivateKey ? await this.restorePrivateKey(userUid) : null;
      }
    } else {
      captureMessage('Tentou fazer o restorePrivateKeyOrRegister sem userUid');
    }
  };

  restorePrivateKey = async userUid => {
    const eThree = await this.getEThree();

    try {
      await eThree.restorePrivateKey(userUid);
    } catch (error) {
      captureException(error);
      captureMessage(`Error no restorePrivateKey(userUid) -> ${error.name} ${userUid}`);
      if (error.name === 'WrongKeyknoxPasswordError') {
        try {
          captureMessage(`Caiu no WrongKeyknox(userUid) -> ${error.name} ${userUid}`);
          // await this.rotatePrivateKey(userUid);
        } catch (err) {
          captureMessage(`Error no restorePrivateKey(userUid) -> ${error.name} ${userUid}`);
        }
      } else if (
        error.name === 'PrivateKeyAlreadyExistsError' ||
        error.name === 'IdentityAlreadyExistsError'
      ) {
        this.cleanAndRestore(userUid || this.therapistUid);
      } else if (error.name === 'MissingPrivateKeyError') {
        try {
          await this.registerUserAndBackupPrivateKey(userUid || this.therapistUid);
        } catch (error) {}
      } else if (error.name === 'PrivateKeyNoBackupError') {
        try {
          await this.backupUserPrivateKey(userUid || this.therapistUid);
          await eThree.restorePrivateKey(userUid || this.therapistUid);
        } catch (error) {
          captureMessage(`Não conseguiu dar backup/restore e vai tentar register()`, error);
        }
      } else {
        await this.registerUserAndBackupPrivateKey(userUid || this.therapistUid);
      }
    }
  };

  cleanAndRestore = async userUid => {
    const eThree = await this.getEThree();

    try {
      await eThree.cleanup();
      await eThree.restorePrivateKey(userUid || this.therapistUid);
    } catch (error) {
      captureException(error);
    }
  };

  resetPrivateKey = async uid => {
    const eThree = await this.getEThree();

    try {
      await eThree.cleanup();
      await eThree.resetPrivateKeyBackup();
      await eThree.backupPrivateKey(uid);
      await eThree.restorePrivateKey(uid);
    } catch (error) {}
  };

  rotatePrivateKey = async uid => {
    const eThree = await this.getEThree();
    try {
      await eThree.resetPrivateKeyBackup();
      await eThree.cleanup();
      await eThree.rotatePrivateKey();
      await eThree.backupPrivateKey(uid);
      captureMessage(`Deu rotatePrivateKey com sucesso`);
    } catch (error) {}
  };

  registerUserAndBackupPrivateKey = async (password: string) => {
    try {
      await this.registerUser();
      await this.backupUserPrivateKey(password);
    } catch (error) {
      if (error.name === 'IdentityAlreadyExistsError') {
        this.cleanAndRestore(password || this.therapistUid);
      }
      // captureException(error);
      throw error;
    }
  };

  registerUser = async () => {
    const eThree = await this.getEThree();

    try {
      await eThree.register();
    } catch (regError) {
      // captureException(regError);
      throw regError;
    }
  };

  backupUserPrivateKey = async (password: string) => {
    const eThree = await this.getEThree();

    try {
      await eThree.backupPrivateKey(password);
    } catch (error) {
      if (error.name === 'MissingPrivateKeyError') {
        this.registerUserAndBackupPrivateKey(password);
        captureMessage(`Register after MissingPrivateKey - ${password}`);
      }
      throw error;
    }
  };

  findUsers = async (identity: string) => {
    const eThree = await this.getEThree(identity);
    let key = null;
    try {
      key = await eThree.findUsers(identity);
    } catch (error) {
      captureException(error);
    }
    return key;
  };

  encryptText = async (text: string, recipientPublicKey: string) => {
    const eThree = await this.getEThree();
    let encryptedText = null;

    try {
      encryptedText = await eThree.encrypt(text, recipientPublicKey);
    } catch (error) {
      captureException(error);
      throw error;
    }

    return encryptedText;
  };

  decryptText = async (text: string, senderPublicKey: Object) => {
    const eThree = await this.getEThree();
    let decryptedText = null;
    try {
      decryptedText = await eThree.decrypt(text, senderPublicKey);
    } catch (error) {
      captureException(error);
      throw error;
    }
    return decryptedText;
  };

  hasLocalPrivateKey = async () => {
    const eThree = await this.getEThree();
    const hasLocalPrivateKey = await eThree.hasLocalPrivateKey();
    return hasLocalPrivateKey;
  };

  restoreUserPrivateKey = async (password: string) => {
    const eThree = await this.getEThree();

    try {
      await eThree.restorePrivateKey(password);
    } catch (error) {
      // captureException(error);
    }
  };

  cleanupUserKey = async () => {
    const eThree = await this.getEThree();

    try {
      await eThree.cleanup();
      captureMessage('Fez o cleanUp das chaves');
    } catch (error) {
      captureException(error);
    }
  };
}

export default new Security();
