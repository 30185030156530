// @flow
import * as React from 'react';
import cx from 'classnames';
import HumorMonitoring from './humor/HumorMonitoring';
import PatientAppointments from '../appointments';
import PatientGoals from '../goals/PatientGoals';
import styles from './PatientMonitoringStyle.module.css';

type State = {
  selectedTab: string
};

class PatientMonitoring extends React.Component<null, State> {
  state = { selectedTab: 'humorLogs' };

  onTabClick = (event: SyntheticInputEvent<*>, tabName: string) => {
    this.setState({ selectedTab: tabName });
    event.preventDefault();
  };

  getTabClass = (tabName: string): Object => {
    const { selectedTab } = this.state;
    if (tabName === selectedTab) {
      return cx(styles.tab, styles.tabActive);
    }
    return styles.tab;
  };

  render() {
    const { selectedTab } = this.state;
    const { isHistoric } = this.props;
    const goalsTab = this.getTabClass('goals');
    const humorLogsTab = this.getTabClass('humorLogs');
    // const appointmentsTab = this.getTabClass('appointments');

    return (
      <div className={styles.container}>
        <div className={styles.nav}>
          <button
            type="button"
            onClick={e => this.onTabClick(e, 'humorLogs')}
            className={humorLogsTab}
          >
            Monitoramento de Humor
          </button>
          {/* <button type="button" onClick={e => this.onTabClick(e, 'goals')} className={goalsTab}>
            Metas
          </button> */}
          {/* <button
            type="button"
            onClick={e => this.onTabClick(e, 'appointments')}
            className={appointmentsTab}
          >
            Próximas consultas
          </button> */}
        </div>
        <div className={styles.tabcontent}>
          {selectedTab === 'goals' && <PatientGoals isHistoric={isHistoric} />}
          {selectedTab === 'appointments' && <PatientAppointments />}
          {selectedTab === 'humorLogs' && <HumorMonitoring isHistoric={isHistoric} />}
        </div>
      </div>
    );
  }
}

export default PatientMonitoring;
