// @flow
import { withScope, captureException, captureMessage, configureScope } from '@sentry/browser';
// export const BASE_SERVER_URL: string = 'https://dev.moodar.com.br/api/'; // stage
// export const BASE_SERVER_URL: string = 'http://localhost:8000/api/'; // stage
// export const BASE_SERVER_URL: string = 'https://webapp.moodar.com.br/api/'; // production
export const BASE_SERVER_URL: string =
  process.env.REACT_APP_BASE_SERVER_URL ?? 'https://webapp.moodar.com.br/api/';

const reportError = (error, requestBody) => {
  withScope(scope => {
    // scope.setExtras(error);
    scope.setExtra('error', error);
    scope.setExtra('requestBody', requestBody);
    scope.setLevel('error');
    const eventId = captureMessage(error.error);
  });
};

const serverErr = async (res: Object, requestBody: Object) => {
  // return Promise.reject(Error(Server status ${res.status} from ${res.url}));
  const errorJson = await res.json();
  reportError(errorJson, requestBody);

  switch (res.status) {
    case 400:
      return Promise.reject(Error(errorJson.error));
    case 500:
      return Promise.reject(Error('Ocorreu um erro no servidor!'));
    default:
      return Promise.reject(Error('Algo deu errado! Tente novamente mais tarde'));
  }
};

export const fetchServerData = async (
  path: Array<string>,
  body: Object = {},
  method: string = 'GET'
): Promise<Object> => {
  const url: string = `${BASE_SERVER_URL}${path.join('/')}`;
  const headers = new Headers();
  headers.append('samesite', 'None');
  headers.append('Accept', 'application/json');
  headers.append('Content-Type', 'application/json');

  const options: Object = {
    method,
    headers,
    credentials: 'include',
    body: method !== 'GET' ? JSON.stringify(body) : undefined
  };
  const res = await fetch(url, options);
  return res.ok ? res.json() : serverErr(res, body);
};

export const fetchServerPdfData = path => {
  const url: string = `${BASE_SERVER_URL}${path.join('/')}`;
  const headers = new Headers();
  headers.append('samesite', 'None');
  headers.append('Accept', 'application/pdf');
  const method = 'GET';
  const options: Object = {
    method,
    headers,
    credentials: 'include'
  };

  return fetch(url, options);
};
