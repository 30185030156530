import styled from 'styled-components';
import { Colors } from '../../constants/Colors';

export const StyledClients = styled.div``;

export const StyledChatContainer = styled.div`
  display: grid;
  grid-template-columns: 30vw 70vw;
  height: 100%;
  background-color: ${Colors.background};
  display: flex;

  @media (max-width: 600px) {
    grid-template-columns: 100%;
  }
`;

export const StyledApplication = styled.div`
  display: flex;
  flex-direction: column;
`;

//Content Navigation
export const StyledContentNavigation = styled.div`
  display: grid;
  height: 100vh;
`;

export const StyledContentContainer = styled.div`
  overflow-y: auto;
  background-color: #eee;
`;
