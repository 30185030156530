import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, CustomModal, ConfirmModal } from '../../reusable';
import { UserController } from '../../../controllers';
import CreateAvailableDay from './create-available/CreateAvailableDay';

import styles from './AvailableDays.module.css';
import { Colors } from '../../../constants/Colors';

const callCreateModal = (id, getAvailableDays, setModal) => (
  <CreateAvailableDay id={id} getAvailableDays={getAvailableDays} setModal={setModal} />
);

const callUpdateModal = (id, getAvailableDays, av, setModal) => (
  <CreateAvailableDay
    id={id}
    getAvailableDays={getAvailableDays}
    editing={true}
    currentTime={av}
    setModal={setModal}
  />
);

const AvailableDays = props => {
  const { getAvailableDays, availableDays, id, deleteAvailableDay } = props;
  const [modal, setModal] = useState(false);

  useEffect(() => {
    getAvailableDays(id);
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.headerTitle}>
          <p>Seus dias disponíveis</p>
        </div>
        <div className={styles.headerButton}>
          <Button
            color={'#2e9be2'}
            title={'Adicionar'}
            icon={'fas fa-plus-circle'}
            func={() => setModal(true)}
            color={'seconday'}
            backgroundColor={Colors.primary}
          ></Button>
        </div>
      </div>
      <div className={styles.content}>
        {availableDays?.map(day => (
          <DaysList
            av={day}
            id={id}
            getAvailableDays={getAvailableDays}
            deleteDay={deleteAvailableDay}
          />
        ))}
      </div>
      <CustomModal
        modal={modal}
        setModal={setModal}
        component={() => callCreateModal(id, getAvailableDays, setModal)}
      />
    </div>
  );
};

const DaysList = props => {
  const { av, id, getAvailableDays, deleteDay } = props;
  const { time_string } = av;

  const [updateModal, setUpdateModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  return (
    <div className={styles.list}>
      <div className={styles.fullDay}> {time_string}</div>
      <div className={styles.optionsList}>
        <Button
          icon={'fas fa-edit'}
          color={'#cfcf4c'}
          backgroundColor={Colors.primary}
          func={() => setUpdateModal(true)}
        />
        <Button
          icon={'fas fa-trash'}
          backgroundColor={Colors.red}
          color={'#eb5252'}
          func={() => setDeleteModal(true)}
        />
      </div>
      <CustomModal
        modal={updateModal}
        setModal={setUpdateModal}
        component={() => callUpdateModal(id, getAvailableDays, av, setUpdateModal)}
      />
      <ConfirmModal
        func={() => {
          deleteDay({ id: av.id }, id);
          setDeleteModal(false);
        }}
        title={'Deseja realmente apagar horário disponível?'}
        modal={deleteModal}
        setModal={setDeleteModal}
      />
    </div>
  );
};

const mapStateToProps = (state: Store) => {
  const {
    user: {
      profile,
      profile: { id, availableDays }
    }
  } = state;

  return { profile, id, availableDays };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    getAvailableDays: therapistId => UserController.retrieveAvailableDays(therapistId)(dispatch),
    deleteAvailableDay: (body, therapistId) =>
      UserController.deleteAvailableDay(body, therapistId)(dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AvailableDays));
