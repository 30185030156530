// @flow
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ChatActions } from '../../../../redux/actions';
import { Avatar, Button } from '../../../reusable';
import { getPlanDescription } from '../../../../utils/Utils';
import {
  StyledBoxHeader,
  StyledBoxHeaderContainer,
  StyledBoxHeaderPlan,
  StyledBoxHeaderTitle,
  StyledBoxHeaderTag,
  StyledBoxHeaderSmall
} from './Styles';
import moment from 'moment';

const ChatHeader = props => {
  const { chatInfo, clearCurrentChat, history } = props;
  const { patientName, patientThumbnail, signupDate, plan, planNameId } = chatInfo;

  const planDescription = getPlanDescription(plan, planNameId);
  const isOnMobile = window.innerWidth < 600;
  const appointmentIsFree = planDescription.includes('Lançamento Gratuito');

  const goToPatient = () =>
    chatInfo.plan === 'Paciente Antigo'
      ? history.push(`/history/${chatInfo.patientId}`)
      : history.push(`/patient/${chatInfo.patientId}`);

  const makeCall = () => history.push('/video');

  const AvatarOrGoBack = () =>
    !isOnMobile ? (
      <Avatar size="2.5em" src={patientThumbnail} name={patientName} onClick={goToPatient} />
    ) : (
      <Button
        rounded
        icon={'fas fa-arrow-circle-left'}
        func={() => clearCurrentChat()}
        color={'primary'}
      />
    );

  return (
    <StyledBoxHeader>
      <AvatarOrGoBack />
      <StyledBoxHeaderContainer onClick={goToPatient} onKeyUp={goToPatient}>
        <StyledBoxHeaderTitle>{patientName}</StyledBoxHeaderTitle>
        <StyledBoxHeaderSmall disableOnMobile>{`Cadastro na plataforma em: ${moment(
          signupDate
        ).format('DD/MM/YYYY')}`}</StyledBoxHeaderSmall>
        <StyledBoxHeaderPlan>
          <StyledBoxHeaderSmall>{getPlanDescription(plan, planNameId)}</StyledBoxHeaderSmall>
          {appointmentIsFree && (
            <StyledBoxHeaderTag>
              <StyledBoxHeaderSmall>Consulta de Lançamento Gratuita</StyledBoxHeaderSmall>
            </StyledBoxHeaderTag>
          )}
        </StyledBoxHeaderPlan>
      </StyledBoxHeaderContainer>
      <Button rounded func={() => makeCall()} icon={'fas fa-video fa'} color={'primary'} />
    </StyledBoxHeader>
  );
};

const mapStateToProps = (state: Store) => {
  const {
    chat: { currentChat }
  } = state;
  return { chatInfo: (currentChat && currentChat.info) || {} };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  clearCurrentChat: () => dispatch(ChatActions.setCurrentChat(null))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChatHeader));
