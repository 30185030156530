import styled from 'styled-components';

export const AppoCard = styled.div`
  background-color: #ddd;
  margin-bottom: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
`;

export const AppoDate = styled.div`
  text-align: left;
  display: flex;
  flex-direction: column;
`;

export const AppoStatus = styled.div`
  font-size: 20px;
  width: fit-content;
  border-radius: 5px;
  font-weight: bold;
  align-self: right;
`;

export const AppoOptions = styled.div`
  display: flex;
  flex-direction: column;
`;
