import React, { useEffect } from 'react';
import styles from './Historic.module.css';
import { connect } from 'react-redux';
import * as PatientController from '../../controllers/PatientController';
import Appointments from '../patient/appointments/Appointments';
import PatientGoals from '../patient/goals/PatientGoals';
import PatientNotes from '../patient/notes/PatientNotes';
import moment from 'moment';
import HeaderLeft from '../patient/header/left/HeaderLeft';

const setHumorValue = value =>
  value === 5
    ? 'Radiante'
    : value === 4
    ? 'Bem'
    : value === 3
    ? 'Regular'
    : value === 2
    ? 'Mal'
    : value === 1
    ? 'Horrível'
    : 'não preenchido';

export const ListOldHumor = ({ humor }) => {
  return (
    <div>
      <h3>Relatórios de Humor</h3>
      {humor &&
        humor.map((log, index) => {
          return (
            <p>
              {index + 1}. Estava {setHumorValue(log.value)} no dia{' '}
              {moment(log.date).format('DD/MM/YYYY')}
            </p>
          );
        })}
    </div>
  );
};

const Historic = props => {
  const { fetchOldParticipant, appointments, notes, goals, humor } = props;

  const convertArrayToObject = (array, key) => {
    const initialValue = {};
    return array.reduce((obj, item) => {
      return {
        ...obj,
        [item[key]]: item
      };
    }, initialValue);
  };

  useEffect(() => {
    const split = window.location.href.split('/');
    const id = split[split.length - 1];
    fetchOldParticipant(id)
      .then(res => {
        localStorage.setItem('isHistory', true);
      })
      .catch(err => {});
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <HeaderLeft isHistory={true} />

        <PatientNotes oldNotes={convertArrayToObject(notes, 'id')} isHistoric={true} />
        <Appointments oldAppointments={appointments} isHistoric={true} />
        <PatientGoals oldGoals={goals} isHistory={true} />
        <ListOldHumor humor={humor} />
      </div>
    </div>
  );
};

const mapStateToProps = (state: Store) => {
  const {
    oldPatient: {
      data,
      data: { appointments, notes, goals, humor }
    }
  } = state;

  return { oldPatient: data, appointments, notes, goals, humor };
};

const mapDispatchToProps = dispatch => ({
  fetchOldParticipant: oldPatientId => dispatch(PatientController.fetchOldPatient(oldPatientId))
});

export default connect(mapStateToProps, mapDispatchToProps)(Historic);
