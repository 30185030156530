// @flow
import * as React from "react";
import pt from "date-fns/locale/pt";
import DatePicker from "react-datepicker";

import styles from "./GoalsCreate.module.css";
import "react-datepicker/dist/react-datepicker.css";
import { Spinner } from '../../../reusable';

type Props = {
  onTitleChange: Function,
  onFrequencyChange: Function,
  onDateChange: Function,
  onSubmit: Function,
  date: Date,
  title: string,
  frequency: number,
  isCreatingGoal: boolean
};

const GoalsCreateStateless = (props: Props) => {
  const {
    date,
    title,
    frequency,
    onSubmit,
    onDateChange,
    onFrequencyChange,
    onTitleChange,
    isCreatingGoal
  } = props;

  return !isCreatingGoal ?
    (<div className={styles.container}>
    <h2>Criar Meta</h2>
      <strong>Título:</strong>
      <input
        type="text"
        value={title}
        className={styles.input}
        onChange={onTitleChange}
      />
      <div className={styles.row}>
        <div className={styles.column}>
          <strong>Data limite:</strong>
          <DatePicker
            locale={pt}
            dateFormat="dd/MM/yyyy"
            selected={date}
            onChange={onDateChange}
            className={styles.input}
          />
        </div>
        <div className={styles.column}>
          <strong>Frequência:</strong>
          <input
            className={styles.input}
            value={frequency}
            onChange={onFrequencyChange}
            type="number"
            min="1"
          />
        </div>
      </div>
      <button type="button" onClick={onSubmit} className={styles.button}>
        CRIAR META
      </button>
    </div>)
    : (<div>
        <Spinner />
        <p>Criando meta...</p>
      </div>);
};

export default GoalsCreateStateless;
