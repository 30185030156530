// @flow
import * as React from "react";
import { Editor, EditorState, ContentState, RichUtils } from "draft-js";

type Props = {
  onBlur?: Function,
  onFocus?: Function,
  onSubmit?: Function,
  onChange?: Function,
  value?: string,
  allowBlankText?: boolean,
  placeholder?: string,
  allowShiftReturn?: boolean,
  isReadOnly?: boolean,
  maxLength?: number,
  clearOnSubmit?: boolean
};

type State = {
  editorState: EditorState,
  lastContent: ?string
};

class ContentEditable extends React.Component<Props, State> {
  static defaultProps = {
    onBlur: () => {},
    onFocus: () => {},
    onSubmit: () => {},
    onChange: () => {},
    value: "",
    placeholder: "",
    allowBlankText: true,
    allowShiftReturn: false,
    isReadOnly: false,
    maxLength: Infinity,
    clearOnSubmit: false
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      editorState: EditorState.createEmpty(),
      lastContent: props.value
    };
  }

  componentDidMount() {
    this.setEditorWithLastContent();
  }

  setEditorWithLastContent = () => {
    this.setState(prevState => ({
      editorState: EditorState.createWithContent(
        ContentState.createFromText(prevState.lastContent || "")
      )
    }));
  };

  onChange = (editorState: EditorState) => {
    const { onChange } = this.props;
    this.setState({ editorState }, () => onChange && onChange(editorState));
  };

  handleShiftReturnCommand = (editorState: EditorState) => {
    this.setState({ editorState: RichUtils.insertSoftNewline(editorState) });
  };

  handleReturnCommand = (editorState: EditorState) => {
    const { allowBlankText } = this.props;
    const text: string = editorState
      .getCurrentContent()
      .getPlainText()
      .trim();

    this.setState(
      () => (text || allowBlankText ? { lastContent: text } : null),
      () => this.submit(text)
    );
  };

  handleReturn = (
    event: SyntheticKeyboardEvent<*>,
    editorState: EditorState
  ) => {
    const { allowShiftReturn } = this.props;

    if (event.shiftKey && allowShiftReturn) {
      this.handleShiftReturnCommand(editorState);
      return "not-handled";
    }
    this.handleReturnCommand(editorState);
    return "handled";
  };

  clearEditor = () => {
    const { onChange } = this.props;

    this.setState(
      {
        editorState: EditorState.createEmpty(),
        lastContent: ""
      },
      () => onChange && onChange(EditorState.createEmpty())
    );
  };

  blurEditor = () => {
    if (this.editor) this.editor.blur();
  };

  focusEditor = () =>
    this.setState(prevState => ({
      editorState: EditorState.moveFocusToEnd(prevState.editorState)
    }));

  submit = (text: string) => {
    const { onSubmit, clearOnSubmit } = this.props;

    if (onSubmit) onSubmit(text);

    if (clearOnSubmit) {
      this.clearEditor();
      this.focusEditor();
    } else {
      this.blurEditor();
    }
  };

  getEditorRef = (ref: Editor) => {
    this.editor = ref;
  };

  handleFocusEvent = () => {
    const { onFocus } = this.props;
    if (onFocus) onFocus();
  };

  handleBlurEvent = () => {
    const { onBlur } = this.props;
    if (onBlur) onBlur();
    this.setState(null, this.setEditorWithLastContent);
  };

  handleBeforeInput = (chars: string, editorState: EditorState) => {
    const { maxLength } = this.props;
    const textLength: number = editorState.getCurrentContent().getPlainText()
      .length;

    if (textLength > (maxLength || 0)) return "handled";
    return "not-handled";
  };

  editor: ?React.ElementRef<*>;

  render() {
    const { editorState } = this.state;
    const { placeholder, isReadOnly } = this.props;

    return (
      <Editor
        editorState={editorState}
        placeholder={placeholder}
        readOnly={isReadOnly}
        ref={this.getEditorRef}
        onChange={this.onChange}
        onEscape={this.blurEditor}
        onFocus={this.handleFocusEvent}
        onBlur={this.handleBlurEvent}
        handleReturn={this.handleReturn}
        handleBeforeInput={this.handleBeforeInput}
      />
    );
  }
}

export default ContentEditable;
