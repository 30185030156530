// @flow
import React, { useEffect } from 'react';
import { blackCalendar } from '../../../../assets/images/images';
import Icon from '../../icon/Icon';

import {
  StyledDatepicker,
  StyledDate,
  StyledDatepickerIcon,
  StyledDatePickerInputs
} from './Styles';

// eslint-disable-next-line react/prop-types
const StartDatePicker = ({ onChange, firstDate, setFirstDate, maxDate }) => {
  useEffect(() => {
    onChange(firstDate);
  }, [firstDate]);

  return (
    <StyledDatepicker>
      <StyledDatepickerIcon>
        <Icon icon={blackCalendar} size="sm" />
      </StyledDatepickerIcon>
      <StyledDatePickerInputs>
        <StyledDate
          value={firstDate}
          type="date"
          onChange={e => setFirstDate(e.target.value)}
          max={maxDate}
        />
      </StyledDatePickerInputs>
    </StyledDatepicker>
  );
};

export default StartDatePicker;
