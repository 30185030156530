import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import styles from './Header.module.css';

const Header = props => {
  const { profile } = props;

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.profilePic}>
          <img className={styles.img} src={profile.thumbnail} alt="Thumbnail img"></img>
          <p className={styles.profileTitle}>{profile.name}</p>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: Store) => {
  const {
    user: { profile }
  } = state;
  return { profile };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return null;
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
