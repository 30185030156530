// @flow
export const SAVE_PATIENT_PROFILE: string = 'SAVE_PATIENT_PROFILE';
export const SAVE_PATIENT_STATS: string = 'SAVE_PATIENT_STATS';
export const SAVE_PATIENT_WEIGHTS: string = 'SAVE_PATIENT_WEIGHTS';
export const SAVE_PATIENT_WORKOUTS: string = 'SAVE_PATIENT_WORKOUTS';
export const SAVE_PATIENT_MEALS: string = 'SAVE_PATIENT_MEALS';
export const SAVE_PATIENT_NOTES: string = 'SAVE_PATIENT_NOTES';
export const EDIT_PATIENT_NOTE: string = 'EDIT_PATIENT_NOTE';
export const DELETE_PATIENT_NOTE: string = 'DELETE_PATIENT_NOTE';
export const CREATE_PATIENT_NOTE: string = 'CREATE_PATIENT_NOTE';
export const SAVE_PATIENT_GOALS: string = 'SAVE_PATIENT_GOALS';
export const CREATE_PATIENT_GOAL: string = 'CREATE_PATIENT_GOAL';
export const UPDATE_PATIENT_GOAL: string = 'UPDATE_PATIENT_GOAL';
export const DELETE_PATIENT_GOAL: string = 'DELETE_PATIENT_GOAL';
export const SAVE_PATIENT_DESCRIPTION: string = 'SAVE_PATIENT_DESCRIPTION';
export const EDIT_PATIENT_DESCRIPTION: string = 'EDIT_PATIENT_DESCRIPTION';
export const CREATE_APPOINTMENT: string = 'CREATE_APPOINTMENT';
export const SAVE_PATIENT_APPOINTMENTS: string = 'SAVE_PATIENT_APPOINTMENTS';
export const SAVE_OLD_PATIENT_APPOINTMENTS: string = 'SAVE_OLD_PATIENT_APPOINTMENTS';
export const UPDATE_PATIENT_APPOINTMENT: string = 'UPDATE_PATIENT_APPOINTMENT';
export const SAVE_HUMOR_LOGS: string = 'SAVE_HUMOR_LOGS';
export const SAVE_OLD_PATIENT = 'SAVE_OLD_PATIENT';
export const CLEAR_OLD_PATIENT = 'CLEAR_OLD_PATIENT';
export const UPDATE_AFTER_CHANGE_STATUS = 'UPDATE_AFTER_CHANGE_STATUS';
