import { SideMenuTypes } from '../../types';

const initialState = {
  expanded: false,
  currentRoute: 'Mensagens'
};

export default (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case SideMenuTypes.SET_EXPANDED:
      return { ...state, expanded: !state.expanded };
    default:
      return state;
  }
};
