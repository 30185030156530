export const blackCalendar = require('./black-calendar.png');
export const calendar = require('./calendar.png');
export const cashRegister = require('./cash-register.png');
export const chat = require('./chat.png');
export const close = require('./close.png');
export const config = require('./config.png');
export const logout = require('./logout.png');
export const menuLogo = require('./menu-logo.png');
export const people = require('./people.png');
export const sandwich = require('./sandwich.png');
export const schedule = require('./schedule.png');
export const search = require('./search.png');
export const support = require('./support.png');
export const wallet = require('./wallet.png');
