// @flow
import { fetchServerData } from './utils';

export const createAvailableDays = (body, therapistId): Promise<Object> => {
  const path = ['appointments', `retrieve-therapist-available-times`];
  return fetchServerData(path, body, 'POST');
};

export const retrieveAvailableDays = (therapistId): Promise<Object> => {
  const path = ['appointments', `retrieve-therapist-available-times`];
  return fetchServerData(path, {}, 'GET');
};

export const updateAvailableDays = (body, therapistId): Promise<Object> => {
  const path = ['appointments', `retrieve-therapist-available-times`];
  return fetchServerData(path, body, 'PUT');
};

export const deleteAvailableDays = (body, therapistId): Promise<Object> => {
  const path = ['appointments', `retrieve-therapist-available-times`];
  return fetchServerData(path, body, 'DELETE');
};
