import styled, { css } from 'styled-components';
import { Colors } from '../../../constants/Colors';

export const StyledButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  justify-content: center;
  padding: 7px;
  border: none;
  color: ${Colors.third};
  border-radius: 5px;
  background-color: ${props => props.backgroundColor};
  transition: 0.2s ease-in;

  ${props =>
    props.rounded &&
    css`
      width: 50px;
      height: 50px;
      border-radius: 50%;
    `}

  ${props =>
    props.color &&
    css`
      color: ${Colors[props.color]};
    `}
`;

export const StyledIcon = styled.div`
  padding-right: 3px;
  padding-left: 3px;
  width: 25px;
`;
