import styled from 'styled-components';
import { Colors } from '../../../constants/Colors';

export const StyledTextInput = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  width: 100%;
`;

export const StyledInputDiv = styled.div`
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
  background-color: ${Colors.third};
  border-radius: 5px;
  margin: 10px;
  border: 0.5px solid #c6c6c6;
`;

export const StyledError = styled.div`
  height: 25px;
  margin: 10px;
  margin-top: 0;
  color: ${Colors.red};
  font-size: 14px;
`;

export const StyledInput = styled.input`
  border: none;
  height: 45px;
  width: ${props => (props.icon ? '96%' : '100%')};
  border-radius: 5px;
  margin-left: 10px;
  background-color: ${Colors.third};
  color: ${Colors.black};
  font-size: 18px;
  font-weight: 300;
`;

export const StyledPlaceholder = styled.label`
  display: flex;
  margin: 10px;
  color: ${Colors.black};
  font-size: 18px;
`;
