import styled from 'styled-components';

export const StyledTitle = styled.div`
  height: 40px;
  text-align: left;
  font-size: 22px;
  margin: 10px 30px;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
`;
