// @flow
import { fetchServerData } from './utils';

export const fetchProfile = (patientId: string): Promise<PatientProfile> => {
  const path: Array<string> = ['participant-page', 'profile', patientId];
  return fetchServerData(path);
};

export const fetchStats = async (patientId: string): Promise<PatientStatistics> => {
  const path: Array<string> = ['participant-page', 'statistics', patientId];
  return fetchServerData(path);
};

export const fetchWeights = (patientId: string): Promise<{ weights: Array<PatientWeight> }> => {
  const path: Array<string> = ['participant-page', 'weights', patientId];
  return fetchServerData(path);
};

export const fetchWorkouts = (
  patientId: string
): Promise<{ activities: Array<PatientWorkout> }> => {
  const path: Array<string> = ['participant-page', 'activities', patientId];
  return fetchServerData(path);
};

export const fetchMeals = (patientId: string): Promise<PatientMeals> => {
  const path: Array<string> = ['participant-page', 'meals', `${patientId}`];
  return fetchServerData(path);
};

export const fetchNotes = (patientId: string): Promise<PatientAnnotations> => {
  const path: Array<string> = ['participant-page', 'annotations', patientId];
  return fetchServerData(path);
};

export const editNote = (
  patientId: string,
  note: PatientAnnotation
): Promise<PatientAnnotation> => {
  const path: Array<string> = ['participant-page', 'annotations', patientId];
  return fetchServerData(path, note, 'PUT');
};

export const deleteNote = (patientId: string, id: number): Promise<{}> => {
  const path: Array<string> = ['participant-page', 'annotations', patientId];
  return fetchServerData(path, { id }, 'DELETE');
};

export const createNote = (
  patientId: string,
  note: { date: string, text: string }
): Promise<PatientAnnotation> => {
  const path: Array<string> = ['participant-page', 'annotations', patientId];
  return fetchServerData(path, note, 'POST');
};

export const fetchGoals = (patientId: string): Promise<PatientGoals> => {
  const path: Array<string> = ['goals', 'patient', patientId];
  return fetchServerData(path);
};

export const createGoal = (
  patientId: string,
  goal: {
    title: string,
    expire_date: string,
    frequency: number,
    encrypted: boolean
  }
): Promise<PatientGoal> => {
  const path: Array<string> = ['goals', 'patient', patientId];
  return fetchServerData(path, goal, 'POST');
};

export const updateGoal = (
  patientId: string,
  goal: {
    id: number,
    title: string,
    expireDate: string,
    frequency: number
  }
): Promise<PatientGoal> => {
  const path: Array<string> = ['goals', 'patient', patientId];
  return fetchServerData(path, goal, 'PUT');
};

export const deleteGoal = (
  patientId: string,
  goal: {
    id: number
  }
): Promise<PatientGoal> => {
  const path: Array<string> = ['goals', 'patient', patientId];
  return fetchServerData(path, goal, 'DELETE');
};

export const editDescription = (
  patientId: string,
  description: {
    description: string
  }
): Promise<Object> => {
  const path: Array<string> = ['participant-page', 'description', patientId];
  return fetchServerData(path, description, 'POST');
};

export const fetchDescription = (patientId: string): Promise<Object> => {
  const path: Array<string> = ['participant-page', 'description', patientId];
  return fetchServerData(path);
};

export const fetchHumorLogs = (patientId: string): Promise<Object> => {
  const path: Array<string> = ['monitoring', 'humor', 'retrieve-for-therapist', patientId];
  return fetchServerData(path);
};

export const fetchOldParticipant = patientId => {
  const path = ['historic', 'retrive-old-patient-id', patientId];
  return fetchServerData(path);
};
