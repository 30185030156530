import { RouterTypes } from '../types';

export const saveHistory = history => ({
  type: RouterTypes.SAVE_HISTORY,
  payload: history
});

export const setCurrentRoute = route => ({
  type: RouterTypes.SET_CURRENT_ROUTE,
  payload: route
});
