// @flow
import React from 'react';
import moment from 'moment';
import Modal from 'react-modal';
import styles from './PatientGoals.module.css';
import GoalsCreate from './create';
import { Spinner } from '../../reusable';

type Props = {
  goals: PatientGoals,
  isFetching: boolean,
  modalVisible: boolean,
  onRequestOpenModal: Function,
  onRequestCloseModal: Function
};

const PatientGoalsStateless = (props: Props) => {
  const {
    goals,
    isFetching,
    modalVisible,
    onRequestCloseModal,
    onRequestOpenModal,
    onDelete,
    isHistory
  } = props;

  const iterable: Array<PatientGoal> = (Object.values(goals): any);

  return (
    <React.Fragment>
      <div className={styles.container}>
        {!isHistory ? (
          <div className={styles.header}>
            <button type="button" onClick={onRequestOpenModal}>
              <i className="fas fa-plus" />
              ADICIONAR META
            </button>
          </div>
        ) : (
          <h3>Relatório de Metas</h3>
        )}

        {!isFetching ? (
          <div className={styles.content}>
            {iterable
              .filter(i => i.status === 'active')
              .reverse()
              .map(g => {
                const percent: number = (g.progress * 100) / g.frequency;
                return (
                  <div key={g.id} className={styles.goal}>
                    <div className={styles.left}>
                      <strong>{g.title}</strong>
                      <small>
                        Expira em:
                        {` ${moment(g.expireDate, 'YYYY-MM-DD HH:mm:ss').calendar()}`}
                      </small>
                    </div>
                    <div className={styles.right}>
                      <strong>{percent % 1 ? percent.toFixed(1) : percent}%</strong>
                      <small>
                        {g.progress} de {g.frequency}
                      </small>
                      {isHistory ? null : (
                        <button type="button" onClick={() => onDelete(g.id)}>
                          <i className="fas fa-trash" />
                        </button>
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
        ) : (
          <Spinner />
        )}
      </div>
      <Modal
        isOpen={modalVisible}
        className={styles.modal}
        onRequestClose={onRequestCloseModal}
        style={{
          content: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            overflow: 'none'
          }
        }}
      >
        <GoalsCreate closeModal={onRequestCloseModal} />
      </Modal>
    </React.Fragment>
  );
};

export default PatientGoalsStateless;
