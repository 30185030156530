// @flow
import * as React from 'react';
import { Spinner } from '../../reusable';
import NotesCard from './card';
import styles from './PatientNotes.module.css';

type Props = {
  notes: PatientAnnotations,
  onTextChange: Function,
  onSubmit: Function,
  isLoading: boolean,
  isCreating: boolean,
  text: string
};

const PatientNotesStateless = (props: Props) => {
  const { notes, onTextChange, onSubmit, isLoading, isCreating, text, isHistoric } = props;

  const iterable: Array<?PatientAnnotation> = (Object.values(notes): any);

  return (
    <React.Fragment>
      <div className={isHistoric ? null : styles.form}>
        {!isHistoric ? (
          <div>
            <h3 className={styles.title}>Insira uma nova nota sobre o participante</h3>
            <textarea
              spellCheck={false}
              rows={5}
              value={text}
              className={styles.textarea}
              onChange={onTextChange}
              placeholder="Aqui você pode escrever informações pertinentes aos participantes"
            />
            <button className={styles.button} type="submit" onClick={onSubmit}>
              ANOTAR
            </button>
          </div>
        ) : (
          <h3 className={styles.title}>Notas sobre o participante</h3>
        )}
        {!isLoading ? (
          <div className={styles.notes}>
            {isCreating && <Spinner size="2em" />}
            {iterable
              .reverse()
              .map(
                (note: ?PatientAnnotation) =>
                  note && <NotesCard key={note.id} note={note} isHistoric={isHistoric} />
              )}
          </div>
        ) : (
          <Spinner />
        )}
      </div>
    </React.Fragment>
  );
};

export default PatientNotesStateless;
