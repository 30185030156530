import React from 'react';
import { Fragment } from 'react';
import { connect } from 'react-redux';
import { Icon } from '../reusable';
import { RouterActions, SideMenuActions } from '../../redux/actions';
import { BASE_SERVER_URL } from '../../server/utils';
import * as AuthController from '../../controllers/AuthController';
import {
  chat,
  calendar,
  cashRegister,
  menuLogo,
  people,
  schedule,
  wallet,
  config,
  close,
  logout
} from '../../assets/images/images';
import { StyledAppContainer, StyledSideMenu, StyledApp } from './Styles';
import { SideBarOption, SideBarOptionIcon, SideBarOptionText, OptionsSections } from './Styles';

const Option = ({ icon, text, path, onClick, currentRoute, disableHover, expanded }) => (
  <SideBarOption disableHover={disableHover} onClick={onClick} selected={currentRoute === text}>
    <SideBarOptionIcon>
      <Icon icon={icon} />
    </SideBarOptionIcon>
    <SideBarOptionText>{text}</SideBarOptionText>
  </SideBarOption>
);

const SideMenu = props => {
  const { history, saveCurrentRoute, currentRoute, isAuthenticated, profile, signOut } = props;
  const { expanded, setExpanded } = props;

  const ChildrenAppContainer = React.Children.toArray(props.children)[0];
  const hasCalendar = profile && profile.hasCalendarAssociated;

  const activateGoogleCalendar = () => {
    window.open(`${BASE_SERVER_URL}integrations/google-calendar`, '_blank');
  };

  // const goToGoogleCalendar = () => {
  //   window.open('${BASE_SERVER_URL}integrations/google-calendar', '_blank');
  // };

  const goToWhatsapp = () => {
    window.open('https://api.whatsapp.com/send?phone=5581991067299', '_blank');
  };

  const goToRoute = (path, route) => {
    if (route === 'Sair') signOut().then(() => history.push('/'));
    if (route === 'Suporte') goToWhatsapp();
    if (route === 'Agenda') {
      window.open('https://calendar.google.com/calendar/', '_blank');
    } else {
      saveCurrentRoute(route);
      history.push(path);
    }
  };

  const AppRoutes = [
    { icon: people, route: 'Clientes', path: '/clientes' },
    { icon: chat, route: 'Mensagens', path: '/chat' },
    { icon: schedule, route: 'Consultas', path: '/consultas' },
    { icon: calendar, route: 'Agenda', path: '/agenda' },
    { icon: wallet, route: 'Carteira', path: '/carteira' },
    { icon: cashRegister, route: 'Recebimentos', path: '/payments' }
  ];

  const SettingsRoutes = [
    { icon: config, route: 'Configurações', path: '/configuracoes' },
    { icon: logout, route: 'Sair', path: '/logout' }
  ];

  const unloggedSettings = [{ icon: logout, route: 'Sair', path: '/logout' }];

  return (
    <StyledAppContainer>
      {isAuthenticated ? (
        <StyledSideMenu expanded={expanded}>
          {hasCalendar ? (
            <Fragment>
              <OptionsSections>
                {expanded ? (
                  <Option icon={close} text={'Fechar menu'} onClick={() => setExpanded()} />
                ) : (
                  <Option disableHover={true} icon={menuLogo} text={'Moodar'} />
                )}
              </OptionsSections>

              <OptionsSections>
                {AppRoutes.map((item, key) => (
                  <Option
                    icon={item.icon}
                    text={item.route}
                    path={item.path}
                    key={key}
                    onClick={() => goToRoute(item.path, item.route)}
                    currentRoute={currentRoute}
                  />
                ))}
              </OptionsSections>

              <OptionsSections>
                {SettingsRoutes.map((item, key) => (
                  <Option
                    icon={item.icon}
                    text={item.route}
                    onClick={() => goToRoute(item.path, item.route)}
                    key={key}
                  />
                ))}
              </OptionsSections>
            </Fragment>
          ) : (
            <Fragment>
              <OptionsSections>
                <Option
                  icon={calendar}
                  text={'Ativar Calendar'}
                  onClick={() => activateGoogleCalendar()}
                />
              </OptionsSections>
              <OptionsSections>
                {unloggedSettings.map((item, key) => (
                  <Option
                    icon={item.icon}
                    text={item.route}
                    onClick={() => goToRoute(item.path, item.route)}
                    key={`calendar_${key}`}
                  />
                ))}
              </OptionsSections>
            </Fragment>
          )}
        </StyledSideMenu>
      ) : null}

      <StyledApp expanded={expanded}>{ChildrenAppContainer}</StyledApp>
    </StyledAppContainer>
  );
};

const mapStateToProps = state => {
  const {
    auth: {
      firebase: { isAuthenticated: firebaseAuth },
      security: { setUpSuccess: securityAuth },
      server: { isAuthenticated: serverAuth }
    },
    router: { currentRoute, history },
    sideMenu: { expanded },
    user: { profile }
  } = state;

  const isAuthenticated = firebaseAuth && securityAuth && serverAuth;

  return { currentRoute, expanded, history, isAuthenticated, profile };
};

const mapDispatchToProps = dispatch => ({
  saveCurrentRoute: route => dispatch(RouterActions.setCurrentRoute(route)),
  setExpanded: () => dispatch(SideMenuActions.setSideMenuExpanded()),
  signOut: () => dispatch(AuthController.logOut())
});

export default connect(mapStateToProps, mapDispatchToProps)(SideMenu);
