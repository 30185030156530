// @flow
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styles from './Appointments.module.css';
import { Spinner, CustomModal, ConfirmModal, Button, Tab } from '../../reusable';
import Reschedule from './reschedule/Reschedule';
import AppointmentsCard from '../../reusable/cards/AppointmentCard/AppointmentsCard';

const AppointmentsStateless = (props: Props) => {
  const { appointments, oldAppointments, isFetching, changeStatus, isHistoric } = props;

  const [modal, setModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [currentAppo, setCurrentAppo] = useState(undefined);

  const rescheduleModal = appointment => (
    <Reschedule appointment={appointment} setModal={setModal} filter={''} />
  );

  const setStatus = status => (status === 'successful' ? 'confirmed' : 'successful');

  return (
    <React.Fragment>
      <div className={styles.container}>
        {isFetching ? (
          <Spinner />
        ) : (
          <Tab defaultIndex={0}>
            <Tab.Item label="Próximas Consultas" index={0} data={appointments}>
              {appointments?.map(appo => (
                <AppointmentsCard
                  appointment={appo}
                  isHistoric={isHistoric}
                  setCurrentAppo={setCurrentAppo}
                  setConfirmModal={setConfirmModal}
                  setModal={setModal}
                />
              ))}
            </Tab.Item>
            <Tab.Item label="Consultas Passadas" index={1} data={oldAppointments}>
              {oldAppointments?.map(appo => (
                <AppointmentsCard
                  appointment={appo}
                  isHistoric={isHistoric}
                  setCurrentAppo={setCurrentAppo}
                  setConfirmModal={setConfirmModal}
                  setModal={setModal}
                />
              ))}
            </Tab.Item>
          </Tab>
        )}
      </div>

      <CustomModal
        component={() => rescheduleModal(currentAppo)}
        modal={modal}
        setModal={setModal}
      />

      <ConfirmModal
        func={() => {
          changeStatus(currentAppo.id, setStatus(currentAppo.status));
          setConfirmModal(false);
        }}
        title={'Confirmar consulta como realizada?'}
        modal={confirmModal}
        setModal={setConfirmModal}
      />
    </React.Fragment>
  );
};

export default AppointmentsStateless;
