import styled, { css } from 'styled-components';
import { Colors } from '../../../../constants/Colors';

//Box Header
export const StyledBoxHeader = styled.div`
  display: flex;
  padding: 1em;
  background-color: ${Colors.third};
  border-bottom: 1px solid #ddd;
  -webkit-box-shadow: 1px 8px 20px -20px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 1px 8px 20px -20px rgba(0, 0, 0, 0.75);
  box-shadow: 1px 8px 20px -20px rgba(0, 0, 0, 0.75);
`;

export const StyledBoxHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  margin-left: 10px;
`;

export const StyledBoxHeaderPlan = styled.div`
  display: flex;
`;

export const StyledBoxHeaderTag = styled.strong`
  background-color: green;
  margin-left: 0px;
  color: white;
  padding: 2px;
  border-radius: 10px;
  text-align: center;
`;

export const StyledBoxHeaderTitle = styled.strong``;

export const StyledBoxHeaderSmall = styled.small`
  ${props =>
    props.disableOnMobile &&
    css`
      @media (max-width: 600px) {
        display: none;
      }
    `}
`;
