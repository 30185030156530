import styled, { css } from 'styled-components';

export const Bubble = styled.div`
  display: flex;
  float: ${props => props.side};

  ${props =>
    props.side === 'left' &&
    css`
      flex-direction: row-reverse;
      margin-left: 8%;
    `}

  ${props =>
    props.side === 'right' &&
    css`
      margin-right: 8%;
    `}
`;

export const BubbleDetail = styled.div`
  display: flex;
  height: 8px;
  width: 8px;
  margin: 2px 0px;
  background-color: ${props => props.color};

  ${props =>
    props.side === 'left' &&
    css`
      clip-path: polygon(0 0, 100% 0, 100% 100%, 50% 50%);
    `}

  ${props =>
    props.side === 'right' &&
    css`
      clip-path: polygon(0 0, 100% 0, 50% 50%, 0 100%);
    `}
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5em;
  max-width: 70ch;
  float: ${props => props.side};
  background-color: ${props => props.color};
  font-family: 'Open Sans', sans-serif;
  margin: 2px 0px;
  border-radius: 3px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;

  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
  display: flex;

  ${props =>
    props.side === 'left' &&
    css`
      border-top-left-radius: 0;
    `}

  ${props =>
    props.side === 'right' &&
    css`
      border-top-right-radius: 0;
    `}
`;

export const Message = styled.p`
  margin: 0;
`;

export const Image = styled.div`
  width: 35ch;
  height: ${props => `calc(${props.ratio} * 35ch)`};
  background: url(${props => props.src}) no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 12px;
  padding-top: 5px;
  color: ${props => props.color};
`;

export const Time = styled.small`
  font-style: italic;
`;

export const Tick = styled.i`
  margin-left: 5px;
`;
