import styled, { css } from 'styled-components';
import { Colors } from '../../constants/Colors';
import { StyledIconImage } from '../reusable/icon/Styles';

export const StyledAppContainer = styled.div`
  width: 100vw;
  height: 100%;

  @media (max-width: 600px) {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
  }
`;

export const StyledApp = styled.div`
  height: 100%;
  ${'' /* width: 100%; */}
  margin-left: 65px;
  display: flex;

  @media (max-width: 600px) {
    margin-left: unset;
    width: 100%;

    ${props =>
      props.expanded &&
      css`
        filter: brightness(70%);
        pointer-events: none;
      `}
  }
`;

export const StyledSideMenu = styled.div`
  width: ${props => (props.expanded ? '200px' : '65px')};
  height: 100%;
  transition: 0.1s ease-in;
  cursor: pointer;
  position: fixed;
  background: ${Colors.primary};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 9;

  @media (min-width: 600px) {
    &:hover {
      width: 222px;
    }
  }

  @media (max-width: 600px) {
    width: ${props => (props.expanded ? '222px' : '0px')};
    z-index: 2;
  }
`;

//Sidebar Option Button Styles
export const OptionsSections = styled.div``;

export const SideBarOptionText = styled.div`
  width: 100%;
  display: grid;
  place-items: center;
  color: ${Colors.fourth};
  font-size: 14px;
`;

export const SideBarOption = styled.div`
  padding: 15px;
  display: flex;
  overflow: hidden;

  ${props =>
    props.selected &&
    css`
      background-color: ${Colors.fifth};

      ${SideBarOptionText} {
        color: ${Colors.blue};
      }

      ${StyledIconImage} {
        filter: invert(63%) sepia(40%) saturate(1568%) hue-rotate(161deg) brightness(98%)
          contrast(105%);
      }
    `}

  ${props =>
    !props.disableHover &&
    css`
      &:hover {
        background-color: ${Colors.fifth};

        ${SideBarOptionText} {
          color: ${Colors.blue};
        }

        ${StyledIconImage} {
          filter: invert(63%) sepia(40%) saturate(1568%) hue-rotate(161deg) brightness(98%)
            contrast(105%);
        }
      }
    `}
`;

export const SideBarOptionIcon = styled.div`
  margin-right: 20px;
`;
