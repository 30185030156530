import styled from 'styled-components';

export const StyledSideMenu = styled.div``;

export const StyledAppContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: flex-start;

  @media (max-width: 600px) {
    flex-direction: row-reverse;
  }
`;

export const StyledApplication = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;
