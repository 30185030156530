import { UserServer } from '../server';
import { UserActions } from '../redux/actions';

export const retrieveAvailableDays = therapistId => async dispatch => {
  try {
    dispatch(UserActions.updateUserProfile(await UserServer.retrieveAvailableDays(therapistId)));
  } catch (err) {
    throw err;
  }
};

export const createAvailableDays = (body, therapistId) => async dispatch => {
  try {
    await UserServer.createAvailableDays(body, therapistId);
    retrieveAvailableDays(therapistId)(dispatch);
  } catch (err) {
    throw err;
  }
};

export const updateAvailableDays = (body, therapistId) => async dispatch => {
  try {
    await UserServer.updateAvailableDays(body, therapistId);
    retrieveAvailableDays(therapistId)(dispatch);
  } catch (err) {
    throw err;
  }
};

export const deleteAvailableDay = (body, therapistId) => async dispatch => {
  try {
    await UserServer.deleteAvailableDays(body, therapistId);
    retrieveAvailableDays(therapistId)(dispatch);
  } catch (err) {
    throw err;
  }
};
