// @flow
import { withScope, captureMessage, configureScope, captureException } from '@sentry/browser';
import * as Sentry from '@sentry/browser';
// import { functions } from 'firebase';
import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';
import { FirebaseAuth } from '../firebase';
import { AppointmentServer, AuthServer } from '../server';
import { AuthActions, UserActions, ChatActions, AppointmentsActions } from '../redux/actions';
import { setUpSecurity } from './SecurityController';

const reportError = (error, credentials, errorMessage, code = null) => {
  withScope(scope => {
    // scope.setExtras(error);
    scope.setExtra('error', error);
    scope.setExtra('credentials', credentials);
    scope.setExtra('errorMessage', errorMessage);
    code && scope.setExtra('authErrorCode', code);
    scope.setLevel('error');
    // const eventId = captureMessage(error);
  });
};

const getParsedProfile = (userProfile: Object): UserProfile => {
  const parsedProfile = {
    ...userProfile,
    hasCalendarAssociated: userProfile.has_calendar_associated
  };
  const { has_calendar_associated, ...finalProfile } = parsedProfile;
  return finalProfile;
};

export const logIn = (credentials: ServerCredentials, username: string) => (dispatch: Dispatch) =>
  AuthServer.logIn(credentials, username)
    .then((userProfile: UserProfile) => {
      Sentry.setUser({ username: userProfile?.name, id: userProfile?.id });
      dispatch(AuthActions.setLoginServerSuccess());
      return FirebaseAuth.logIn(credentials)
        .then((firebaseAuth: Object) => {
          const { emailVerified, uid } = firebaseAuth.user;
          dispatch(AuthActions.saveFirebaseAuthData({ emailVerified, uid }));
          dispatch(AuthActions.setLoginFirebaseSuccess());
          const parsedProfile = getParsedProfile(userProfile);
          dispatch(UserActions.saveUserProfile(parsedProfile));
          localStorage.setItem('user', JSON.stringify(userProfile));
          // Retorno o profile que vai botar no redux para pegar
          // o uid do terapeuta e configurar o Virgil SEcurity
          // settingUpSecurity(dispatch, uid);
          return parsedProfile;
        })
        .catch(err => {
          let errorMessage;
          // if we cant parse then show the whole error
          try {
            errorMessage = JSON.parse(err.message).error.message;
          } catch (e) {
            errorMessage = err;
          }
          reportError(err, credentials, errorMessage, err.code);
          dispatch(AuthActions.setLoginFirebaseFailed());
          throw new Error(errorMessage);
        });
    })
    .catch(err => {
      reportError(err, credentials, err.message);
      dispatch(AuthActions.setLoginServerFailed(err.message));
      throw err;
    });

// export const logOut = () => (dispatch: Dispatch) =>
//   AuthServer.logOut().finally(() => dispatch(AuthActions.setLoginServerFailed()));

export const logOut = () => (dispatch: Dispatch) =>
  AuthServer.logOut().then(resp1 => {
    dispatch(AuthActions.setLogoutServerSuccess());
    dispatch(AuthActions.setLogoutFirebaseSuccess());

    // Limpando as outras partes do redux
    dispatch(ChatActions.clearChatsInfo());
    dispatch(UserActions.clearUserProfile());
    dispatch(AppointmentsActions.clearUpcomingAppointments());
    // Limpando as infos do chat atual
    dispatch(ChatActions.setCurrentChat(undefined));
    dispatch(ChatActions.setMessages({}));
  });

export const refreshAuthenticatedFlag = () => (dispatch: Dispatch) =>
  Promise.all([
    AuthServer.isAuthenticated()
      .then((profile: Object) => {
        const parsedProfile: UserProfile = getParsedProfile(profile);
        configureScope(scope => {
          scope.setUser({ name: parsedProfile.name });
          scope.setExtra({ therapistUid: parsedProfile.uid });
        });
        dispatch(UserActions.saveUserProfile(parsedProfile));
        dispatch(AuthActions.setLoginServerSuccess());
      })
      .catch(error => {
        reportError(error, null, 'Error no server login');
        dispatch(AuthActions.setLoginServerFailed());
      }),
    FirebaseAuth.isAuthenticated()
      .then((uid: string) => {
        dispatch(AuthActions.setLoginFirebaseSuccess(uid));
        return uid;
      })
      .catch(error => {
        reportError(error, null, 'Erro no firebase.auth().onAuthStateChanged');
        dispatch(AuthActions.setLoginFirebaseFailed());
      })
  ]).then(async resp => {
    const therapistUid = resp[1];
    localStorage.setItem('uidT', therapistUid);
    if (therapistUid) {
      try {
        await setUpSecurity(therapistUid);
        dispatch(AuthActions.setUpSecuritySuccess());
      } catch (error) {
        reportError(error, null, 'Erro no setUpSecurity no refreshAuthenticatedFlag');
        dispatch(AuthActions.setUpSecurityFailed());
      }
    } else {
      dispatch(AuthActions.setUpSecurityFailed());
    }
  });

export const getVirgilJwtToken = async therapistUid => {
  try {
    try {
      const getToken = firebase.functions().httpsCallable('getVirgilJwt');
      return (await getToken()).data.token;
    } catch (err) {
      return await AuthServer.getVirgilJwt(therapistUid);
    }
  } catch (err) {
    captureException(err);
  }
};

export const checkUpdatedDevice = async () => {
  try {
    const isUpdated = await AuthServer.checkUpdatedDevice();
    return isUpdated;
  } catch (err) {
    throw err;
  }
};

export const automaticallyUpdateAppointmentsStatus = () => {
  return AppointmentServer.automaticallyChangeAppointmentStatus()
    .then(() => {})
    .catch(err => {});
};
