import React, { useEffect, useRef, useState } from 'react';
import * as Sentry from '@sentry/browser';
import { connect } from 'react-redux';
import VideoController from '../../controllers/VideoController';
import styles from './Video.module.css';
import { checkBrowser } from '../../utils/Utils';
import { TooltipIcon } from '../reusable/tooltip/Tooltip';
import { RouterActions } from '../../redux/actions';

const VideoScreen = props => {
  let timeout = null;
  let count = 0;
  const [media] = useState({ local: undefined, remote: undefined });
  const [room, setRoom] = useState(null);
  const [errorToast] = useState(undefined);
  const [toastMessage, setToastMessage] = useState('');
  const [, setToastEnabled] = useState(false);
  const [videoEnabled, setVideoEnabled] = useState(false);
  const [, setPacketLoss] = useState(0);
  // const [noConnection, setNoConnection] = useState(0);
  const [stats, setStats] = useState(0);
  const [idInterval, setIdInterval] = useState(0);
  const [lastPacketsAmmount, setLastPacketsAmmount] = useState(0);
  const [lastPacketsSent, setLastPacketsSent] = useState(0);
  const [icon, setIcon] = useState('mdi mdi-signal-cellular-3');
  // const [feedbackInterval, setFeedbackInterval] = useState(0);

  // eslint-disable-next-line react/prop-types
  const { patient, therapist, roomName, history, saveHistoryOnRedux, setCurrentNav } = props;

  const videoOptions = { therapist, patient, setToastEnabled, actualRoom: room };

  const checkVideoStats = () => {
    if (stats) {
      let packetLost = stats?.remoteVideoTrackStats[0]?.packetsLost;
      let packetsReceived = stats?.remoteVideoTrackStats[0]?.packetsReceived;
      let packetsSent = stats?.localVideoTrackStats[0].packetsSent;
      let differencePackets = packetsReceived - lastPacketsAmmount;
      let differenceSentPackets = packetsSent - lastPacketsSent;

      const qualityCheckerValues = {
        packetLost,
        differencePackets,
        differenceSentPackets,
        videoEnabled
      };

      setLastPacketsAmmount(packetsReceived);
      setLastPacketsSent(packetsSent);

      VideoController.qualityChecker(qualityCheckerValues).then(res => {
        if (res) {
          const { toastEnabled, toastMessage, packetLost, icon } = res;
          setToastEnabled(toastEnabled);
          setToastMessage(toastMessage || '');
          setPacketLoss(packetLost);
          setIcon(icon);
        }
      });
    }
  };

  const checkInterval = (room, videoEnabled) => {
    if (room) {
      const interval = setInterval(async () => {
        let stats = Object.values(await room?.getStats())[0];
        setStats(stats);
        checkVideoStats(videoEnabled);
      }, 2000);
      setIdInterval(interval);
      return interval;
    }
  };

  const connect = () => {
    VideoController.initiateVideoCall(videoOptions, media)
      .then(async room => {
        checkInterval(room, videoEnabled);
        setRoom(room);
      })
      .catch(error => {
        Sentry.withScope(scope => {
          scope.setExtra('patient', patient);
          scope.setExtra('therapist', therapist);
          Sentry.captureException(error);
        });
      });
  };

  const toogleVideo = () => {
    VideoController.setVideo(videoEnabled);
    setVideoEnabled(!videoEnabled);
  };

  const disconnect = () => {
    clearInterval(idInterval);
    VideoController.disconnectVideoCall(room, { therapist, patient });
  };

  const requestDisconnect = () => {
    if (window.confirm('Você deseja realmente encerrar a consulta?')) {
      disconnect();
      history.push({ pathname: '/chat', state: { route: 'video' } });
    }
  };

  const disconnectBeforeClose = () => {
    window.addEventListener('beforeunload', ev => {
      ev.preventDefault();
      return (ev.returnValue = 'custom message');
    });

    window.addEventListener('unload', ev => {
      ev.preventDefault();
      return disconnect();
    });
  };

  const handleConnectionIcon = stats =>
    ({
      wifi1: 'mdi mdi-signal-cellular-1',
      wifi2: 'mdi mdi-signal-cellular-2',
      wifi3: 'mdi mdi-signal-cellular-3',
      wifi0: 'mdi mdi-signal-cellular-outline'
    }[stats]);

  const handleVideoIcon = () => (videoEnabled ? `mdi mdi-video` : 'mdi mdi-video-off');

  useEffect(() => {
    localStorage.setItem('patient', patient);
    localStorage.setItem('therapist', therapist);
    localStorage.setItem('roomName', roomName);

    saveHistoryOnRedux(history);
    setCurrentNav('Chamada de vídeo');

    timeout = setInterval(() => {
      // eslint-disable-next-line no-plusplus
      count++;
      localStorage.setItem('feedback', count);
    }, 1000);

    checkBrowser();
    connect();
    disconnectBeforeClose();
  }, []);

  const useComponentWillMount = func => {
    const willMount = useRef(true);
    if (willMount.current) func();
    willMount.current = false;
  };

  useComponentWillMount(() => {
    disconnect();
    clearInterval(timeout);
    clearInterval();
  });

  const getLocalMediaRef = ref => (media.local = ref);
  const getRemoteMediaRef = ref => (media.remote = ref);

  return (
    <div className={styles.container}>
      {errorToast !== undefined ? <div className={styles.errorDiv}>{errorToast}</div> : null}
      <div className={styles.remoteMedia} id="remoteMedia" ref={getRemoteMediaRef} />
      <div className={styles.control}>
        <div className={styles.buttons}>
          {toastMessage !== '' ? <div className={styles.toastDiv}>{toastMessage}</div> : null}
          <div className={styles.connection}>
            <TooltipIcon
              title={'Qualidade da sua conexão'}
              icon={handleConnectionIcon(icon) || icon}
            />
            <span
              className={`mdi mdi-phone ${styles.disconnect}`}
              onClick={() => requestDisconnect()}
            />
            <TooltipIcon
              title={'Ativar/Destivar câmera'}
              icon={handleVideoIcon()}
              clickFunction={() => toogleVideo()}
            />
          </div>
        </div>

        <div className={styles.localMedia} id="localMedia" ref={getLocalMediaRef} />
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  const {
    user: { profile },
    chat: {
      currentChat: { info }
    }
  } = state;

  const therapist =
    profile && profile.id ? profile && profile.id : localStorage.getItem('therapist');
  const patient = info && info.patientId ? info && info.patientId : localStorage.getItem('patient');
  const roomName = info && info.id ? info && info.id : localStorage.getItem('roomName');

  return {
    therapist,
    patient,
    roomName
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setCurrentNav: current => dispatch(RouterActions.setCurrentRoute(current)),
    saveHistoryOnRedux: history => dispatch(RouterActions.saveHistory(history))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoScreen);
