import styled from 'styled-components';
import { Colors } from '../../constants/Colors';

export const StyledPayments = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  flex-direction: column;
  padding: 25px;
  background-color: ${Colors.background};
`;

export const StyledPaymentsTable = styled.div`
  padding: 15px;
  margin-top: 20px;
  width: 100%;
`;
