import styled from 'styled-components';
import { Colors } from '../../../../constants/Colors';

export const StyledDatepicker = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border-radius: 5px;
  background-color: ${Colors.third};
  border: 0.5px solid #c6c6c6;
  height: 47px;
  margin: 10px;
`;

export const StyledDatepickerIcon = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  padding: 0 25px 0 20px;
`;

export const StyledDatePickerInputs = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  flex-direction: row;
  jusitfy-content: center;
`;

export const StyledDate = styled.input`
  display: flex;
  align-items: center;
  flex-grow: 1;
  margin: 0 15px;
  border: none;
`;
