// @flow
import { AuthTypes } from '../../types';

const initialState = {
  setUpSuccess: false
};

export default (state = initialState, action) => {
  const { type } = action;

  switch (type) {
    case AuthTypes.SECURITY_SETUP_SUCCESS:
      return {
        ...state,
        setUpSuccess: true
      };
    case AuthTypes.SECURITY_SETUP_FAILED:
      return {
        ...state,
        setUpSuccess: false
      };
    default:
      return state;
  }
};
