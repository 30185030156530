import { combineReducers } from 'redux';

import PaymentAppointmentsReducer from './PaymentAppointmentsReducer';
import PaymentCoparticipationReducer from './PaymentCoparticipationReducer';
import PaymenteSubscriptionsReducer from './PaymenteSubscriptionsReducer';
import PaymentAccountInfoReducer from './PaymentAccountInfoReducer';
import PaymentCalendarReducer from './PaymentCalendarReducer';
import PaymentAmountReducer from './PaymentAmountReducer';
import PaymentAccountWithdraw from './PaymentAccountWithdraw';
import PaymentsFinancialReducer from './PaymentsFinancialReducer';

export default combineReducers({
  invoicesInfo: PaymentAppointmentsReducer,
  coparticipationsInfo: PaymentCoparticipationReducer,
  subscriptionsInfo: PaymenteSubscriptionsReducer,
  account: PaymentAccountInfoReducer,
  month: PaymentCalendarReducer,
  amount: PaymentAmountReducer,
  withdraw: PaymentAccountWithdraw,
  financial: PaymentsFinancialReducer
});
