import { combineReducers } from 'redux';

import PatientReducer from './patient';
import UserReducer from './user';
import AuthReducer from './auth';
import ChatReducer from './chat';
import PaymentReducer from './payment';
import AppointmentsReducer from './AppointmentsReducer';
import OldPatientReducer from './oldPatient/OldPatientReducer';
import RouterReducer from './router/RouterReducer';
import SideMenuReducer from './sideMenu/SideMenuReducer';

export default combineReducers({
  patient: PatientReducer,
  user: UserReducer,
  auth: AuthReducer,
  chat: ChatReducer,
  payment: PaymentReducer,
  appointments: AppointmentsReducer,
  oldPatient: OldPatientReducer,
  router: RouterReducer,
  sideMenu: SideMenuReducer
});
