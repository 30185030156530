// @flow
import * as React from "react";
import HeaderLeft from "./left";
import HeaderRight from "./right";
import styles from "./PatientHeader.module.css";

const PatientHeader = () => {
  return (
    <div className={styles.container}>
      <div className={styles.left}>
        <HeaderLeft />
      </div>
      <div className={styles.verticalLine} />
      <div className={styles.right}>
        <HeaderRight />
      </div>
    </div>
  );
};

export default PatientHeader;
