// @flow
import * as React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Avatar } from '../../../reusable';
import {
  Container,
  Body,
  Title,
  SubTitle,
  Badge,
  InsideBody,
  NewUserTag
} from './ListContact.styled';
import { ContactListDate } from '../../../../utils/Utils';

type Props = {
  chatInfo: ChatInfo,
  onClick?: Function
};

type OwnProps = {
  selectedId: ?string
};

class ListContact extends React.Component<Props & OwnProps> {
  static defaultProps = {
    onClick: () => {}
  };

  handleClick = (event: SyntheticMouseEvent<HTMLDivElement>) => {
    const { onClick, chatInfo } = this.props;
    event.preventDefault();
    if (onClick) onClick(chatInfo.id);
  };

  isNewUser = (signupDate: string) => {
    const signup = moment(signupDate);
    const day_difference = moment().diff(signup, 'days');
    // Se for ate uma semana a partir da data de cadastro dizemos que eh novo
    return day_difference < 8;
  };

  render() {
    const {
      selectedId,
      chatInfo: { patientName, patientThumbnail, viewedAt, id, unreadMessages, signupDate }
    } = this.props;

    // const isOnMobile = window.innerWidth < 600;
    return (
      <Container type="button" active={id === selectedId} onClick={this.handleClick}>
        {<Avatar size="3em" src={patientThumbnail} name={patientName} />}
        <Body>
          {this.isNewUser(signupDate) ? (
            <InsideBody>
              <Title>{patientName}</Title>
              <NewUserTag>
                <SubTitle>{'Novo'}</SubTitle>
              </NewUserTag>
            </InsideBody>
          ) : (
            <Title>{patientName}</Title>
          )}
          {/* <SubTitle>{moment(viewedAt).calendar()}</SubTitle> */}
          <SubTitle>{ContactListDate(viewedAt)}</SubTitle>
        </Body>
        {unreadMessages && <Badge className="fas fa-comment" />}
      </Container>
    );
  }
}

const mapStateToProps = (state: Store) => {
  const {
    chat: { currentChat }
  } = state;

  return { selectedId: currentChat.info && currentChat.info.id };
};

export default connect(mapStateToProps)(ListContact);
