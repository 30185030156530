// @flow
import { UserTypes } from '../../types';

const initialState: UserProfile = {
  thumbnail: undefined,
  name: undefined,
  email: undefined,
  id: undefined,
  hasCalendarAssociated: undefined
};

export default (state: UserProfile = initialState, action: Action): UserProfile => {
  const { type, payload } = action;
  switch (type) {
    case UserTypes.SAVE_USER_PROFILE:
      return { ...state, ...payload };
    case UserTypes.CLEAR_USER_PROFILE:
      return { ...payload };
    case UserTypes.UPDATE_USER_PROFILE:
      const availableDays = payload;
      return { ...state, availableDays };
    default:
      return state;
  }
};
