// @flow
import { PatientTypes } from '../../types';

const initialState = {
  oldAppointments: [],
  appointments: []
};

const sortAppointments = appointments => {
  return appointments.sort((a, b) => (+(a.schedule < b.schedule) > 0 ? -1 : 1));
};

export default (state = initialState, action: Action): PatientAppointments => {
  const { type, payload } = action;
  switch (type) {
    case PatientTypes.CREATE_APPOINTMENT: {
      const newState = sortAppointments([...state, payload]);
      return newState;
    }
    case PatientTypes.SAVE_PATIENT_APPOINTMENTS:
      return { ...state, appointments: sortAppointments(payload) };
    case PatientTypes.SAVE_OLD_PATIENT_APPOINTMENTS:
      return { ...state, oldAppointments: sortAppointments(payload) };
    case PatientTypes.UPDATE_AFTER_CHANGE_STATUS:
      return { ...state, appointments: payload[0], oldAppointments: payload[1] };
    default:
      return state;
  }
};
