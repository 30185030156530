import { combineReducers } from 'redux';

import PatientProfileReducer from './PatientProfileReducer';
import PatientStatsReducer from './PatientStatsReducer';
import PatientNotesReducer from './PatientNotesReducer';
import PatientGoalsReducer from './PatientGoalsReducer';
import PatientDesciptionReducer from './PatientDescriptionReducer';
import PatientAppointmentsReducer from './PatientAppointmentsReducer';
import PatientHumorLogsReducer from './PatientHumorLogsReducer';

export default combineReducers({
  profile: PatientProfileReducer,
  statistics: PatientStatsReducer,
  notes: PatientNotesReducer,
  goals: PatientGoalsReducer,
  description: PatientDesciptionReducer,
  appointments: PatientAppointmentsReducer,
  humorLogs: PatientHumorLogsReducer
});
