// @flow
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { BsSearch, BsEmojiFrown } from 'react-icons/bs';
import { ChatController } from '../../../controllers';

import ListContact from './contact';
import styles from './ChatList.module.css';

const ChatList = props => {
  const { selectCurrentChat, clearUnreadMessagesBadge, currentChatId, chats } = props;
  const values: Array<ChatInfo> = (Object.values(chats): any);

  const [chatArray, setChatArray] = useState([]);

  const isOnMobile = window.innerWidth < 600;
  const selectedChatAndIsOnMobile = isOnMobile && currentChatId;

  const onItemClick = (chatId: string) => {
    selectCurrentChat(chatId);
  };

  useEffect(() => {
    window.addEventListener('unload', () => {
      clearUnreadMessagesBadge(currentChatId);
    });
    setChatArray(values);
    return clearUnreadMessagesBadge(currentChatId);
  }, []);

  const handleSearch = name => {
    const filter = values.filter(patient =>
      patient.patientName.toLowerCase().includes(name.toLowerCase())
    );
    setChatArray(filter);
  };

  return selectedChatAndIsOnMobile ? null : (
    <div className={styles.container}>
      <div className={styles.filter}>
        <input
          type="text"
          placeholder="Buscar paciente..."
          className={styles.busca}
          onChange={e => handleSearch(e.target.value)}
        />
        <BsSearch size={20} />
      </div>
      {chatArray.length > 0 ? (
        chatArray.map(value => (
          <ListContact key={value.id} chatInfo={value} onClick={onItemClick} />
        ))
      ) : (
        // Atualizar css para essa view
        <div className={styles.notFound}>
          Não existe paciente com esse nome
          <p>
            <BsEmojiFrown size={18} />
          </p>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: Store) => {
  const {
    chat: { chats, currentChat }
  } = state;
  return { chats, currentChatId: currentChat && currentChat.info && currentChat.info.id };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  selectCurrentChat: (chatId: string) => dispatch(ChatController.selectCurrentChat(chatId)),
  clearUnreadMessagesBadge: (chatId: string) =>
    dispatch(ChatController.clearUnreadMessagesBadge(chatId)),
  fetchOldChats: () => dispatch(ChatController.fetchOldChats()),
  fetchCurrentChats: () => dispatch(ChatController.fetchChatsInfo())
});

export default connect(mapStateToProps, mapDispatchToProps)(ChatList);
