// @flow
import { FirebaseDatabase, FirebaseStorage } from './index';
import { SecurityController } from '../controllers';

const decrypt = async (msg: Object, patientUid: string) => {
  const sentByPatient = msg.user._id === patientUid;
  const decryptedText = await SecurityController.decryptText(msg.text, sentByPatient);
  return decryptedText;
};

// NOVAS MENSAGENS SERAO ENCRIPTADAS E DEVERAM TER ESSA TAG
// POREM MENSAGENS ANTIGAS NAO SAO, ASSIM SE FOSSEMOS DECRIPTA-LAS IRIA DAS ERRO
const checkEncriptionToDecode = async (msg: Object, patientUid: string) =>
  msg.encrypted
    ? { [msg._id]: { ...msg, synced: true, text: await decrypt(msg, patientUid) } }
    : { [msg._id]: { ...msg, synced: true } };

// NO DECODEMESSAGE ALEM DE COLOCAR O FIELD DE SYNCED
// VAMOS DECRIPTAR O CONTEUDO DO TEXTO DA MENSAGEM PARA PODER SER MOSTRADO NO CHAT
const decodeMessage = async (msg: Object, patientUid: string): Promise<Messages> =>
  msg.image
    ? FirebaseStorage.getURL(msg.image).then(image => ({
        [msg._id]: { ...msg, synced: true, image }
      }))
    : await checkEncriptionToDecode(msg, patientUid);

// ==================== BADGES API ====================

export const getBadges = (chatsInfo: Array<Object>): Promise<Object> => {
  return Promise.all(
    chatsInfo.map(info => {
      const path = `/chats/${info.id}/newUserMessages/`;
      return FirebaseDatabase.get(path).then(val => ({
        [info.id]: { ...info, unreadMessages: val }
      }));
    })
  ).then(infos => infos.reduce((a, b) => ({ ...a, ...b }), {}));
};

export const activeBadge = (chatId: string, setBadge: Function): void => {
  const path = `/chats/${chatId}/newUserMessages/`;
  FirebaseDatabase.listenOnValue(path, badge => badge && setBadge());
};

export const activeBadges = (chatsId: Array<string>, setBadge: Function): void => {
  chatsId.forEach(chatId => activeBadge(chatId, () => setBadge(chatId)));
};

export const clearBadge = (chatId: string): void => {
  const path = `/chats/${chatId}/newUserMessages/`;
  FirebaseDatabase.turnOffListener(path);
  FirebaseDatabase.set(path, false);
};

// ==================== CHAT MESSAGES API ====================

export const fetchMessages = (
  chatId: string,
  patientUid: string,
  batchSize: number,
  offset: number = 0
): Promise<Object> => {
  const path = `/chats/${chatId}/messages/`;

  return FirebaseDatabase.getLast(path, offset + batchSize)
    .then(messages =>
      Promise.all(
        Object.values(messages)
          .slice(0, Object.keys(messages).length - offset)
          .map(msg => decodeMessage(msg, patientUid))
      )
    )
    .then(arr => arr.reduce((a, b) => ({ ...a, ...b }), {}));
};

export const listenMessages = (chatId: string, patientUid: string, saveMessage: Function): void => {
  const path = `/chats/${chatId}/messages/`;
  FirebaseDatabase.turnOffListener(path);
  FirebaseDatabase.listenOnLastAdd(path, msg => decodeMessage(msg, patientUid).then(saveMessage));
};

export const clearMessageListener = (chatId: string) => {
  const path = `/chats/${chatId}/messages/`;
  FirebaseDatabase.turnOffListener(path);
};

const createMessageObject = (message: Message, text: string, wasAbleToEncrypt: boolean) => {
  return wasAbleToEncrypt
    ? { ...message, text, encrypted: true }
    : { ...message, text, encrypted: false };
};

export const sendText = async (chatId: string, message: Message): void => {
  const path = `/chats/${chatId}/messages/`;
  // ENCRIPTA QUANDO MANDA PARA O FIREBASE MAS NAO COMO SALVA NO REDUX
  // Encriptando o conteudo em texto da mensagem antes de enviar
  const [encryptedText, wasAbleToEncrypt] = message.text
    ? await SecurityController.encryptText(message.text)
    : [null, false];
  // ADICIONANDO TAG ENCRYPTED PARA SINALIZAR QUE A MSG DEVERA SER DECRIPTADA AO LER
  const encryptedMessage = encryptedText
    ? createMessageObject(message, encryptedText, wasAbleToEncrypt)
    : { ...message, encrypted: false };
  FirebaseDatabase.push(path, encryptedMessage).then(resp => {});
};

export const sendImage = (chatId: string, message: Message, file: Object): Promise<any> => {
  const storagePath = `/chats/${chatId}/${message._id}.jpg`;
  const databasePath = `/chats/${chatId}/messages/`;

  return FirebaseStorage.put(storagePath, file).then(({ metadata: { fullPath } }) =>
    FirebaseDatabase.push(databasePath, { ...message, image: fullPath })
  );
};
