// @flow
import React from 'react';
import { HashRouter, Switch, Route, Redirect } from 'react-router-dom';
import { PrivateRoute } from './views/wrappers';
import { Routes } from './constants';
import 'moment/locale/pt-br';
import { checkBrowser } from './utils/Utils';
import * as Sentry from '@sentry/browser';
import SideMenu from './views/sidemenu/SideMenu';
import { StyledAppContainer, StyledApplication } from './AppStyles';
import { Nav } from './views/reusable';

const user = JSON.parse(localStorage.getItem('user'));

const App = () => {
  checkBrowser();
  Sentry.setUser({ username: user?.name, id: user?.id });
  return (
    <HashRouter>
      <StyledAppContainer>
        <SideMenu>
          <StyledApplication>
            <Nav />
            <Switch>
              <Route exact path="/" render={() => <Redirect exact to="/chat" />} />
              {Routes.map(r => {
                const { isPrivate, path, ...props } = r;
                const Routable = isPrivate ? PrivateRoute : Route;
                return <Routable key={path} path={path} {...props} />;
              })}
            </Switch>
          </StyledApplication>
        </SideMenu>
      </StyledAppContainer>
    </HashRouter>
  );
};

export default App;

//TODO: api/appointments/retrieve-therapist-upcoming
//https://webapp.moodar.com.br/api/appointments/retrieve-therapist-upcoming
