// @flow
import { fetchServerData } from './utils';

export const generateTokens = (therapistId: number, patientId: number): Promise<Object> => {
  const path = ['communication', 'generate-tokens'];
  return fetchServerData(path, { therapist_id: therapistId, participant_id: patientId }, 'POST');
};

export const confirmVideocall = (therapistId: number, patientId: number): Promise<Object> => {
  const path = ['communication', 'confirm-videocall'];
  return fetchServerData(path, { therapist_id: therapistId, participant_id: patientId }, 'POST');
};

export const deleteTokens = (therapistId: number, patientId: number): Promise<Object> => {
  const path = ['communication', 'delete-tokens'];
  return fetchServerData(path, { therapist_id: therapistId, participant_id: patientId }, 'DELETE');
};

export const sendFeedback = body => {
  const path = ['therapist_feedback'];
  return fetchServerData(path, body, 'POST');
};
